import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createPromotion = createAsyncThunk(
  "createPromotion",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/promotions`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.promotions}/page/1`);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getPromotions = createAsyncThunk(
  "getPromotions",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/promotions?start=${data.start || 0}&title=${
          data.title || ""
        }&sub_title=${data.sub_title || ""}&address=${data.address || ""}`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updatePromotion = createAsyncThunk(
  "updatePromotion",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/promotions/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.promotions}/page/${data.page}`, {
          state: { page: data.page, value: data.value },
        });
        return response.data;
      }
      if (data.cb && data.isDelete) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      if (data.cb && !data.isDelete) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

const promotionSlice = createSlice({
  name: "promotion",
  initialState: {
    promotionList: [],
    createPromotion: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Admin
    builder.addCase(getPromotions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPromotions.fulfilled, (state, action) => {
      state.loader = false;
      state.promotionList = action.payload;
    });
    builder.addCase(getPromotions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Admin
    builder.addCase(createPromotion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createPromotion.fulfilled, (state, action) => {
      state.loader = false;
      state.createPromotion = action.payload;
    });
    builder.addCase(createPromotion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default promotionSlice.reducer;
