import React from "react";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addMobileConfigData } from "../../store/slice/settingSlice";

const MobileConfiguration = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("common");
  //   const configData = useSelector(
  //     (state) => state.mobileConfig.mobileConfigDetail
  //   );

  const { configData } = location.state;

  const initialFValues = useCallback(() => {
    return getInitialLeadState(configData);
  }, [configData]);

  const getInitialLeadState = (configData) => {
    return {
      title_en: configData ? configData?.title_en : "",
      title_th: configData ? configData?.title_th : "",
      minimum_version: configData ? configData?.minimum_version : "",
      current_version: configData ? configData?.current_version : "",
      current_version_name: configData ? configData?.current_version_name : "",
      ios_url: configData ? configData?.ios_url : "",
      android_url: configData ? configData?.android_url : "",
      description_en: configData ? configData?.description_en : "",
      description_th: configData ? configData?.description_th : "",
      notify: configData
        ? configData?.notify === "0"
          ? "not_notify"
          : configData?.notify === "2"
          ? "force_update"
          : "notify"
        : "notify",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("title_en" in fieldValues) {
      temp.title_en = fieldValues.title_en ? "" : "Title is Required!";
    }
    if ("title_th" in fieldValues) {
      temp.title_th = fieldValues.title_th ? "" : "Title is Required!";
    }
    if ("minimum_version" in fieldValues) {
      temp.minimum_version = fieldValues.minimum_version
        ? ""
        : "Minimum version is Required!";
    }
    if ("current_version" in fieldValues) {
      temp.current_version = fieldValues.current_version
        ? ""
        : "Current version is Required!";
    }
    if ("current_version_name" in fieldValues) {
      temp.current_version_name = fieldValues.current_version_name
        ? ""
        : "Current version name is Required!";
    }
    if ("ios_url" in fieldValues) {
      temp.ios_url = fieldValues.ios_url ? "" : "iOS URL is Required!";
    }
    if ("android_url" in fieldValues) {
      temp.android_url = fieldValues.android_url
        ? ""
        : "Android URL is Required!";
    }
    if ("description_en" in fieldValues) {
      temp.description_en = fieldValues.description_en
        ? ""
        : "Description is Required!";
    }
    if ("description_th" in fieldValues) {
      temp.description_th = fieldValues.description_th
        ? ""
        : "Description is Required!";
    }

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    configData
  );

  const configurationHandler = () => {
    if (validate()) {
      const data = {
        title_en: values.title_en,
        title_th: values.title_th,
        minimum_version: values.minimum_version,
        current_version: values.current_version,
        current_version_name: values.current_version_name,
        ios_url: values.ios_url,
        android_url: values.android_url,
        description_en: values.description_en,
        description_th: values.description_th,
        notify:
          values.notify === "not_notify"
            ? "0"
            : values.notify === "notify"
            ? "1"
            : "2",
      };
      if (!configData?.id) {
        dispatch(addMobileConfigData({ data: data, navigate }));
      } else {
        dispatch(
          addMobileConfigData({ data: data, id: configData?.id, navigate })
        );
      }
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("form.mobile_version_control")}
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.announcement_title_place")}
            label={t("table.en_title")}
            id="title_en"
            name="title_en"
            value={values?.title_en}
            onChange={handleInputChange}
            error={errors?.title_en || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.announcement_title_place")}
            label={t("table.th_title")}
            id="title_th"
            name="title_th"
            value={values?.title_th}
            onChange={handleInputChange}
            error={errors?.title_th || ""}
          />
          <div className="col_span_2 oc_mobile_version_cont">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.minimum_version_place")}
              label={t("form.minimum_version")}
              id="minimum_version"
              name="minimum_version"
              value={values?.minimum_version}
              onChange={handleInputChange}
              error={errors?.minimum_version || ""}
            />
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.current_version_place")}
              label={t("form.current_version")}
              id="current_version"
              name="current_version"
              value={values?.current_version}
              onChange={handleInputChange}
              error={errors?.current_version || ""}
            />
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.current_version_name_place")}
              label={t("form.current_version_name")}
              id="current_version_name"
              name="current_version_name"
              value={values?.current_version_name}
              onChange={handleInputChange}
              error={errors?.current_version_name || ""}
            />
          </div>
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.ios_url_place")}
            label={t("form.ios_url")}
            id="ios_url"
            name="ios_url"
            rows={1}
            value={values?.ios_url}
            onChange={handleInputChange}
            error={errors?.ios_url || ""}
          />
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.android_place")}
            label={t("form.android_url")}
            id="android_url"
            name="android_url"
            rows={1}
            value={values?.android_url}
            onChange={handleInputChange}
            error={errors?.android_url || ""}
          />
          <TextArea
            className="create_from_input_content "
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.description_place")}
            label={t("table.en_desc")}
            id="description_en"
            name="description_en"
            rows={5}
            value={values?.description_en}
            onChange={handleInputChange}
            error={errors?.description_en || ""}
          />
          <TextArea
            className="create_from_input_content "
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.description_place")}
            label={t("table.th_desc")}
            id="description_th"
            name="description_th"
            rows={5}
            value={values?.description_th}
            onChange={handleInputChange}
            error={errors?.description_th || ""}
          />

          {/* <div className="create_from_row"> */}
          <div className="create_from_input_content mobile_config_radio col_span_2">
            <label className="create_from_label required">
              {t("form.notify")}{" "}
            </label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label={t("form.not_notify")}
              id="not_notify"
              name="notify"
              value="not_notify"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "not_notify"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label={t("form.notify")}
              id="notify"
              name="notify"
              value="notify"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "notify"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label={t("form.force_update")}
              id="force_update"
              name="notify"
              value="force_update"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values?.notify === "force_update"}
            />
          </div>

          <Button
            className="create_from_bottom_btn col_span_2"
            buttonClassName="comman_btn"
            onClick={configurationHandler}
            text={t("common.save")}
          />
        </div>
      </div>
    </>
  );
};

export default MobileConfiguration;
