import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  editIcon,
  viewIcon,
} from "../../icons";
import { getFeatures, getRoles } from "../../store/slice/rolemanagementSlice";
import DeleteRoleModal from "./DeleteRoleModal";
import ViewRoleDetailModal from "./ViewRoleDetailModal";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const RoleManagement = () => {
  const dispatch = useDispatch();
  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);
  const roleListLoader = useSelector((state) => state.rolemanagement.loader);

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  useEffect(() => {
    dispatch(getRoles({}));
  }, [dispatch]);

  const initialFValues = {
    viewModal: false,
    viewModalData: "",
    deleteModal: false,
    deleteModalId: "",
  };

  const { values, setValues } = useForm(initialFValues, true);

  const viewRoleHandler = (data) => {
    dispatch(getFeatures({}));
    setValues({ ...values, viewModal: true, viewModalData: data });
  };

  const handleClose = () => {
    setValues({ ...values, viewModal: false, viewModalData: "" });
  };

  const deleteRoleHandler = (id) => {
    setValues({ ...values, deleteModal: true, deleteModalId: id });
  };

  const handleCloseDeleteModal = () => {
    setValues({ ...values, deleteModal: false, deleteModalId: "" });
  };

  if (roleListLoader) {
    return <Loader />;
  }

  return (
    <div className="project_edit_main_content">
      <div className="rolemanagement_project_btn_row_flex">
        <h2 className="project_subheading">
          {/* {sideHeading} */}
          {SidebarComponent()}
        </h2>
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createRole} className="comman_btn ml-auto">
            {t("common.create")}
          </Link>
        </div>
      </div>

      <div className="custom_data_table_content">
        {roleList?.length === 0 ? (
          <h2 className="ta_c">No results found</h2>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.is_default")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.app_type")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.createdAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.updatedAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {roleList?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.role_display_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.is_default === "1" ? checkIcon : crossIcon}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.app_type_display_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.created_at &&
                        moment
                          .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.updated_at
                        ? moment
                            .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row custom_table_three_btn_row">
                        <div className="tooltip">
                          <button
                            className="custom_data_table_view_edit_item_btn"
                            onClick={() => viewRoleHandler(menu)}
                          >
                            {viewIcon}
                          </button>
                          <span className="tooltiptext">View</span>
                        </div>
                        <div className="tooltip">
                          <Link
                            to={`${routes.rolemanagement}/edit/${menu.id}`}
                            state={{ role: menu }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>

                        {
                          menu.is_default === "0" && (
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteRoleHandler(menu.id)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">Delete</span>
                            </div>
                          )
                          // : (
                          //   <button className="custom_data_table_view_edit_item_btn">
                          //     -
                          //   </button>
                          // )
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <ViewRoleDetailModal
        handleClose={handleClose}
        show={values.viewModal}
        roleDetail={values.viewModalData}
      />

      <DeleteRoleModal
        handleClose={handleCloseDeleteModal}
        values={values}
        setValues={setValues}
      />
    </div>
  );
};

export default RoleManagement;
