import moment from "moment";
import React, { useRef, useState, memo } from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  announcementsReportDetail,
  contactReportDetail,
  eventReportDetail,
  feedbackReportDetail,
  promotionReportDetail,
  serviceReportDetail,
  userReportDetail,
  visitorInviteReportDetail,
} from "../../store/slice/dashboardSlice";
import { defaultTimeZone } from "../../helpers/commonFunction";

const CsvDownload = ({
  type,
  fileName,
  startDate,
  endDate,
  isBtnLoading,
  handleBtnLoaderCallback,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const [dataReport, setDataReport] = useState(false);
  const csvInstance = useRef();

  useEffect(() => {
    if (dataReport && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setDataReport(false);
      });
    }
  }, [dataReport]);

  const userDownloadHandler = (type) => {
    let data;
    if (startDate && endDate) {
      data = {
        sdate: moment(startDate).format("YYYY-MM-DD"),
        edate: moment(endDate).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        sdate: "",
        edate: moment(new Date()).format("YYYY-MM-DD"),
      };
    }
    if (type === "user") {
      handleBtnLoaderCallback(type, true);
      dispatch(userReportDetail({ data })).then((res) => {
        let user_Tenants;
        let user_Visitors;
        let user_JustCo;
        if (res && res?.payload?.reports?.tenants?.length > 0) {
          user_Tenants = [
            [
              "User ID",
              "First Name",
              "Last Name",
              "Email",
              "Phone number",
              "User status",
              "Login type",
              "User Role",
              "Company",
              "Department",
              "Position",
              "JustCo Access",
              "JustCo Registered",
              "Created At",
              "Last Active At",
            ],
            ...res?.payload?.reports?.tenants?.map((data) => {
              return [
                data.id,
                data.first_name,
                data.last_name,
                data.email ? data.email : "-",
                data.phone_number
                  ? data.country_code + " " + data.phone_number
                  : "-",
                data.user_status === "VERIFICATION_PENDING"
                  ? "Verification Pending"
                  : "Verification Successful",
                data.provider_type === "google.com"
                  ? "Google"
                  : data.provider_type === "facebook.com"
                  ? "Facebook"
                  : data.provider_type === "apple.com"
                  ? "Apple"
                  : "Phone",
                data.role_display_name ? data.role_display_name : "-",
                data.role_display_name === "Tenant" && data.company
                  ? data.company
                  : data.company_name
                  ? data.company_name
                  : "-",
                data.role_display_name === "Tenant" && data.department_name
                  ? data.department_name
                  : "-",
                data.job_title ? data.job_title : "-",
                data.justco_access ? data.justco_access : "-",
                data.justco_status ? data.justco_status : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.last_login
                  ? moment
                      .utc(data.last_login, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
              ];
            }),
          ];
        }
        if (res && res?.payload?.reports?.visitors?.length > 0) {
          user_Visitors = [
            [
              "User ID",
              "First Name",
              "Last Name",
              "Email",
              "Phone number",
              "User status",
              "Login type",
              "User Role",
              "Company",
              "Department",
              "Position",
              "Created At",
              "Last Active At",
            ],
            ...res.payload?.reports?.visitors?.map((data) => {
              return [
                data.id,
                data.first_name,
                data.last_name,
                data.email ? data.email : "-",
                data.phone_number
                  ? data.country_code + " " + data.phone_number
                  : "-",
                data.user_status === "VERIFICATION_PENDING"
                  ? "Verification Pending"
                  : "Verification Successful",
                data.provider_type === "google.com"
                  ? "Google"
                  : data.provider_type === "facebook.com"
                  ? "Facebook"
                  : data.provider_type === "apple.com"
                  ? "Apple"
                  : "Phone",
                data.role_display_name ? data.role_display_name : "-",
                data.role_display_name === "Tenant" && data.company
                  ? data.company
                  : data.company_name
                  ? data.company_name
                  : "-",
                data.role_display_name === "Tenant" && data.department_name
                  ? data.department_name
                  : "-",
                data.job_title ? data.job_title : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.last_login
                  ? moment
                      .utc(data.last_login, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
              ];
            }),
          ];
        }
        if (res && res?.payload?.reports?.justCo_users?.length > 0) {
          user_JustCo = [
            [
              "User ID",
              "First Name",
              "Last Name",
              "Email",
              "Phone number",
              "Gender",
              "User status",
              "Login type",
              "User Role",
              "Company",
              "Department",
              "Position",
              "JustCo Status",
              "JustCo Access",
              "Created At",
              "Last Active At",
            ],
            ...res.payload?.reports?.justCo_users?.map((data) => {
              return [
                data.id,
                data.first_name,
                data.last_name,
                data.email ? data.email : "-",
                data.phone_number
                  ? data.country_code + " " + data.phone_number
                  : "-",
                data.gender ? data.gender : "-",
                data.user_status === "VERIFICATION_PENDING"
                  ? "Verification Pending"
                  : "Verification Successful",
                data.provider_type === "google.com"
                  ? "Google"
                  : data.provider_type === "facebook.com"
                  ? "Facebook"
                  : data.provider_type === "apple.com"
                  ? "Apple"
                  : "Phone",
                data.role_display_name ? data.role_display_name : "-",
                data.role_display_name === "Tenant" && data.company
                  ? data.company
                  : data.company_name
                  ? data.company_name
                  : "-",
                data.role_display_name === "Tenant" && data.department_name
                  ? data.department_name
                  : "-",
                data.job_title ? data.job_title : "-",
                data.justco_status ? data.justco_status : "-",
                data.justco_access ? data.justco_access : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.last_login
                  ? moment
                      .utc(data.last_login, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
              ];
            }),
          ];
        }

        if (
          user_Tenants?.length > 0 &&
          user_Tenants !== undefined &&
          user_Visitors?.length > 0 &&
          user_Visitors !== undefined &&
          user_JustCo?.length > 0 &&
          user_JustCo !== undefined
        ) {
          let sheet1 = XLSX.utils.aoa_to_sheet(user_Visitors);
          let sheet2 = XLSX.utils.aoa_to_sheet(user_Tenants);
          let sheet3 = XLSX.utils.aoa_to_sheet(user_JustCo);
          setTimeout(() => {
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet1, "Visitors");
            XLSX.utils.book_append_sheet(workbook, sheet2, "Tenants");
            XLSX.utils.book_append_sheet(workbook, sheet3, "JustCo Users");
            XLSX.writeFile(workbook, fileName);
            handleBtnLoaderCallback(type, false);
          }, 1000);
        } else if (user_Tenants?.length > 0 && user_Tenants !== undefined) {
          let sheet2 = XLSX.utils.aoa_to_sheet(user_Tenants);
          setTimeout(() => {
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet2, "Tenants");
            XLSX.writeFile(workbook, fileName);
            handleBtnLoaderCallback(type, false);
          }, 1000);
        } else if (user_Visitors?.length > 0 && user_Visitors !== undefined) {
          let sheet1 = XLSX.utils.aoa_to_sheet(user_Visitors);
          setTimeout(() => {
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet1, "Visitors");
            XLSX.writeFile(workbook, fileName);
            handleBtnLoaderCallback(type, false);
          }, 1000);
        } else if (user_JustCo?.length > 0 && user_JustCo !== undefined) {
          let sheet3 = XLSX.utils.aoa_to_sheet(user_JustCo);
          setTimeout(() => {
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet3, "JustCo Users");
            XLSX.writeFile(workbook, fileName);
            handleBtnLoaderCallback(type, false);
          }, 1000);
        }
      });
    } else if (type === "feedback") {
      handleBtnLoaderCallback(type, true);
      dispatch(feedbackReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Name",
              "Phone number",
              "Email",
              "Description",
              "Feedback type",
              "Role",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.feedback?.map((data) => {
              return [
                data.first_name + " " + data.last_name,
                data.phone_number
                  ? data.country_code + " " + data.phone_number
                  : "-",
                data.email ? data.email : "-",
                data.feedback_description ? data.feedback_description : "-",
                data.feedback_type_name ? data.feedback_type_name : "-",
                data.role_display_name ? data.role_display_name : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "service") {
      handleBtnLoaderCallback(type, true);
      dispatch(serviceReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Name",
              "Phone number",
              "Email",
              "Role",
              "Status",
              "Service type",
              "Floor/Level",
              "Location/Area",
              "Description",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.service_requests?.map((data) => {
              return [
                data.first_name + " " + data.last_name,
                data.phone_number
                  ? data.country_code + " " + data.phone_number
                  : "-",
                data.email ? data.email : "-",
                data.role_display_name ? data.role_display_name : "-",
                data.service_request_status === "PENDING"
                  ? "Pending"
                  : data.service_request_status === "IN_PROGRESS"
                  ? "In Progress"
                  : "Completed",
                data.service_type_name ? data.service_type_name : "-",
                data.floor_name
                  ? data.floor_name + " / " + data.level_name
                  : "-",
                data.service_location ? data.service_location : "-",
                data.service_request_description
                  ? data.service_request_description
                  : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "event") {
      handleBtnLoaderCallback(type, true);
      dispatch(eventReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Event ID",
              "Title",
              "Event Location",
              "Start Date",
              "Start Time",
              "End Date",
              "End Time",
              "Organizer Name",
              "Organizer Company Name",
              "Event Link",
              "Floor/Level",
              "Created By",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.events?.map((data) => {
              return [
                data.id,
                data.title,
                data.address ? data.address : "-",
                moment
                  .utc(data.date, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY"),
                moment
                  .utc(data.date, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("hh:mm A"),
                moment
                  .utc(data.end_date, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("DD/MM/YYYY"),
                moment
                  .utc(data.end_date, "YYYYMMDD HH:mm:ss")
                  .clone()
                  .tz(defaultTimeZone)
                  .format("hh:mm A"),
                data.organizer_name ? data.organizer_name : "-",
                data.organizer_company_name ? data.organizer_company_name : "-",
                data.event_link ? data.event_link : "-",
                data.floor_name
                  ? data.floor_name + " / " + data.level_name
                  : "-",
                data.user_name ? data.user_name : "",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "contact") {
      handleBtnLoaderCallback(type, true);
      dispatch(contactReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Name",
              "Phone number",
              "Email",
              "Company",
              "Department",
              "Position",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.contact_list?.map((data) => {
              return [
                data.contact_first_name + " " + data.contact_last_name,
                data.contact_phone_number
                  ? data.contact_country_code + " " + data.contact_phone_number
                  : "-",
                data.email ? data.email : "-",
                data.company
                  ? data.company
                  : data.company_name
                  ? data.company_name
                  : "-",
                data.contact_post ? data.contact_post : "-",
                data.contact_position ? data.contact_position : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "announcement") {
      handleBtnLoaderCallback(type, true);
      dispatch(announcementsReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Title",
              "Announcement Type",
              "Description",
              "User Role",
              "Announcement Time",
              "Start Date",
              "End Date",
              "Created By",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.announcement_list?.map((data) => {
              return [
                data.announcement_title ? data.announcement_title : "-",
                data.announcement_type_name ? data.announcement_type_name : "-",
                data.announcement_description
                  ? data.announcement_description
                  : "-",
                data.role_display_name ? data.role_display_name : "-",
                data.announcement_date_and_time
                  ? moment(data.announcement_date_and_time).format(
                      "DD/MM/YYYY, hh:mm A"
                    )
                  : "-",
                data.start_date
                  ? moment
                      .utc(data.start_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
                data.end_date
                  ? moment
                      .utc(data.end_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
                data.user_name ? data.user_name : "-",
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "visitor_invite") {
      handleBtnLoaderCallback(type, true);
      dispatch(visitorInviteReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Name",
              "Email",
              "Phone Number",
              "Purpose",
              "Visit Date & Time",
              "Invite Status",
              "Document Type",
              "Document Number",
              "Document Issue Date",
              "Document Expiry Date",
              "Invited by Tenant",
              "Tenant Position",
              "Tenant Department",
              "Guest Visitor 1",
              "Guest Visitor 2",
              "Guest Visitor 3",
              "Guest Visitor 4",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports?.events?.map((data) => {
              return [
                data.first_name + " " + data.last_name,
                data.email ? data.email : "-",
                data.visitor_phone_number
                  ? data.visitor_country_code + " " + data.visitor_phone_number
                  : "-",
                data.purpose_title ? data.purpose_title : "-",
                data.visit_date
                  ? moment
                      .utc(data.visit_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
                data.invite_status ? data.invite_status : "-",
                data.document_type_title ? data.document_type_title : "-",
                data.document_number ? data.document_number : "-",
                data.issue_date
                  ? moment
                      .utc(data.issue_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY")
                  : "-",
                data.expiry_date
                  ? moment
                      .utc(data.expiry_date, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY")
                  : "-",
                data.tenant_first_name+" "+data.tenant_last_name,
                data.tenant_position ? data.tenant_position.charAt(0).toUpperCase() + data.tenant_position.slice(1) : '',
                data.tenant_department ? data.tenant_department.charAt(0).toUpperCase() + data.tenant_department.slice(1) : '',
                data.subvisitor_1,
                data.subvisitor_2,
                data.subvisitor_3,
                data.subvisitor_4,
                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.update_at
                  ? moment
                      .utc(data.update_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")
                  : "-",
              ];
            }),
          ]);

          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    } else if (type === "promotion") {
      handleBtnLoaderCallback(type, true);
      dispatch(promotionReportDetail({ data })).then((res) => {
        setTimeout(() => {
          setDataReport([
            [
              "Title",
              "Sub Title",
              "Description",
              "Address",
              "User Role",
              "Start Date",
              "End Date",
              "Start Time",
              "End Time",
              "Created At",
              "Updated At",
            ],

            ...res.payload?.reports.promotion_list?.map((data) => {
              return [
                data.title ? data.title : "-",
                data.sub_title ? data.sub_title : "-",
                data.description ? data.description : "-",

                data.address ? data.address : "-",
                data.role_display_name ? data.role_display_name : "-",
                data.start_date
                  ? moment(data.start_date).format("DD/MM/YYYY, hh:mm A")
                  : "-",
                data.end_date
                  ? moment(data.end_date).format("DD/MM/YYYY, hh:mm A")
                  : "-",
                data.start_time ? data.start_time : "-",
                data.end_time ? data.end_time : "-",

                data.created_at &&
                  moment
                    .utc(data.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
                data.updated_at &&
                  moment
                    .utc(data.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A"),
              ];
            }),
          ]);
          handleBtnLoaderCallback(type, false);
        }, 1000);
      });
    }
  };

  return (
    <>
      <button
        className="download_btn comman_btn"
        onClick={() => userDownloadHandler(type)}
        disabled={
          isBtnLoading?.type === type && isBtnLoading?.isLoading === true
        }
      >
        {isBtnLoading?.type === "user" ? (
          <>
            {isBtnLoading?.isLoading === true ? (
              <>
                {t("dashboard.download_excel")}
                <span className="oc-btn-loader"></span>
              </>
            ) : (
              <span>{t("dashboard.download_excel")}</span>
            )}
          </>
        ) : (
          <>
            {isBtnLoading?.isLoading === true ? (
              <>
                {t("dashboard.download_csv")}
                <span className="oc-btn-loader"></span>
              </>
            ) : (
              <span>{t("dashboard.download_csv")}</span>
            )}
          </>
        )}
      </button>
      {type !== "user" && dataReport ? (
        <CSVLink
          data={dataReport}
          className="download_btn comman_btn"
          filename={fileName}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};

export default memo(CsvDownload);
