import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { updateFeatureRole } from "../../store/slice/rolemanagementSlice";

const EditFeature = ({
  values,
  setValues,
  editDataState,
  setEditDataState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roleFeatureList = useSelector(
    (state) => state.rolemanagement.roleFeatureDataList?.data
  );

  const handleInputChangeCheckbox = (e, item) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  const editFeatureHandler = (item) => {
    values.editableId
      ? setValues({
          ...values,
          editable: false,
          editableId: "",
        })
      : setValues({
          ...values,
          is_create: item.is_create === "0" ? false : true,
          is_delete: item.is_delete === "0" ? false : true,
          is_read: item.is_read === "0" ? false : true,
          is_update: item.is_update === "0" ? false : true,
          editable: !values.editable,
          editableId: item.id,
        });
    setEditDataState(item);
  };

  const updateRoleHandler = () => {
    const data = {
      role_id: editDataState?.role_id.toString(),
      feature_id: values.feature_id,
      is_create: values.is_create === true ? "1" : "0",
      is_delete: values.is_delete === true ? "1" : "0",
      is_update: values.is_update === true ? "1" : "0",
      is_read: values.is_read === true ? "1" : "0",
    };

    dispatch(
      updateFeatureRole({
        data,
        id: editDataState?.id,
        navigate,
        toast: t("toast.update_featurerole"),
      })
    );
  };

  return (
    <>
      {roleFeatureList?.map((item, i) => {
        return (
          <div className="edit-feature-column" key={i}>
            <h3 className="mobile_configuration_heading edit-feature-headings">
              {item.role_display_name}
            </h3>
            <div
              className={`create_from_row ${
                values?.editableId &&
                values?.editableId !== item.id &&
                "disable_class"
              }`}
            >
              <div className="create_from_input_content">
                <Input
                  className="create_from_radio_checkbox_content"
                  labelClassName="create_from_radio_label"
                  inputClassName="create_from_radio_checkbox"
                  errorClassName="err_text"
                  type="checkbox"
                  label="Create"
                  id="is_create"
                  name="is_create"
                  onChange={(e) => handleInputChangeCheckbox(e, item)}
                  checked={
                    values?.editableId === item.id
                      ? values?.is_create
                      : item.is_create === "1"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="create_from_input_content">
                <Input
                  className="create_from_radio_checkbox_content"
                  labelClassName="create_from_radio_label"
                  inputClassName="create_from_radio_checkbox"
                  errorClassName="err_text"
                  type="checkbox"
                  label="Delete"
                  id="is_delete"
                  name="is_delete"
                  onChange={(e) => handleInputChangeCheckbox(e, item)}
                  checked={
                    values?.editableId === item.id
                      ? values?.is_delete
                      : item.is_delete === "1"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="create_from_input_content">
                <Input
                  className="create_from_radio_checkbox_content"
                  labelClassName="create_from_radio_label"
                  inputClassName="create_from_radio_checkbox"
                  errorClassName="err_text"
                  type="checkbox"
                  label="Read"
                  id="is_read"
                  name="is_read"
                  onChange={(e) => handleInputChangeCheckbox(e, item)}
                  checked={
                    values?.editableId === item.id
                      ? values?.is_read
                      : item.is_read === "1"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="create_from_input_content">
                <Input
                  className="create_from_radio_checkbox_content"
                  labelClassName="create_from_radio_label"
                  inputClassName="create_from_radio_checkbox"
                  errorClassName="err_text"
                  type="checkbox"
                  label="Update"
                  id="is_update"
                  name="is_update"
                  onChange={(e) => handleInputChangeCheckbox(e, item)}
                  checked={
                    values?.editableId === item.id
                      ? values?.is_update
                      : item.is_update === "1"
                      ? true
                      : false
                  }
                />
              </div>
              <Button
                buttonClassName="comman_btn"
                onClick={() => editFeatureHandler(item)}
                text={`${values?.editableId === item.id ? "Cancel" : "Edit"}`}
              />
              {values?.editableId === item.id && (
                <Button
                  buttonClassName="comman_btn"
                  onClick={updateRoleHandler}
                  text={"Update"}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EditFeature;
