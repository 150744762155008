import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createFeature = createAsyncThunk(
  "createFeature",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/roles`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.rolemanagement);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateFeature = createAsyncThunk(
  "updateFeature",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/roles/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.rolemanagement);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateFeatureRole = createAsyncThunk(
  "updateFeatureRole",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/roles/mapping/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.rolemanagement);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateFeaturesRole = createAsyncThunk(
  "updateFeaturesRole",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/roles/features/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        // toast.success("Feature updated successfully", {
        //   autoClose: 3000,
        // });
        // data.navigate(routes.rolemanagement);
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createFeatureRole = createAsyncThunk(
  "createFeatureRole",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/roles/mapping`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(routes.rolemanagement);
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getFeatures = createAsyncThunk(
  "getFeatures",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/roles/features?app_type_id=${
          data.app_type_id || ""
        }&search_type=${data.app_status || ""}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const cleanFeatures = createAsyncThunk("cleanFeatures", async () => {
  return;
});

export const getAppType = createAsyncThunk(
  "getAppType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(`/onecity/types/app`, authHeaders());
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getRoles = createAsyncThunk("getRoles", async (data, thunkAPI) => {
  try {
    const response = await Axios.get(
      `/onecity/roles?mobile_app_type_role=${
        data.appType || 0
      }&admin_app_type_role=${data.adminType || 0}`,
      authHeaders()
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

export const deleteRole = createAsyncThunk(
  "deleteRole",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `/onecity/roles/${data.id}`,
        authHeaders()
      );
      if (data.cb) {
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      return data.cb(error.response);
    }
  }
);

export const getRoleFeaturesData = createAsyncThunk(
  "getRoleFeaturesData",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/roles/mapping/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const rolemanagementSlice = createSlice({
  name: "rolemanagement",
  initialState: {
    featuresList: [],
    rolesList: [],
    roleFeatureDataList: [],
    appTypeList: [],
    createRoleComponent: "",
    updateRoleComponent: "",
    createFeatureRoleComponent: "",
    error: null,
    loader: false,
    featureLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Features
    builder.addCase(getFeatures.pending, (state) => {
      state.featureLoader = true;
    });
    builder.addCase(getFeatures.fulfilled, (state, action) => {
      state.featureLoader = false;
      state.featuresList = action.payload;
    });
    builder.addCase(getFeatures.rejected, (state, action) => {
      state.featureLoader = false;
      state.error = action.payload;
    });

    // Clean Features
    builder.addCase(cleanFeatures.fulfilled, (state, action) => {
      state.featureLoader = false;
      state.featuresList = [];
    });

    // Get App Types
    builder.addCase(getAppType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAppType.fulfilled, (state, action) => {
      state.loader = false;
      state.appTypeList = action.payload;
    });
    builder.addCase(getAppType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Roles
    builder.addCase(getRoles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loader = false;
      state.rolesList = action.payload;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Role Features Data
    builder.addCase(getRoleFeaturesData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRoleFeaturesData.fulfilled, (state, action) => {
      state.loader = false;
      state.roleFeatureDataList = action.payload;
    });
    builder.addCase(getRoleFeaturesData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Feature
    builder.addCase(createFeature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createFeature.fulfilled, (state, action) => {
      state.loader = false;
      state.createRoleComponent = action.payload;
    });
    builder.addCase(createFeature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Feature
    builder.addCase(updateFeature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateFeature.fulfilled, (state, action) => {
      state.loader = false;
      state.updateRoleComponent = action.payload;
    });
    builder.addCase(updateFeature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Feature Role
    builder.addCase(createFeatureRole.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createFeatureRole.fulfilled, (state, action) => {
      state.loader = false;
      state.createFeatureRoleComponent = action.payload;
    });
    builder.addCase(createFeatureRole.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default rolemanagementSlice.reducer;
