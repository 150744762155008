import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonDatePicker from "../../component/Common/DatePicker";
import Input from "../../component/Common/Input";
import { promotionValidation } from "../../component/Common/PageComponent/PromotionComponents";
import TextArea from "../../component/Common/TextArea";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";

import {
  SidebarComponent,
  defaultTimeZone,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, editIcon } from "../../icons";
import Button from "../../component/Common/Button";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import moment from "moment";
import {
  createPromotion,
  updatePromotion,
} from "../../store/slice/PromotionSlice";
import { useEffect } from "react";
import { getRoles } from "../../store/slice/rolemanagementSlice";

const CreatePromotion = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editComponent = location?.state?.menu;
  const value = location?.state?.values;
  const page = location?.state?.page_number;
  const { t, i18n } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);
  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  useEffect(() => {
    dispatch(getRoles({ appType: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (editComponent && roleList) {
      setValues({
        ...values,
        user_type:
          editComponent?.user_role && roleList
            ? JSON.parse(editComponent?.user_role).length === roleList?.length
              ? ["ALL"]
              : JSON.parse(editComponent?.user_role)
            : [],
      });
    }
  }, [editComponent, roleList]);

  const initialFValues = () => {
    return {
      title: editComponent ? editComponent?.title : "",
      sub_title: editComponent ? editComponent?.sub_title : "",
      description: editComponent ? editComponent?.description : "",
      start_date: editComponent
        ? new Date(
            moment
              .utc(editComponent?.start_date, "YYYYMMDD HH:mm:ss")
              .clone()
              .tz(defaultTimeZone)
              .format("YYYY-MM-DD HH:mm:ss")
          )
        : "",
      end_date:
        editComponent && editComponent.end_date
          ? new Date(
              moment
                .utc(editComponent?.end_date, "YYYYMMDD HH:mm:ss")
                .clone()
                .tz(defaultTimeZone)
                .format("MM/DD/YYYY, kk:mm:ss")
            )
          : null,
      // start_time: editComponent
      //   ? new Date(dateFunction(editComponent?.start_time))
      //   : "",
      // end_time: editComponent
      //   ? new Date(dateFunction(editComponent?.end_time))
      //   : "",
      image: editComponent ? editComponent?.image : "",
      image_preview: editComponent
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.image}`
        : "",
      // optional_promotion_img: editComponent ? editComponent?.image_square : "",
      // optional_promotion_img_preview: editComponent
      //   ? `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.image_square}`
      //   : "",
      address: editComponent ? editComponent?.address : "",
      promotion_link: editComponent ? editComponent?.promotion_link : "",
      user_type:
        editComponent?.user_role && roleList
          ? JSON.parse(editComponent?.user_role).length === roleList?.length
            ? ["ALL"]
            : JSON.parse(editComponent?.user_role)
          : [],
      send_now:
        editComponent && editComponent.send_now ? editComponent.send_now : "0",
      include_image:
        editComponent && editComponent.include_image
          ? editComponent.include_image
          : "0",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return promotionValidation(
      editComponent,
      temp,
      fieldValues,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      image: "",
      image_preview: "",
    });
  };

  // const optionalImgRemoveHandler = () => {
  //   setValues({
  //     ...values,
  //     optional_promotion_img: "",
  //     optional_promotion_img_preview: "",
  //   });
  // };

  // const onChangeStart = (e) => {
  //   setValues({
  //     ...values,
  //     start_time: e,
  //   });
  //   setErrors({
  //     ...errors,
  //     start_time: "",
  //   });
  // };

  // const onChangeEnd = (e) => {
  //   setValues({
  //     ...values,
  //     end_time: e,
  //   });
  //   setErrors({
  //     ...errors,
  //     end_time: "",
  //   });
  // };

  const userAllTypeHandler = () => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeData = [...values.user_type];
    if (tempUserTypeData.includes("ALL")) {
      const index = tempUserTypeData.findIndex((obj) => obj === "ALL");
      if (index > -1) {
        tempUserTypeData.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeData });
    } else {
      setValues({ ...values, user_type: ["ALL"] });
    }
  };

  const userTypeHandler = (e, item) => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeHandler = [...values.user_type];
    if (tempUserTypeHandler.includes(item.id)) {
      let index = tempUserTypeHandler.findIndex((obj) => obj === item.id);
      if (index > -1) {
        tempUserTypeHandler.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeHandler });
    } else {
      if (tempUserTypeHandler.includes("ALL")) {
        let index = tempUserTypeHandler.findIndex((obj) => obj === "ALL");
        if (index > -1) {
          tempUserTypeHandler.splice(index, 1);
        }
        setValues({
          ...values,
          user_type: [...tempUserTypeHandler, item.id],
        });
      } else {
        setValues({
          ...values,
          user_type: [...values.user_type, item.id],
        });
      }
    }
  };

  const onChangeNotification = () => {
    setValues({
      ...values,
      send_now: values.send_now === "0" ? "1" : "0",
    });
  };

  const onChangeAnnouncementImage = (event) => {
    // if (values?.include_image === "1") {
    //   setErrors({
    //     ...errors,
    //     image: "",
    //   });
    // }
    setValues({
      ...values,
      include_image: values.include_image === "0" ? "1" : "0",
    });
  };

  const createPromotionHandler = async (e) => {
    e.preventDefault();

    if (validate()) {
      const formData = new FormData();
      ////////////////////////////////////////////////////////////////////////////
      let attachmentImageUrl;
      if (values?.image && typeof values?.image !== "string") {
        formData.append("file", values?.image);
        attachmentImageUrl = await dispatch(fileUpload(formData));
        if (attachmentImageUrl && attachmentImageUrl.payload) {
          setValues({
            ...values,
            image: attachmentImageUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentImageUrl = values.image;
      }
      ////////////////////////////////////////////////////////////////////////////
      // let optionalImgUrl;
      // if (
      //   values?.optional_promotion_img &&
      //   typeof values?.optional_promotion_img !== "string"
      // ) {
      //   formData.append("file", values?.optional_promotion_img);
      //   optionalImgUrl = await dispatch(fileUpload(formData));
      //   if (optionalImgUrl && optionalImgUrl.payload) {
      //     setValues({
      //       ...values,
      //       optional_promotion_img: optionalImgUrl.payload,
      //     });
      //   }
      //   formData.delete("file");
      // } else {
      //   optionalImgUrl = values.optional_promotion_img;
      // }
      ////////////////////////////////////////////////////////////////////////////

      const data = {
        title: values?.title,
        sub_title: values?.sub_title,
        description: values?.description,
        start_date: moment
          .utc(moment(values?.start_date).utc())
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: values?.end_date
          ? moment
              .utc(moment(values?.end_date).utc())
              .format("YYYY-MM-DD HH:mm:ss")
          : null,
        // start_time: moment(values?.start_time).format("HH:mm"),
        // end_time: moment(values?.end_time).format("HH:mm"),
        image:
          typeof values?.image !== "string"
            ? attachmentImageUrl.payload
            : attachmentImageUrl,
        // image_square:
        //   typeof values?.optional_promotion_img !== "string"
        //     ? optionalImgUrl.payload
        //     : optionalImgUrl,
        address: values?.address,
        promotion_link: values?.promotion_link,
        user_role: values?.user_type.includes("ALL")
          ? roleList.map((item) => item.id)
          : values?.user_type,
        send_now: values?.send_now,
        include_image: values?.include_image,
      };

      editComponent
        ? dispatch(
            updatePromotion({
              data,
              navigate,
              id: editComponent?.id,
              toast: t("toast.update_promotion"),
              page,
              value,
            })
          )
        : dispatch(
            createPromotion({
              data,
              navigate,
              toast: t("toast.create_promotion"),
            })
          );
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedEndTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    // Check if the selected date is the same as the start date
    if (values && values.start_date) {
      const startDate = new Date(values.start_date);
      // If the selected date is the same as the start date,
      // compare the times to ensure end time is after start time
      if (
        currentDate.getDate() === startDate.getDate() &&
        currentDate.getMonth() === startDate.getMonth() &&
        currentDate.getFullYear() === startDate.getFullYear()
      ) {
        return selectedDate.getTime() > values.start_date.getTime();
      }
    }
    // For dates on different days, no additional validation is needed
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {/* {editComponent ? `${sideHeading} > Edit` : `${sideHeading} > Create`} */}
          {editComponent
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <Link
          to={`${routes.promotions}/page/${page}`}
          state={{ page, value }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.announcement_title_place")}
            label={t("announcement.title")}
            id="title"
            name="title"
            value={values?.title}
            onChange={handleInputChange}
            error={errors?.title || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.subtitle_place")}
            label={t("table.sub_title")}
            id="sub_title"
            name="sub_title"
            value={values?.sub_title}
            onChange={handleInputChange}
            error={errors?.sub_title || ""}
          />
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("announcement.description_place")}
            label={t("announcement.description")}
            id="description"
            name="description"
            rows={2}
            value={values?.description}
            onChange={handleInputChange}
            error={errors?.description || ""}
          />
          <CommonDatePicker
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.start_date") + " "}
            name="start_date"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeSelect={true}
            timeIntervals={10}
            filterTime={filterPassedTime}
            // hasInfoIcon
            // tooltipText="Announce Time is the date and time at which the announcement is made"
          />

          <CommonDatePicker
            values={values && values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.end_date") + " "}
            name="end_date"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeSelect={true}
            timeIntervals={10}
            filterTime={filterPassedEndTime}
            not_required
            minDate={values.start_date && values.start_date}
            isDisabled={values && !values.start_date && true}
            // minDate={values?.date && values?.date}
            // isDisabled={values && !values?.date && true}
            // hasInfoIcon
            // tooltipText="Announce Time is the date and time at which the announcement is made"
          />

          {/* <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.start_time")}
            </label>
            <DatePicker
              className="create_from_input mb-5"
              placeholderText={t("event.start_place")}
              selected={values.start_time}
              onChange={(e) => onChangeStart(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.start_time && (
              <span className="err_text">{errors?.start_time}</span>
            )}
          </div>
          <div className="create_from_input_content ">
            <label className="create_from_label required">
              {t("event.end_time")}
            </label>
            <DatePicker
              className="create_from_input"
              placeholderText={t("event.end_place")}
              selected={values.end_time}
              onChange={(e) => onChangeEnd(e)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            {errors?.end_time && (
              <span className="err_text">{errors?.end_time}</span>
            )}
          </div> */}
          <div className="event_inputs_column">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text err_margin_bottom"
              type="text"
              placeholder={t("event.address_place")}
              label={t("event.address")}
              id="address"
              name="address"
              value={values?.address}
              onChange={handleInputChange}
              error={errors?.address || ""}
            />
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.promotion_link_place")}
              label={t("form.promotion_link")}
              id="promotion_link"
              name="promotion_link"
              value={values?.promotion_link}
              onChange={handleInputChange}
              error={errors?.promotion_link || ""}
            />
          </div>
          <div className={`create_from_input_content`}>
            <label className="create_from_label required">
              {t("announcement.user_role")}
            </label>
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={"All"}
              id={"ALL"}
              name="user_type"
              value={"ALL"}
              onChange={userAllTypeHandler}
              checked={values?.user_type?.includes("ALL")}
            />
            {roleList?.map((type, i) => {
              return (
                <Input
                  className="create_from_radio_checkbox_contentainer"
                  // className={`create_from_radio_checkbox_contentainer ${
                  //   values?.user_type?.includes("ALL") &&
                  //   "disable_radio_btns"
                  // }`}
                  key={i}
                  labelClassName="create_from_radio_label_role"
                  inputClassName="create_from_radio_checkbox"
                  type="checkbox"
                  label={type.role_display_name}
                  id={type.role_display_name}
                  name="user_type"
                  value={type.id}
                  onChange={(e) => userTypeHandler(e, type)}
                  checked={values?.user_type?.includes(type.id)}
                  // disabled={values?.user_type?.includes("ALL")}
                />
              );
            })}
            {errors?.user_type && (
              <span className="err_text">{errors?.user_type}</span>
            )}
          </div>
          <div className="project_edit_detail_divider">
            <label
              className={`create_from_label required`}
              // ${values.include_image === "1" && "required"}`}
            >
              {t("promotion.head") + " " + t("table.image")} (16:9)
            </label>
            <div className="project_edit_feature_input_file six_nine_image">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`image`}
                labelOtherProps={
                  <span>{values?.image ? editIcon : addIcon}</span>
                }
                name="image"
                accept=".jpg, .jpeg, .png"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values?.image,
                  values?.image_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.max_size_six_nine")}
            </p>
            {errors?.image && <span className="err_text">{errors?.image}</span>}
          </div>

          {/* <div className="project_edit_detail_divider">
            <label
              className={`create_from_label`}
              // ${values.include_image === "1" && "required"}`}
            >
              {t("promotion.head") + " " + t("table.image")} (1:1)
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`optional_promotion_img`}
                labelOtherProps={
                  <span>
                    {values?.optional_promotion_img ? editIcon : addIcon}
                  </span>
                }
                name="optional_promotion_img"
                accept=".jpg, .jpeg, .png"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values?.optional_promotion_img,
                  values?.optional_promotion_img_preview,
                  optionalImgRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.max_size_one_one")}
            </p>
            {errors?.optional_promotion_img && (
              <span className="err_text">{errors?.optional_promotion_img}</span>
            )}
          </div> */}

          <hr className="container_bottom_dashed_line col_span_2" />
          <div>
            <label className="notification_details_heading">
              {t("notification.notification_detail")}
            </label>
          </div>
          <div></div>
          <div className="create_from_input_content ">
            {(!editComponent ||
              (editComponent && editComponent.send_now === "0")) && (
              <Input
                className="create_from_radio_checkbox_contentainer"
                labelClassName="create_from_radio_label_role"
                inputClassName="create_from_radio_checkbox"
                type="checkbox"
                label={t("announcement.send_notification_now")}
                id={"send_now"}
                name="send_now"
                value={"1"}
                onChange={() => onChangeNotification()}
                checked={values.send_now === "1"}
              />
            )}
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={
                t("common.include") +
                " " +
                t("promotion.head") +
                " " +
                t("table.image")
              }
              id={"include_image"}
              name="include_image"
              value={"1"}
              onChange={(e) => onChangeAnnouncementImage(e)}
              checked={values.include_image === "1"}
            />
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createPromotionHandler}
          text={`${
            fileUploadLoader
              ? "Loading..."
              : editComponent
              ? t("common.save")
              : t("common.create")
          }`}
        />
      </div>
    </>
  );
};

export default CreatePromotion;
