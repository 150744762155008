import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { fileUpload } from "../../../store/slice/fileUploadSlice";
import { withoutMetadata } from "./LoginPageComponents";

// Contact link type form validation

export const contactLinkValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("contactLink_icon" in fieldValues) {
    temp.contactLink_icon = "";
    if (!fieldValues.contactLink_icon) {
      temp.contactLink_icon = t("errors.icon_require");
    }
    if (fieldValues.contactLink_icon.size > 1000000) {
      temp.contactLink_icon = t("errors.large_icon_size");
      setValues({
        ...values,
        contactLink_icon_preview: "",
        contactLink_icon: "",
      });
    }
    if (
      fieldValues.contactLink_icon?.name &&
      !fieldValues.contactLink_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.contactLink_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        contactLink_icon_preview: "",
        contactLink_icon: "",
      });
    }
  }

  if ("contactLink_name" in fieldValues) {
    temp.contactLink_name = fieldValues.contactLink_name
      ? ""
      : t("errors.name");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Contact form validation
export const contactValidation = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  t
) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("first_name" in fieldValues) {
    temp.first_name = fieldValues.first_name ? "" : t("errors.first_name");
  }

  if ("last_name" in fieldValues) {
    temp.last_name = fieldValues.last_name ? "" : t("errors.last_name");
  }

  if ("company" in fieldValues) {
    temp.company = fieldValues.company ? "" : t("errors.company");
  }
  if ("contact_post" in fieldValues) {
    temp.contact_post = fieldValues.contact_post ? "" : t("errors.department");
  }

  // if ("phone_number" in fieldValues) {
  //   temp.phone_number = fieldValues.phone_number
  //     ? isValidPhoneNumber(fieldValues.phone_number)
  //       ? ""
  //       : t("errors.phone_valid")
  //     : t("errors.phone_required");
  // }

  if ("contact_phone_number" in fieldValues) {
    temp.contact_phone_number = fieldValues.contact_phone_number
      ? ""
      : t("errors.phone_required");
  }
  if ("email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = t("errors.email");
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : t("errors.email_val");
    }
    // temp.email = fieldValues.email ? "" : t("errors.email");
  }
  if ("position" in fieldValues) {
    temp.position = fieldValues.position ? "" : t("errors.position");
  }

  if ("profile_image" in fieldValues) {
    temp.profile_image = "";
    if (!fieldValues.profile_image) {
      temp.profile_image = t("errors.img_require");
    }
    if (fieldValues.profile_image.size > 1000000) {
      temp.profile_image = t("errors.small_image_size");
      setValues({
        ...values,
        profile_image_preview: "",
        profile_image: "",
      });
    }
    if (
      fieldValues.profile_image?.name &&
      !fieldValues.profile_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.profile_image = t("errors.img_invalid");
      setValues({
        ...values,
        profile_image_preview: "",
        profile_image: "",
      });
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const handleInputChangePost = (inputValue, actionMeta) => {
  console.group("Input Changed");

  console.groupEnd();
};

export const contactData = async (values, dispatch, setValues) => {
  // const parsedPhone =
  //   values.phone_number &&
  //   withoutMetadata(parsePhoneNumber(values.phone_number));

  const formData = new FormData();
  let attachmentUrl;
  if (values.profile_image && typeof values.profile_image !== "string") {
    formData.append("file", values.profile_image);
    attachmentUrl = await dispatch(fileUpload(formData));
    if (attachmentUrl && attachmentUrl.payload) {
      setValues({
        ...values,
        profile_image: attachmentUrl.payload,
      });
    }
    formData.delete("file");
  } else {
    attachmentUrl = values.profile_image;
  }

  const data = {
    contact_first_name: values.first_name,
    contact_last_name: values.last_name,
    // contact_country_code: parsedPhone?.countryCallingCode,
    // contact_phone_number: parsedPhone?.nationalNumber,
    contact_phone_number: values.contact_phone_number,
    contact_link: values.contact_link
      ?.filter(
        (item) => item.contact_link_type && item.contact_link_name && item
      )
      ?.map((item) => {
        return {
          contact_link_type: item.contact_link_type.value,
          contact_link_name: item.contact_link_name,
          contact_link_image: item.contact_link_image,
        };
      }),
    contact_post: values.contact_post.value,
    email: values.email,
    contact_position: values.position,
    company: values.company,
    contact_profile_image:
      typeof values.profile_image !== "string"
        ? attachmentUrl.payload
        : attachmentUrl,
  };

  return data;
};
