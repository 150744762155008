import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import {
  getInitialUserState,
  updateUserValidate,
} from "../../component/Common/PageComponent/UserComponents";
import { useForm } from "../../hooks/useForm";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import Select from "react-select";
import { updateUser } from "../../store/slice/userSlice";
import Button from "../../component/Common/Button";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import { parsePhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { SidebarComponent } from "../../helpers/commonFunction";

const UserEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, value, page_number } = location?.state;

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  const [enableInput, setEnableInput] = useState(true);

  useEffect(() => {
    dispatch(getRoles({ appType: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  const roleListOptions = roleList?.map((role) => {
    return { value: role.id, label: role.role_display_name };
  });

  const initialFValues = useCallback(() => {
    return getInitialUserState(user);
  }, [user]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return updateUserValidate(temp, values, fieldValues, setErrors, t);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const parsedPhone =
    values?.phone_number &&
    withoutMetadata(parsePhoneNumber(values?.phone_number));

  const editUserHandler = () => {
    if (validate()) {
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        country_code: parsedPhone.countryCallingCode,
        phone_number: parsedPhone.nationalNumber,
        email: values.email,
        user_role_id: values.role.value,
        justco_access: values.justco_access,
      };

      dispatch(
        updateUser({
          data,
          id: user.id,
          navigate,
          toast: t("toast.update_user"),
          page_number,
          value,
        })
      );
    }
  };

  const onChangeJustCoAccess = () => {
    setValues({
      ...values,
      justco_access: values.justco_access === "0" ? "1" : "0",
    });
  };

  useEffect(() => {
    if (errors?.phone_number) {
      setEnableInput(false);
    }
  }, [errors.phone_number]);

  return (
    <div className="project_edit_main_content">
      <div className="comman_btn_container center_back_btn creat_edit_project_btn_row user_detail_component_container user_edit_container">
        <h2 className="mobile_configuration_heading">
          {/* {`${sideHeading} > Edit`} */}
          {`${SidebarComponent()} > Edit`}
        </h2>
        <button
          onClick={() =>
            navigate(`/users/page/${page_number}`, {
              state: {
                value,
                value_page: page_number,
              },
            })
          }
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div>
            <label className="create_from_label">{t("form.userRole")}</label>
            <Select
              isDisabled={true}
              placeholder={t("form.userRolePlace")}
              value={values?.role}
              className="basic-multi-select"
              classNamePrefix="select"
              name="role"
              options={roleListOptions}
              onChange={(e) => handleInputChange(e, "role")}
            />
            {errors?.role && <span className="err_text">{errors?.role}</span>}
          </div>
          <div></div>
          <hr className="col_span_2" />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input disabled_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.firstnamePlace")}
            label={t("form.firstname")}
            id="first_name"
            name="first_name"
            disabled={true}
            value={values?.first_name}
            onChange={handleInputChange}
            error={errors?.first_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input disabled_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.lastnamePlace")}
            label={t("form.lastname")}
            disabled={true}
            id="last_name"
            name="last_name"
            value={values?.last_name}
            onChange={handleInputChange}
            error={errors?.last_name || ""}
          />

          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input disabled_input"
            errorClassName="err_text"
            disabled={true}
            type="email"
            placeholder={t("form.emailPlace")}
            label={t("form.email")}
            id="email"
            name="email"
            value={values?.email}
            onChange={handleInputChange}
            error={errors?.email || ""}
          />
          <div className={`login_input_row m-0`}>
            <label>{t("form.phoneNumber")}</label>
            <PhoneInput
              disabled={enableInput}
              placeholder={t("form.phoneNumberPlace")}
              value={values?.phone_number}
              defaultCountry="TH"
              onChange={(e) => {
                setValues({
                  ...values,
                  phone_number: e,
                });
                setErrors({ ...errors, phone_number: "" });
              }}
            />
            {errors?.phone_number && (
              <span className="err_text">{errors?.phone_number}</span>
            )}
          </div>
          {user && user.role_display_name === "Tenant" && (
            <div>
              <div>
                <label className="notification_details_heading">
                  {t("form.justCo_access")}
                </label>
              </div>
              <Input
                className="create_from_radio_checkbox_contentainer"
                labelClassName="create_from_radio_label_role"
                inputClassName="create_from_radio_checkbox"
                type="checkbox"
                label={t("common.allow") + " " + t("form.justCo_access")}
                id={"justco_access"}
                name="justco_access"
                value={"1"}
                onChange={() => onChangeJustCoAccess()}
                checked={values.justco_access === "1"}
              />
            </div>
          )}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={editUserHandler}
          text={t("common.save")}
        />
      </div>
    </div>
  );
};

export default UserEditPage;
