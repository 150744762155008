import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createEvent = createAsyncThunk(
  "createEvent",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/events`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.events}/page/1`);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getEvents = createAsyncThunk(
  "getEvents",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/events?start=${data.start || 0}&title=${
          data.title || ""
        }&date=${data.date || ""}&organizer_company_name=${
          data.organizer_company_name || ""
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updateEvent = createAsyncThunk(
  "updateEvent",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/events/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.events}/page/1`, {
          state: { page: data.page, value: data.value },
        });
        return response.data;
      }
      if (data.cb && data.isDelete) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      if (data.cb && !data.isDelete) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

const eventSlice = createSlice({
  name: "event",
  initialState: {
    eventList: [],
    createEvent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Admin
    builder.addCase(getEvents.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.loader = false;
      state.eventList = action.payload;
    });
    builder.addCase(getEvents.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Admin
    builder.addCase(createEvent.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.loader = false;
      state.createEvent = action.payload;
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default eventSlice.reducer;
