import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultTimeZone,
  webaddressValidation,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const ViewModalDirectory = ({ handleClose, show, directoryDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";

  const { t } = useTranslation("common");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  // function matchYoutubeUrl(url) {
  //   var regX =
  //     /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  //   if (url.match(regX)) {
  //     return url.match(regX)[1];
  //   }
  //   return false;
  // }

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("directory.directory_detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container oc_directory_detail_modal">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.retailName")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {directoryDetail?.directory_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.phoneNumber")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  { (directoryDetail?.directory_contact_country_code != '') ? "+"+directoryDetail?.directory_contact_country_code +
                    " " +
                    directoryDetail?.directory_contact_number : ""}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {directoryDetail?.directory_type_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.email")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  <a href={`mailto:${directoryDetail?.directory_email_link}`}>
                    {directoryDetail?.directory_email_link}
                  </a>
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("directory.website")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  <a
                    href={webaddressValidation(
                      directoryDetail?.directory_website_link
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {directoryDetail?.directory_website_link}
                  </a>
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("directory.facebook")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  <a
                    href={directoryDetail?.directory_facebook_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {directoryDetail?.directory_facebook_link}
                  </a>
                </p>
              </div>
              <div className="oc_directory_detail_modal_7_col">
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("floor.floor/level")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {directoryDetail?.floor_name +
                      " " +
                      directoryDetail?.level_name}
                  </p>
                </div>
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.created_by")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {directoryDetail?.user_name}
                  </p>
                </div>
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.createdAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {moment
                      .utc(directoryDetail?.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </p>
                </div>
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.updatedAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {directoryDetail?.updated_at
                      ? moment
                          .utc(directoryDetail.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="oc_directory_detail_modal_3_col">
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.description")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {directoryDetail?.directory_description}
                  </p>
                </div>

                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("directory.main_image")}
                  </h2>
                  {directoryDetail?.directory_main_image ? (
                    <p className="inquiry_complaint_detail_name">
                      <img
                        className="view_page_img_icon_view"
                        style={{ width: "100px", height: "100px" }}
                        src={`${process.env.REACT_APP_FILE_BASE_URL}/${directoryDetail?.directory_main_image}`}
                        alt="directory_image"
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              directoryDetail?.directory_main_image
                          );
                        }}
                      />
                    </p>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("directory.logo")}
                  </h2>
                  {directoryDetail?.directory_logo ? (
                    <p className="inquiry_complaint_detail_name">
                      <img
                        className="view_page_img_icon_view"
                        style={{ width: "100px", height: "100px" }}
                        src={`${process.env.REACT_APP_FILE_BASE_URL}/${directoryDetail?.directory_logo}`}
                        alt="directory_logo"
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              directoryDetail?.directory_logo
                          );
                        }}
                      />
                    </p>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default ViewModalDirectory;
