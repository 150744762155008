import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Select from "react-select";
import { useForm } from "../../hooks/useForm";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import Button from "../../component/Common/Button";
import { useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
import {
  createJustCoConfigPage,
  getJustCoConfig,
  updateJustCoConfig,
} from "../../store/slice/justCoSlice";

const JustCoTermsCondition = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { staticContent } = location.state;

  const [lang, setLang] = useState({
    value: staticContent.language,
    label: staticContent.language === "en" ? "English" : "Thai",
  });

  let tempContent =
    staticContent.t_and_c && JSON.parse(staticContent.t_and_c).text;
  const contentBlock = tempContent && htmlToDraft(tempContent);
  const contentState =
    contentBlock &&
    ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState1 =
    contentState && EditorState.createWithContent(contentState);

  const initialFValues = {
    policy_editor: editorState1 ? editorState1 : EditorState.createEmpty(),
    content:
      (staticContent.t_and_c && JSON.parse(staticContent.t_and_c).text) || "",
    id: Object.keys(staticContent).length !== 0 ? staticContent.id : "",
  };

  useEffect(() => {
    setValues({
      ...values,
      policy_editor: editorState1 ? editorState1 : EditorState.createEmpty(),
      content:
        (staticContent.t_and_c && JSON.parse(staticContent.t_and_c).text) || "",
      id: Object.keys(staticContent).length !== 0 ? staticContent.id : "",
    });
  }, [staticContent]);

  const languageListOptions = [
    { value: "en", label: "English" },
    { value: "th", label: "Thai" },
  ];

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    false,
    false
  );

  const termsAndConditionHandler = () => {
    const data = {
      t_and_c: { text: values.content },
    };

    Object.keys(staticContent).length !== 0
      ? dispatch(
          updateJustCoConfig({
            data,
            id: values.id,
            toast: t("toast.update_terms"),
            navigate,
          })
        )
      : dispatch(
          createJustCoConfigPage({
            data,
            cb: (err, res) => {
              if (err) {
                console.log("err", err);
              } else {
                dispatch(getJustCoConfig());
              }
            },
            toast: t("toast.create_terms"),
            navigate,
          })
        );
  };

  return (
    <div className="project_edit_main_content">
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("setting.policy.termsCondition")}
        </h2>
        <Link to={routes.justCo} className="comman_btn ml-auto secondary_btn">
          {t("common.back")}
        </Link>
      </div>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <Select
          placeholder={t("visitorInvite.language_place")}
          value={lang}
          className="basic-multi-select visitor_label_tenant"
          classNamePrefix="select"
          name="lang"
          options={languageListOptions}
          onChange={(e) => setLang(e)}
          isDisabled={true}
        />
      </div>
      <div className="create_from_row  settings_container">
        <div className="col_span_2">
          <div className="zu_common_form_input_content zu_common_form_textarea_content">
            <Editor
              editorState={values.policy_editor}
              wrapperClassName="card"
              editorClassName="card-body"
              toolbarClassName="toolbarClassName"
              onEditorStateChange={(newState) => {
                setValues({
                  ...values,
                  policy_editor: newState,
                  content: draftToHtml(
                    convertToRaw(newState.getCurrentContent())
                  ),
                });
              }}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "emoji",
                  "link",
                  "embedded",
                  "image",
                  "history",
                ],
                fontFamily: {
                  options: ["Roboto", "Klavika"],
                },
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </div>

        <div className="col_span_2">
          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn"
            onClick={termsAndConditionHandler}
            text={
              Object.keys(staticContent).length !== 0
                ? t("common.save")
                : t("common.create")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default JustCoTermsCondition;
