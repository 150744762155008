import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../component/Common/Input";
import { addIcon, editIcon } from "../../icons";
import {
  defaultTimeZone,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import TextArea from "../../component/Common/TextArea";
import moment from "moment";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const ChangeStatusModal = ({
  show,
  handleStatusModalClose,
  changeStatusHandler,
  handleInputChange,
  values,
  imageRemoveHandler,
  fileUploadLoader,
  onChangeRespondImg,
  onChangeServiceStatus,
  //added View Page Detail
  editPageViewServiceDetail,
  statusChange,
}) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleStatusModalClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("service.service_Detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("filter.search_service_type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {editPageViewServiceDetail?.service_type_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.description")}
                </h2>
                <p className="inquiry_complaint_detail_name service_request_description">
                  {editPageViewServiceDetail?.service_request_description}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {" "}
                  {t("table.location")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {editPageViewServiceDetail?.service_location}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("floor.floor")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {editPageViewServiceDetail?.service_floor}
                </p>
              </div>

              {editPageViewServiceDetail?.service_request_attachment && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("service.attachment")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    <img
                      className="view_page_img_icon_view"
                      style={{ width: "100px", height: "100px" }}
                      src={
                        editPageViewServiceDetail?.service_request_attachment
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            editPageViewServiceDetail?.service_request_attachment
                          : process.env.REACT_APP_FILE_BASE_URL +
                            "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                      }
                      // src={`${process.env.REACT_APP_FILE_BASE_URL}/${editPageViewServiceDetail?.service_request_attachment}`}
                      alt="service_image"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            editPageViewServiceDetail?.service_request_attachment
                        );
                      }}
                    />
                  </p>
                </div>
              )}
              {editPageViewServiceDetail?.service_image && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.response_img")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    <img
                      className="view_page_img_icon_view"
                      style={{ width: "100px", height: "100px" }}
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${editPageViewServiceDetail?.service_image}`}
                      alt="service_image"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            editPageViewServiceDetail?.service_image
                        );
                      }}
                    />
                  </p>
                </div>
              )}
              {editPageViewServiceDetail?.service_remark && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.Remarks")}
                  </h2>
                  <p className="inquiry_complaint_detail_name service_request_description">
                    {editPageViewServiceDetail?.service_remark}
                  </p>
                </div>
              )}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(
                      editPageViewServiceDetail?.created_at,
                      "YYYYMMDD HH:mm:ss"
                    )
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {editPageViewServiceDetail?.updated_at
                    ? moment
                        .utc(
                          editPageViewServiceDetail?.updated_at,
                          "YYYYMMDD HH:mm:ss"
                        )
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.status")}
                </h2>
                {editPageViewServiceDetail?.service_request_status ===
                "PENDING" ? (
                  <p className="inquiry_complaint_detail_name">Pending</p>
                ) : editPageViewServiceDetail?.service_request_status ===
                  "IN_PROGRESS" ? (
                  <p className="inquiry_complaint_detail_name">In Progress</p>
                ) : editPageViewServiceDetail?.service_request_status ===
                  "COMPLETED" ? (
                  <p className="inquiry_complaint_detail_name">Completed</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="service_view_section">
          <div className="inquiry_complaint_detail_section">
            <div className="inquiry_complaint_detail_content">
              <div className="inquiry_complaint_detail_body">
                <h2 className="announcement_page-view-modal">
                  {t("service.status")}
                </h2>
                <div className="inquiry_complaint_detail_text oc_change_status_modal_box_row">
                  <div className="m-0 create_from_row radio_update_service radio_update_service_eidt_modal">
                    <h2 className="radio_update_service_heading">
                      {t("service.change_label")}
                    </h2>
                    <div className="create_from_input_content col_span_2">
                      <Input
                        labelClassName="create_from_radio_label"
                        inputClassName="create_from_radio_checkbox input_none"
                        errorClassName="err_text"
                        type="radio"
                        label={t("service.pending")}
                        id="PENDING1"
                        name="changeStatusModalStatus"
                        value="PENDING"
                        onChange={(e) => onChangeServiceStatus(e)}
                        checked={values.changeStatusModalStatus === "PENDING"}
                        className={`create_from_radio_checkbox_content 
                        ${
                          editPageViewServiceDetail?.service_request_status !==
                            "PENDING" && "disabled_input"
                        }`}
                      />
                      <Input
                        className="create_from_radio_checkbox_content"
                        labelClassName="create_from_radio_label"
                        inputClassName="create_from_radio_checkbox input_none"
                        errorClassName="err_text"
                        type="radio"
                        label={t("service.in_progress")}
                        id="IN_PROGRESS2"
                        name="changeStatusModalStatus"
                        value="IN_PROGRESS"
                        onChange={(e) => onChangeServiceStatus(e)}
                        checked={
                          values.changeStatusModalStatus === "IN_PROGRESS"
                        }
                      />
                      <Input
                        className={`create_from_radio_checkbox_content${
                          editPageViewServiceDetail?.service_request_status ===
                            "PENDING" && "disabled_input"
                        }`}
                        labelClassName="create_from_radio_label"
                        inputClassName="create_from_radio_checkbox input_none"
                        errorClassName="err_text"
                        type="radio"
                        label={t("service.completed")}
                        id="COMPLETED3"
                        name="changeStatusModalStatus"
                        value="COMPLETED"
                        onChange={(e) => onChangeServiceStatus(e)}
                        checked={values.changeStatusModalStatus === "COMPLETED"}
                        // disabled={values.changeStatusModalStatus === "PENDING"}
                      />
                    </div>
                  </div>
                  {values.changeStatusModalStatus === "IN_PROGRESS" && (
                    <>
                      <div className="project_edit_detail_divider modal_input_container">
                        <label className="create_from_label">
                          {t("announcement.response_img")}{" "}
                          {`(${t("service.in_progress")})`}
                        </label>
                        <div className="project_edit_feature_input_file">
                          <Input
                            className="create_from_input_content"
                            labelClassName="create_from_label"
                            errorClassName="err_text"
                            type="file"
                            id={`in_progress_image`}
                            labelOtherProps={
                              <span>
                                {values.in_progress_image ? editIcon : addIcon}
                              </span>
                            }
                            name="in_progress_image"
                            accept="image/*"
                            onChange={(e) => onChangeRespondImg(e)}
                            onClick={(e) => (e.target.value = null)}
                            imageProps={imagePreviewProps(
                              values.in_progress_image,
                              values.in_progress_image?.name
                                ? values.in_progress_image_preview
                                : `${process.env.REACT_APP_FILE_BASE_URL}/${values?.in_progress_image_preview}`,
                              imageRemoveHandler
                            )}
                          />
                        </div>
                        <p className="create_project_review mb-0 block">
                          {t("form.large_size_desc")}
                        </p>
                        {values.respond_image_err && (
                          <span className="err_text">
                            {values.respond_image_err}
                          </span>
                        )}
                      </div>
                      <TextArea
                        className="create_from_input_content modal_input_container"
                        labelClassName="create_from_label"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        type="text"
                        placeholder={t("announcement.Remarks_place")}
                        // label={t("announcement.Remarks")}
                        label={`${t("announcement.Remarks_place")} (${t(
                          "service.in_progress"
                        )})`}
                        id="in_progress_remark"
                        name="in_progress_remark"
                        rows={4}
                        value={values.in_progress_remark}
                        onChange={handleInputChange}
                        //   error={errors?.service_remark || ""}
                      />
                    </>
                  )}
                  {values.changeStatusModalStatus === "COMPLETED" && (
                    <>
                      <div className="project_edit_detail_divider modal_input_container">
                        <label className="create_from_label">
                          {t("announcement.response_img")}{" "}
                          {`(${t("service.completed")})`}
                        </label>
                        <div className="project_edit_feature_input_file">
                          <Input
                            className="create_from_input_content"
                            labelClassName="create_from_label"
                            errorClassName="err_text"
                            type="file"
                            id={`respond_image`}
                            labelOtherProps={
                              <span>
                                {values.respond_image ? editIcon : addIcon}
                              </span>
                            }
                            name="respond_image"
                            accept="image/*"
                            onChange={(e) => onChangeRespondImg(e)}
                            onClick={(e) => (e.target.value = null)}
                            imageProps={imagePreviewProps(
                              values.respond_image,
                              values.respond_image_preview,
                              imageRemoveHandler
                            )}
                          />
                        </div>
                        <p className="create_project_review mb-0 block">
                          {t("form.large_size_desc")}
                        </p>
                        {values.respond_image_err && (
                          <span className="err_text">
                            {values.respond_image_err}
                          </span>
                        )}
                      </div>
                      <TextArea
                        className="create_from_input_content modal_input_container"
                        labelClassName="create_from_label"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        type="text"
                        placeholder={t("announcement.Remarks_place")}
                        // label=`${t("announcement.Remarks")}`
                        label={`${t("announcement.Remarks_place")} (${t(
                          "service.completed"
                        )})`}
                        id="service_remark"
                        name="service_remark"
                        rows={4}
                        value={values.service_remark}
                        onChange={handleInputChange}
                        //   error={errors?.service_remark || ""}
                      />
                    </>
                  )}
                </div>
                <div className="edit_view_service_btn-container">
                  <button
                    type="button"
                    onClick={handleStatusModalClose}
                    className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
                  >
                    {t("common.close")}
                  </button>
                  {statusChange &&
                    editPageViewServiceDetail?.service_request_status !==
                      values.changeStatusModalStatus && (
                      <button
                        type="button"
                        onClick={changeStatusHandler}
                        className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
                      >
                        {t("common.update")}
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default ChangeStatusModal;
