import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../../constants";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  dragDropIcon,
  editIcon,
} from "../../../icons";
import { defaultTimeZone } from "../../../helpers/commonFunction";

const MenuListTable = ({
  list,
  updateMenu,
  name,
  values,
  setValues,
  setImgZoom,
  setImgSrc,
}) => {
  const { t } = useTranslation("common");

  const deleteMenuHandler = (menu) => {
    setValues({
      ...values,
      deleteModal: true,
      deleteModalId: menu,
    });
  };

  return (
    <table className="custom_data_table">
      <thead className="custom_data_table_head">
        <tr>
          <th className="custom_data_table_heading"></th>
          <th className="custom_data_table_heading">{t("table.icon")}</th>
          {name !== "amenity" && (
            <th className="custom_data_table_heading">{t("table.title")}</th>
          )}
          {name !== "amenity" && (
            <th className="custom_data_table_heading">{t("table.app_type")}</th>
          )}
          <th className="custom_data_table_heading">
            {name !== "amenity" ? t("feature.feature_type") : t("table.title")}
          </th>
          <th className="custom_data_table_heading">{t("table.is_active")}</th>
          <th className="custom_data_table_heading">{t("table.createdAt")}</th>
          <th className="custom_data_table_heading">{t("table.updatedAt")}</th>
          <th className="custom_data_table_heading">{t("table.action")}</th>
        </tr>
      </thead>
      <tbody className="custom_data_table_body">
        {list?.map((menu, i) => {
          return (
            <Draggable key={menu.id} draggableId={`${menu.id}`} index={i}>
              {(provided, snapshot) => (
                <tr
                  className="custom_data_table_row"
                  key={menu.id}
                  provided={provided}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <td className="custom_data_table_item table_item">
                    {dragDropIcon}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <img
                      src={
                        process.env.REACT_APP_FILE_BASE_URL +
                        "/" +
                        (name === "amenity"
                          ? menu.amenity_icon
                          : menu.sidemenu_icon)
                      }
                      alt={
                        name === "amenity" ? "amenity_icon" : "sidemenu_icon"
                      }
                      className={`custom_data_table_img ${
                        name !== "amenity" && "custom_data_table_img_sidemenu"
                      }`}
                      onClick={() => {
                        setImgZoom(true);
                        if (name === "amenity") {
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              menu.amenity_icon
                          );
                        } else {
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              menu.sidemenu_icon
                          );
                        }
                      }}
                    />
                  </td>
                  {name !== "amenity" && (
                    <td className="custom_data_table_item table_item">
                      {menu.sidemenu_title}
                    </td>
                  )}
                  {name !== "amenity" && (
                    <td className="custom_data_table_item table_item">
                      {menu.app_type_id === 1
                        ? "Mobile App"
                        : menu.app_type_id === 2 && "Admin App"}
                    </td>
                  )}
                  <td className="custom_data_table_item table_item">
                    {name === "amenity"
                      ? menu.amenity_name
                      : menu.feature_display_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.hidden === "0" ? checkIcon : crossIcon}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.created_at &&
                      moment
                        .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.updated_at
                      ? moment
                          .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>

                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                          menu.hidden === "0" ? "red" : "green"
                        }`}
                        onClick={() => updateMenu(menu, "hide")}
                      >
                        {menu.hidden === "0"
                          ? t("feature.deactivate")
                          : t("table.activate")}
                      </button>
                      <div className="tooltip">
                        <Link
                          to={`${
                            name === "amenity"
                              ? routes.amenities
                              : routes.sidemenu
                          }/edit/${menu.id}`}
                          state={
                            name === "amenity"
                              ? { amenity: menu }
                              : {
                                  menu,
                                  value: {
                                    role: values.role,
                                    appStatus: values.appStatus,
                                  },
                                }
                          }
                          className="custom_data_table_view_edit_item_btn"
                        >
                          {editIcon}
                        </Link>
                        <span className="tooltiptext">Edit</span>
                      </div>
                      <div className="tooltip">
                        <button
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => deleteMenuHandler(menu)}
                        >
                          {deleteIcon}
                        </button>
                        <span className="tooltiptext">Delete</span>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </Draggable>
          );
        })}
      </tbody>
    </table>
  );
};

export default MenuListTable;
