import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const ViewFeedbackModal = ({ handleClose, show, feedbackDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main oc_feedback_detail_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("feedback.feedback_detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.user_name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {feedbackDetail?.feedback_by_first_name &&
                  feedbackDetail?.feedback_by_last_name
                    ? feedbackDetail?.feedback_by_first_name +
                      " " +
                      feedbackDetail?.feedback_by_last_name
                    : "-"}
                </p>
              </div>
              {/* {feedbackDetail?.phone_number && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.phoneNumber")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {"+" +
                      feedbackDetail?.country_code +
                      " " +
                      feedbackDetail?.phone_number}
                  </p>
                </div>
              )} */}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.userRole")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {feedbackDetail?.role_display_name
                    ? feedbackDetail?.role_display_name
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {feedbackDetail?.feedback_type_name
                    ? feedbackDetail?.feedback_type_name
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text contact_modal_time ">
                <div>
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.description")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {feedbackDetail?.feedback_description
                      ? feedbackDetail?.feedback_description
                      : "-"}
                  </p>
                </div>
                <div>
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.createdAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {feedbackDetail?.created_at
                      ? moment
                          .utc(feedbackDetail?.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>
                <div>
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.updatedAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {feedbackDetail?.updated_at
                      ? moment
                          .utc(feedbackDetail?.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.feedbackImage")}
                </h2>
                {feedbackDetail?.feedback_type_image ? (
                  <p className="inquiry_complaint_detail_name">
                    <img
                      alt="service_img"
                      className="view_page_img_icon_view"
                      style={{ width: "100px", height: "100px" }}
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${feedbackDetail?.feedback_type_image}`}
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            feedbackDetail?.feedback_type_image
                        );
                      }}
                    />
                  </p>
                ) : (
                  "-"
                )}
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment(feedbackDetail?.created_at).format(
                    "DD/MM/YYYY, hh:mm A"
                  )}
                </p>
              </div> */}
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment(feedbackDetail?.updated_at).format(
                    "DD/MM/YYYY, hh:mm A"
                  )}
                </p>
              </div> */}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default ViewFeedbackModal;
