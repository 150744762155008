import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewServiceModal = ({ handleClose, show, serviceDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("service.service_Detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.first_name + " " + serviceDetail?.last_name}
                </p>
              </div>
              {serviceDetail?.phone_number && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.phoneNumber")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {"+" +
                      serviceDetail?.country_code +
                      " " +
                      serviceDetail?.phone_number}
                  </p>
                </div>
              )}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.location")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_location}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("service.attachment")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_request_attachment}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.description")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_request_description}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.image")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  <img
                    alt="service_img"
                    style={{ width: "100px", height: "100px" }}
                    src={`${process.env.REACT_APP_FILE_BASE_URL}/${serviceDetail?.service_type_image}`}
                  />
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(serviceDetail?.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(serviceDetail?.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.status")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_request_status}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
    </>
  );
};

export default ViewServiceModal;
