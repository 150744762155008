import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import { deleteIcon, viewIcon } from "../../icons";
import { defaultTimeZone } from "../../helpers/commonFunction";

const FeedbackTable = ({ values, setValues, setImgZoom, setImgSrc }) => {
  const { t } = useTranslation("common");

  const feedbacksList = useSelector(
    (state) => state.feedback.feedbackList?.data
  );

  const deleteFeedbackModalConfirmHandler = (menu) => {
    setValues({ ...values, deleteModal: true, deleteModalData: menu.id });
  };

  const viewFeedbackHandler = (menu) => {
    setValues({ ...values, viewModal: true, viewModalData: menu });
  };

  return (
    <div className="custom_data_table_content">
      <table className="custom_data_table">
        <thead className="custom_data_table_head">
          <tr>
            <th className="custom_data_table_heading">{t("table.image")}</th>
            <th className="custom_data_table_heading">{t("table.name")}</th>
            <th className="custom_data_table_heading">{t("table.email")}</th>
            <th className="custom_data_table_heading">
              {t("table.phoneNumber")}
            </th>
            <th className="custom_data_table_heading">{t("form.userRole")}</th>
            <th className="custom_data_table_heading">{t("table.type")}</th>
            <th className="custom_data_table_heading">
              {t("table.createdAt")}
            </th>
            {/* <th className="custom_data_table_heading">
              {t("table.updatedAt")}
            </th> */}
            <th className="custom_data_table_heading">{t("table.action")}</th>
          </tr>
        </thead>
        <tbody className="custom_data_table_body">
          {feedbacksList?.map((menu, i) => {
            return (
              <tr className="custom_data_table_row" key={menu.id}>
                <td className="custom_data_table_item table_item">
                  <img
                    src={
                      menu.feedback_type_image
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.feedback_type_image
                        : process.env.REACT_APP_FILE_BASE_URL +
                          "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                    }
                    alt={"feedback_type_image"}
                    className={`custom_data_table_img `}
                    onClick={() => {
                      setImgZoom(true);
                      setImgSrc(
                        menu.feedback_type_image
                          ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              menu.feedback_type_image
                          : process.env.REACT_APP_FILE_BASE_URL +
                              "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                      );
                    }}
                  />
                </td>
                <td className="custom_data_table_item table_item custom_data_link">
                  <Link to={`${routes.users}/view/${menu.feedback_by}`}>
                    {menu.feedback_by_first_name +
                      " " +
                      menu.feedback_by_last_name}
                  </Link>
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.email ? menu.email : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.phone_number
                    ? "+" + menu.country_code + " " + menu.phone_number
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.role_display_name}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.feedback_type_name}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.created_at &&
                    moment
                      .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                </td>
                {/* <td className="custom_data_table_item table_item">
                  {menu.updated_at
                    ? moment(menu.updated_at).format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </td> */}
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row">
                    <div className="tooltip">
                      <button
                        className="custom_data_table_view_edit_item_btn"
                        onClick={() => viewFeedbackHandler(menu)}
                      >
                        {viewIcon}
                      </button>
                      <span className="tooltiptext">View</span>
                    </div>
                    <div className="tooltip">
                      <button
                        onClick={() => deleteFeedbackModalConfirmHandler(menu)}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {deleteIcon}
                      </button>
                      <span className="tooltiptext">Delete</span>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackTable;
