import React from "react";
import { eyeIcon, hideEyeIcon } from "../../../../icons";
import Button from "../../Button";
import Input from "../../Input";

const LoginPageForm = ({
  values,
  errors,
  setErrors,
  setValues,
  handleInputChange,
  signInHandler,
}) => {
  const eyeIconHandler = () => {
    setValues({
      ...values,
      inputType: values.inputType === "password" ? "text" : "password",
    });
  };

  // const emailPhoneToggleHandler = () => {
  //   setValues({
  //     ...values,
  //     emailPhoneToggle: !values.emailPhoneToggle,
  //     phone_number_error: "",
  //     password_error: "",
  //     password: "",
  //   });
  //   setErrors({});
  // };

  return (
    <>
      {/* {values.emailPhoneToggle ? ( */}
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type="email"
        placeholder="Email"
        label="Email"
        id="email"
        name="email"
        value={values.email}
        onChange={(e) => {
          setValues({
            ...values,
            email: e.target.value,
          });
          setErrors({
            ...errors,
            email: "",
          });
        }}
        error={errors?.email || ""}
      />
      {/* ) : ( */}
      {/* <div className="login_input_row">
        <label>Phone Number</label>
        <PhoneInput
          placeholder="Phone number"
          value={values.phone_number}
          defaultCountry="TH"
          onChange={(e) => {
            setValues({
              ...values,
              phone_number: e,
            });
            setErrors({
              ...errors,
              phone_number: "",
            });
          }}
        />
        {errors.phone_number && (
          <span className="err_text">{errors.phone_number}</span>
        )}
      </div> */}
      {/* )} */}
      <Input
        className="login_input_row null"
        errorClassName="err_text"
        type={values.inputType}
        placeholder="Password"
        label="Password"
        id="password"
        name="password"
        value={values.password}
        onChange={handleInputChange}
        error={errors?.password || values.password_error || ""}
        eyeIcon={values.inputType === "password" ? eyeIcon : hideEyeIcon}
        eyeIconHandler={eyeIconHandler}
        autoComplete="on"
      />
      <Button
        type="submit"
        buttonClassName="login_btn"
        onClick={signInHandler}
        text=" Sign In"
      />
      {/* <span className="login_btn_span" onClick={emailPhoneToggleHandler}>
        {values.emailPhoneToggle
          ? "Login with Phone number"
          : "Login with Email"}
      </span> */}
    </>
  );
};

export default LoginPageForm;
