import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  getFeedbackTypes,
  updateFeedbackType,
} from "../../store/slice/feedbackSlice";

const DeleteFeedbacktypeModal = ({ handleClose, values, setValues }) => {
  const showHideClassName = values?.deleteTypeModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const deleteFeedbackTypeHandler = (menu) => {
    const data = {
      delete_status: 1,
    };
    dispatch(
      updateFeedbackType({
        data,
        id: values.deleteModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getFeedbackTypes());
            setValues({
              ...values,
              deleteTypeModal: false,
              deleteModalId: "",
            });
          }
        },
        toast: t("toast.delete_feedbacktype"),
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("modal.delete.deleteFeedbacktype")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteFeedbacktypeDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteFeedbackTypeHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteFeedbacktypeModal;
