import React from "react";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { directoryTypeValidate } from "../../component/Common/PageComponent/DirectoryComponents";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { addIcon, editIcon } from "../../icons";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useRef } from "react";
import {
  createDirectoryType,
  getDirectoryTypes,
  updateDirectoryType,
} from "../../store/slice/directorySlice";
import { useTranslation } from "react-i18next";

const DirectoryTypeForm = ({
  updateState,
  setUpdateState,
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(false);

  const { t } = useTranslation("common");

  const directoryTypes = useSelector(
    (state) => state.directory.directoryTypeList
  );

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  useEffect(() => {
    setErrors({ ...errors, directoryType_name: "", directoryType_icon: "" });
    dispatch(getDirectoryTypes());
  }, []);

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        directoryType_name: updateState.directory_type_name
          ? updateState.directory_type_name
          : "",
        directoryType_icon: updateState.directory_type_image
          ? updateState.directory_type_image
          : "",
        directoryType_icon_preview: updateState.directory_type_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.directory_type_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      directoryType_icon: "",
      directoryType_icon_preview: "",
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return directoryTypeValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors,
      t
    );
  };

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.directoryType_name && values.directoryType_icon) {
      setErrors({
        ...errors,
        directoryType_name: "",
        directoryType_icon: "",
      });
    } else if (values.directoryType_name) {
      setErrors({ ...errors, directoryType_name: "" });
    } else if (values.directoryType_icon) {
      setErrors({ ...errors, directoryType_icon: "" });
    }
  }, [values.directoryType_icon, values.directoryType_name]);

  const createDirectoryTypeHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.directoryType_icon &&
        typeof values.directoryType_icon !== "string"
      ) {
        formData.append("file", values.directoryType_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            directoryType_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.directoryType_icon;
      }

      const data = {
        directory_type_image:
          typeof values.directoryType_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
        directory_type_name: values.directoryType_name,
      };

      updateState
        ? dispatch(
            updateDirectoryType({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getDirectoryTypes());
                  setValues({
                    ...values,
                    directoryType_name: "",
                    directoryType_icon: "",
                    directoryType_icon_preview: "",
                  });

                  ref.current = false;

                  setUpdateState("");
                }
              },
              toast: t("toast.update_directorytype"),
            })
          )
        : dispatch(
            createDirectoryType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getDirectoryTypes());
                  setValues({
                    ...values,
                    directoryType_name: "",
                    directoryType_icon: "",
                    directoryType_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_directorytype"),
            })
          );
    }
  };

  return (
    <>
      <div className="create_from_row">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {`${updateState ? t("common.edit") : t("common.create")}`}{" "}
            {t("directory.directory_type")}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`directoryType_icon`}
                labelOtherProps={
                  <span>{!values.directoryType_icon ? addIcon : editIcon}</span>
                }
                name="directoryType_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.directoryType_icon,
                  values.directoryType_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.directoryType_icon && (
              <span className="err_text">{errors?.directoryType_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.name_place")}
              label={t("form.name")}
              id="directoryType_name"
              name="directoryType_name"
              value={values.directoryType_name}
              onChange={handleInputChange}
              error={errors?.directoryType_name || ""}
            />
          </div>
          {fileUploadLoader ? (
            <div className="project_submit_bottom_btn center_back_btn">
              <span className="comman_btn">Loading...</span>
            </div>
          ) : (
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createDirectoryTypeHandler}
              text={`${updateState ? t("common.save") : t("common.create")}`}
            />
          )}
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {directoryTypes?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.directory_type_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.directory_type_image
                        }
                        alt="directory_type_image"
                        className="custom_data_table_img"
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DirectoryTypeForm;
