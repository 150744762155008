import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { deleteIcon, editIcon } from "../../icons";
import { getFloors } from "../../store/slice/floorLevelSlice";
import DeleteFloorModal from "./DeleteFloorModal";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const FloorLevels = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const floorList = useSelector((state) => state.floor.floorsList?.data);
  const floorListLoader = useSelector((state) => state.floor.loader);
  const floorCount = useSelector((state) => state.floor.floorsList?.count);

  const sideHeading = localStorage.getItem("sidebar_component");

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [apiErr, setApiErr] = useState(false);

  useEffect(() => {
    dispatch(
      getFloors({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [dispatch]);

  const deleteFloorModalHandler = (menu) => {
    setDeleteModal(true);
    setDeleteModalId(menu.id);
  };

  const handleClose = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  if (floorListLoader) {
    return <Loader />;
  }

  return (
    <div className="project_edit_main_content">
      <div className="rolemanagement_project_btn_row_flex">
        <h2 className="project_subheading">
          {/* {sideHeading} */}
          {SidebarComponent()}
        </h2>
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createFloorLevel} className="comman_btn ml-auto">
            {t("common.create")}
          </Link>
        </div>
      </div>
      <div className="custom_data_table_content">
        {floorList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !floorList && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">
                  {t("floor.floor/level")}
                </th>
                <th className="custom_data_table_heading">
                  {t("floor.location")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.createdAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.updatedAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {floorList?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.floor_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.level_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.created_at &&
                        moment
                          .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.updated_at &&
                        moment
                          .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <Link
                            to={`${routes.floorLevel}/edit/${menu.id}`}
                            state={{ floor: menu }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>
                        <div className="tooltip">
                          <button
                            className="custom_data_table_view_edit_item_btn"
                            onClick={() => deleteFloorModalHandler(menu)}
                          >
                            {deleteIcon}
                          </button>
                          <span className="tooltiptext">Delete</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <DeleteFloorModal
        show={deleteModal}
        serviceDetail={deleteModalId}
        handleClose={handleClose}
        setDeleteModal={setDeleteModal}
        setDeleteModalId={setDeleteModalId}
      />
    </div>
  );
};

export default FloorLevels;
