import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import {
  amenityValidate,
  createAmenityData,
} from "../../component/Common/PageComponent/AmenityComponents";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { useForm } from "../../hooks/useForm";
import { addIcon, editIcon } from "../../icons";
import { createAmenity, updateAmenity } from "../../store/slice/amenitySlice";
import { getFeatures } from "../../store/slice/rolemanagementSlice";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const CreateAmenities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.amenity;

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  useEffect(() => {
    dispatch(getFeatures({}));
  }, [dispatch]);

  let amenityLoader = useSelector((state) => state.amenity.loader);
  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);
  const featureList = useSelector(
    (state) => state.rolemanagement.featuresList?.data
  );
  const featureListOptions = featureList?.map((item) => {
    return {
      value: item.feature_display_name,
      label: item.feature_display_name,
      id: item.id,
    };
  });

  let initialImagePreview;

  if (editComponent?.amenity_icon) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.amenity_icon}`;
  }

  const initialFValues = {
    amenity_icon: editComponent ? editComponent?.amenity_icon : "",
    amenity_icon_preview: editComponent ? initialImagePreview : "",
    amenity_name: editComponent ? editComponent?.amenity_name : "",
    feature_display_name: editComponent
      ? {
          value: editComponent?.feature_display_name,
          label: editComponent?.feature_display_name,
          id: editComponent?.feature_id,
        }
      : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return amenityValidate(fieldValues, temp, setValues, values, setErrors, t);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({ ...values, amenity_icon: "", amenity_icon_preview: "" });
  };

  const amenitiesHandler = async (e) => {
    if (validate()) {
      const data = await createAmenityData(dispatch, values, setValues);

      editComponent
        ? dispatch(
            updateAmenity({
              data,
              id: editComponent.id,
              navigate,
              toastText: t("toast.update_amenity"),
            })
          )
        : dispatch(
            createAmenity({ data, navigate, toast: t("toast.create_amenity") })
          );
    }
  };

  if (amenityLoader || fileUploadLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {/* {editComponent ? `${sideHeading} > Edit` : `${sideHeading} > Create`} */}
          {editComponent
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="project_edit_detail_column_content">
            <div className="project_edit_detail_divider">
              <label className="create_from_label required">
                {t("table.icon")}
              </label>
              <div className="project_edit_feature_input_file">
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  errorClassName="err_text"
                  type="file"
                  id={`amenity_icon`}
                  labelOtherProps={
                    <span>{!values.amenity_icon ? addIcon : editIcon}</span>
                  }
                  name="amenity_icon"
                  accept="image/*"
                  onChange={handleInputChange}
                  onClick={(e) => (e.target.value = null)}
                  imageProps={imagePreviewProps(
                    values.amenity_icon,
                    values.amenity_icon_preview,
                    imageRemoveHandler
                  )}
                />
              </div>
              <p className="create_project_review mb-0 block">
                {t("form.xsmall_size_desc")}
              </p>
              {errors?.amenity_icon && (
                <span className="err_text">{errors?.amenity_icon}</span>
              )}
            </div>
          </div>

          <div className="project_edit_feature_input mt-0">
            <Input
              className="project_edit_feature_input mt-0 mb-15"
              labelClassName="create_from_label required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.name_place")}
              label={t("announcement.title")}
              id="amenity_name"
              name="amenity_name"
              value={values.amenity_name}
              onChange={handleInputChange}
              error={errors?.amenity_name || ""}
            />
            <label className="create_from_label required">
              {t("form.feature_type")}
            </label>
            <Select
              placeholder={t("form.feature_type_place")}
              value={values.feature_display_name}
              className="basic-multi-select"
              classNamePrefix="select"
              name="feature_display_name"
              options={featureListOptions}
              onChange={(e) => handleInputChange(e, "feature_display_name")}
            />
            {errors?.feature_display_name && (
              <span className="err_text">{errors?.feature_display_name}</span>
            )}
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={amenitiesHandler}
          text={t("common.save")}
        />
      </div>
    </>
  );
};

export default CreateAmenities;
