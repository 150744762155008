import { isValidPhoneNumber } from "react-phone-number-input";

export const visitorInviteValidation = (
  editComponent,
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  t
) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("tenant_name" in fieldValues) {
    temp.tenant_name = fieldValues.tenant_name ? "" : t("errors.tenant_name");
  }

  if ("tenant_company" in fieldValues) {
    temp.tenant_company = fieldValues.tenant_company
      ? ""
      : t("errors.tenant_company");
  }

  // if ("first_name" in fieldValues) {
  //   temp.first_name = fieldValues.first_name ? "" : t("errors.first_name");
  // }

  // if ("last_name" in fieldValues) {
  //   temp.last_name = fieldValues.last_name ? "" : t("errors.last_name");
  // }

  // if ("directory_floor_level_id" in fieldValues) {
  //   temp.directory_floor_level_id = fieldValues.directory_floor_level_id
  //     ? ""
  //     : t("errors.floor_level");
  // }

  // if ("contact" in fieldValues) {
  //   temp.contact = fieldValues.contact ? "" : t("errors.contact");
  // }
  // if (
  //   fieldValues.visitor_phone_number !== undefined &&
  //   fieldValues.visitor_phone_number !== ""
  // ) {
  //   if ("visitor_phone_number" in fieldValues) {
  //     temp.visitor_phone_number = isValidPhoneNumber(
  //       fieldValues.visitor_phone_number
  //     )
  //       ? ""
  //       : t("errors.phone_valid");
  //   }
  // }

  // Validate the first email
  if (!editComponent && "email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = t("errors.email");
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : t("errors.email_val");
    }
  }

  if (!editComponent) {
    const emailFields = ["email_one", "email_two", "email_three", "email_four"];
    const uniqueEmails = [];

    emailFields.forEach((fieldName) => {
      if (fieldName in fieldValues && fieldValues[fieldName]) {
        // Check if the email is in a valid format
        const isValidFormat = fieldValues[fieldName].match(mailReg);

        // Check if the email is unique among previous fields, including "email"
        const isUnique =
          !uniqueEmails.includes(fieldValues[fieldName]) &&
          (fieldValues["email"] !== fieldValues[fieldName] ||
            uniqueEmails.length === 0);

        if (!isValidFormat) {
          temp[fieldName] = t("errors.email_val");
        } else if (!isUnique) {
          temp[fieldName] = t("errors.unique_email");
        } else {
          temp[fieldName] = ""; // Clear the error message
          uniqueEmails.push(fieldValues[fieldName]);
        }
      }
    });
  }

  if ("date" in fieldValues) {
    // if ("email_one" in fieldValues) {
    //   if (!fieldValues.email_one) {
    //     temp.email_one = t("errors.email");
    //   } else {
    //     temp.email_one = fieldValues.email_one.match(mailReg)
    //       ? ""
    //       : t("errors.email_val");
    //   }
    //   // temp.email = fieldValues.email ? "" : t("errors.email");
    // }
    // if ("email_two" in fieldValues) {
    //   if (!fieldValues.email_two) {
    //     temp.email_two = t("errors.email");
    //   } else {
    //     temp.email_two = fieldValues.email_two.match(mailReg)
    //       ? ""
    //       : t("errors.email_val");
    //   }
    //   // temp.email = fieldValues.email ? "" : t("errors.email");
    // }
    // if ("email_three" in fieldValues) {
    //   if (!fieldValues.email_three) {
    //     temp.email_three = t("errors.email");
    //   } else {
    //     temp.email_three = fieldValues.email_three.match(mailReg)
    //       ? ""
    //       : t("errors.email_val");
    //   }
    //   // temp.email = fieldValues.email ? "" : t("errors.email");
    // }
    // if ("email_four" in fieldValues) {
    //   if (!fieldValues.email_four) {
    //     temp.email_four = t("errors.email");
    //   } else {
    //     temp.email_four = fieldValues.email_four.match(mailReg)
    //       ? ""
    //       : t("errors.email_four");
    //   }
    //   // temp.email = fieldValues.email ? "" : t("errors.email");
    // }

    if (!fieldValues.date) {
      temp.date = t("errors.date");
    } else if (values.status !== "rejected" && values.status !== "cancelled") {
      const currentDate = new Date();
      const selectedDate = new Date(fieldValues.date);
      if (currentDate.getTime() < selectedDate.getTime()) {
        temp.date = "";
      } else {
        temp.date = t("errors.time_val");
      }
    }
  }
  // if ("time" in fieldValues) {
  //   temp.time = fieldValues.time ? "" : t("errors.time");
  // }
  // if ("purpose" in fieldValues) {
  //   temp.purpose = fieldValues.purpose ? "" : t("errors.purpose");
  // }

  // console.log("temp", temp);

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
