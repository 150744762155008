import React from "react";

const ViewImageModal = ({ setImgZoom, imgZoom, imgSrc }) => {
  return (
    <>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="table img" />
      </div>
    </>
  );
};

export default ViewImageModal;
