import React from "react";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import {
  createDocType,
  getDocType,
  updateDocType,
} from "../../store/slice/docTypeSlice";

const CreateDocType = ({
  docTypeForm,
  setDocTypeForm,
  setDocTypeId,
  docTypeId,
  title,
  setTitle,
  titleErr,
  setTitleErr,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = docTypeForm && "user_detail_modal_show";

  const validation = () => {
    let isValid = false;
    if (!title) {
      setTitleErr("Document type is required");
    } else {
      isValid = true;
    }
    return isValid;
  };

  const addDocTypeHandler = () => {
    if (validation()) {
      const data = {
        title: title,
      };
      docTypeId
        ? dispatch(
            updateDocType({
              data,
              id: docTypeId,
              only_edit: true,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  setTitle("");
                  setDocTypeId("");
                  setDocTypeForm(false);
                  dispatch(getDocType({}));
                }
              },
            })
          )
        : dispatch(
            createDocType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  setTitle("");
                  setDocTypeId("");
                  setDocTypeForm(false);
                  dispatch(getDocType({}));
                }
              },
            })
          );
    }
  };

  const handleClose = () => {
    setDocTypeForm(false);
    setDocTypeId();
    setTitle("");
    setTitleErr("");
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main add_modal_container ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section tag-container">
          <h2 className="announcement_page-view-modal">
            {docTypeId ? "Edit Doc Type" : "Add Doc Type"}
          </h2>
          <div className="">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={"Enter Document Type"}
              label={"Document Type"}
              id="title"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleErr("");
              }}
              error={titleErr || ""}
            />
            {/* <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={addTagHandler}
          text={tagFormEdit ? "Edit" : "Add"}
        />
        <Button
          type="button"
          onClick={handleClose}
          buttonClassName="comman_btn"
          className="project_submit_bottom_btn center_back_btn"
          text="Close"
        /> */}
          </div>
          <div className="delete_feedback_btn-container">
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={addDocTypeHandler}
              text={docTypeId ? "Edit" : "Add"}
            />
            <Button
              type="button"
              onClick={handleClose}
              buttonClassName="comman_btn"
              className="project_submit_bottom_btn center_back_btn"
              text="Close"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateDocType;
