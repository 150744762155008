import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const ViewRoleDetailModal = ({ handleClose, show, roleDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  const featureList = useSelector(
    (state) => state.rolemanagement.featuresList?.data
  );

  const roleDetailRole = roleDetail?.feature_ids
    ?.split(",")
    ?.map((item) => +item);

  const featureId = featureList?.filter((item) =>
    roleDetailRole?.includes(item.id)
  );

  // useEffect(() => {
  //   dispatch(getFeatures());
  // }, []);

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {/* {`${sideHeading} > View`} */}
            {`${SidebarComponent()} > View`}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("role.viewDisplayName")}
                </h2>
                <p className="inquiry_complaint_detail_name ">
                  {roleDetail?.role_display_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.app_type")}
                </h2>
                <p className="inquiry_complaint_detail_name ">
                  {roleDetail?.app_type_display_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("role.viewActiveFeature")}
                </h2>
                <p className="inquiry_complaint_detail_name d-grid_features">
                  {featureId?.map((item, i) => {
                    return (
                      <span className="feature_display_name" key={i}>
                        {item.feature_display_name}
                      </span>
                    );
                  })}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(roleDetail?.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(roleDetail?.updated_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
    </>
  );
};

export default ViewRoleDetailModal;
