import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createContactLink = createAsyncThunk(
  "createContactLink",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/types/contact-link`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createContact = createAsyncThunk(
  "createContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/message-contacts`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.contact}/page/1`);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateContact = createAsyncThunk(
  "updateContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/message-contacts/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.contact}/page/${data.valuePage}`, {
          state: { value: data.value, valuePage: data.valuePage },
        });
        return response.data;
      }
      if (data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const updateContactLink = createAsyncThunk(
  "updateContactLink",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/types/contact-link/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(routes.contact);
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const getContactLinkType = createAsyncThunk(
  "getContactLinkType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/types/contact-link`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getContact = createAsyncThunk(
  "getContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/message-contacts?search_key=${
          data.search_data || ""
        }&contact_phone_number=${data.phone_number || ""}&start=${
          data.start || 0
        }&limit=${data.limit || 10}`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getContactPost = createAsyncThunk(
  "getContactPost",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/message-contacts/contact-post`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contactList: [],
    contactLinkList: [],
    contactPostList: [],
    createContactComponent: "",
    createContactLinkComponent: "",
    updateContactComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Contact
    builder.addCase(getContact.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.loader = false;
      state.contactList = action.payload;
    });
    builder.addCase(getContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Contact Link
    builder.addCase(getContactLinkType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getContactLinkType.fulfilled, (state, action) => {
      state.loader = false;
      state.contactLinkList = action.payload;
    });
    builder.addCase(getContactLinkType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Contact Posts
    builder.addCase(getContactPost.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getContactPost.fulfilled, (state, action) => {
      state.loader = false;
      state.contactPostList = action.payload;
    });
    builder.addCase(getContactPost.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Contact
    builder.addCase(createContact.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.loader = false;
      state.createContactComponent = action.payload;
    });
    builder.addCase(createContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Contact Link
    builder.addCase(createContactLink.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createContactLink.fulfilled, (state, action) => {
      state.loader = false;
      state.createContactLinkComponent = action.payload;
    });
    builder.addCase(createContactLink.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Contact
    builder.addCase(updateContact.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.loader = false;
      state.updateContactComponent = action.payload;
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default contactSlice.reducer;
