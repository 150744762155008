import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DndContext, closestCorners } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import SortableCountries from "./SortableCountries";
import { useDispatch } from "react-redux";
import {
  getAllCountryCodeList,
  getCountryCode,
  updateCountryCodeModal,
} from "../../../store/slice/countryCodeSlice";
import { toast } from "react-toastify";

const ViewCountryModal = ({ handleClose, show, allList }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");
  const ref = useRef(false);
  const dispatch = useDispatch();

  const [allCountries, setAllCountries] = useState([]);
  const [dragCounter, setDragCounter] = React.useState(0);

  const [activeId, setActiveId] = useState(0);
  const [activePreference, setActivePreference] = useState(0);

  useEffect(() => {
    if (allList) {
      setAllCountries(allList);
    }
  }, [allList]);

  //For handle drag move
  const handleDragMove = (e) => {
    setDragCounter(dragCounter + 1);
  };

  //For handle drag end
  const handleDragEnd = (e) => {
    const { active, over } = e;
    setActiveId(active?.id);
    if (active.id !== over.id) {
      ref.current = true;
      const newCountryArr = [...allCountries];
      const activeIndex = newCountryArr?.findIndex(
        (item) => item?.id === active?.id
      );
      const overIndex = newCountryArr?.findIndex(
        (item) => item?.id === over?.id
      );
      setActivePreference(overIndex);
      const [draggedCountry] = newCountryArr?.splice(activeIndex, 1);
      newCountryArr?.splice(overIndex, 0, draggedCountry);
      setAllCountries(newCountryArr);
    }

    if (dragCounter === 0) {
      const activeIndex = allCountries?.findIndex(
        (item) => item?.id === active?.id
      );
      const currentData = allCountries && allCountries[activeIndex];
    }
    setDragCounter(0);
  };

  const saveDragCountryCodeHandler = () => {
    let updatedArr = [];
    for (let i = 0; i < allCountries.length; i++) {
      const obj = {
        preference: i + 1,
        id: allCountries[i].id,
      };
      updatedArr.push(obj);
    }

    dispatch(
      updateCountryCodeModal({
        updatedArr,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getCountryCode({ start: 0, limit: 10 }));
            dispatch(getAllCountryCodeList({}));
            setActiveId(0);
            setActivePreference(0);
            toast.success("Country code arranged successfully", {
              autoClose: 2000,
            });
          }
        },
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main country_width ${showHideClassName}`}
      >
        <h2 className="country_code_modal_heading">
          {t("setting.policy.headingOne")}
          <br />
          {t("setting.policy.headingTwo")}
        </h2>

        <div className="inquiry_complaint_detail_section country_modal_increased_section">
          <DndContext
            collisionDetection={closestCorners}
            onDragEnd={(e) => handleDragEnd(e)}
            onDragMove={(e) => handleDragMove(e)}
          >
            <div className="inquiry_complaint_detail_content">
              <SortableContext items={allCountries}>
                <div className="inquiry_complaint_detail_body view_country_modal_container">
                  {allCountries?.map((item, index) => (
                    <SortableCountries key={index} item={item} />
                  ))}
                </div>
              </SortableContext>
            </div>
          </DndContext>
          <div className="close_btn_container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn secondary_btn comman_btn"
            >
              {t("common.close")}
            </button>
            {allCountries?.length !== 0 && (
              <button
                className={`comman_btn complaint_view_modalbtn ${
                  !ref.current && "disabled_btn"
                }`}
                onClick={() => saveDragCountryCodeHandler()}
                disabled={!ref.current}
              >
                {t("common.save")}
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewCountryModal;
