import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../constants";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../../helpers/commonFunction";
import Button from "../../../component/Common/Button";
import { deleteIcon, editIcon, searchIcon } from "../../../icons";
import Input from "../../../component/Common/Input";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCountryCodeList,
  getCountryCode,
} from "../../../store/slice/countryCodeSlice";
import Loader from "../../../component/Common/Loader";
import moment from "moment";
import DeleteCountryCode from "./DeleteCountryCode";
import Pagination from "../../../component/Pagination/Pagination";
import ViewCountryModal from "./ViewCountryModal";

const CountryCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const valuePage = location?.state?.valuePage;

  const [pageN, setPageN] = useState(page_number - 1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");

  const { t } = useTranslation("common");
  const list = useSelector(
    (state) => state?.country?.getCountry?.country_codes_list
  );
  const totalCount = useSelector(
    (state) => state?.country?.getCountry?.total_country_codes
  );
  const countryLoader = useSelector((state) => state?.country?.loader);

  const initialFValues = {
    filter_country_name: "",
    openCountryModal: false,
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  //OPEN DELETE MODAL
  const deleteModalHandler = (item) => {
    setDeleteModal(true);
    setDeleteModalId(item.id);
  };

  //CLOSE DELETE MODAL
  const handleClose = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.countryCodes}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getCountryCode({
              search: encodeURIComponent(values.filter_country_name),
              start: pageN,
              limit: 10,
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [values.filter_country_name]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getCountryCode({
          search: encodeURIComponent(values.filter_country_name),
          start: pageN,
          limit: 10,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getCountryCode({
        search: encodeURIComponent(values.filter_country_name),
        start: (valuePage - 1) * 10,
        limit: 10,
      })
    );
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  //OPEN COUNTRY VIEW MODAL
  const openCountryModal = () => {
    dispatch(getAllCountryCodeList({}));
    setValues({ ...values, openCountryModal: true });
  };

  const allCountryList = useSelector(
    (state) => state?.country?.allList?.country_codes_list
  );

  //CLOSE COUNTRY VIEW MODAL
  const closeCountryModal = () => {
    setValues({ ...values, openCountryModal: false });
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">Country Codes</h2>
          <div className="creat_edit_project_btn_row creat_edit_project_btn_row_flex">
            <Link
              to={routes.settings}
              className="comman_btn secondary_btn mr-20"
            >
              {t("common.back")}
            </Link>
            <button
              className="comman_btn mr-20"
              onClick={() => openCountryModal()}
            >
              {t("common.set") + " " + t("common.Order")}
            </button>
            <Link
              to={routes.createCountryCodes}
              state={{
                // values,
                page_number,
              }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("table.search_by_name_code")}
              id="filter_country_name"
              name="filter_country_name"
              value={values.filter_country_name}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
        </div>

        {countryLoader ? (
          <Loader />
        ) : list?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.country_name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.country_code")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {list?.length > 0 &&
                  list?.map((item, i) => {
                    return (
                      <tr className="custom_data_table_row" key={item.id}>
                        <td className="custom_data_table_item table_item">
                          {item.country_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.country_code}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.created_at &&
                            moment
                              .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.updated_at
                            ? moment
                                .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <div className="tooltip">
                              <Link
                                to={`${routes.countryCodes}/edit/${item.id}`}
                                state={{
                                  item: item,
                                  page_number,
                                  //   values,
                                }}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </Link>
                              <span className="tooltiptext">Edit</span>
                            </div>

                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteModalHandler(item)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        <Pagination totalRecords={totalCount} onPageChange={onPageChange} />

        <DeleteCountryCode
          show={deleteModal}
          deleteModalId={deleteModalId}
          setDeleteModal={setDeleteModal}
          setDeleteModalId={setDeleteModalId}
          handleClose={handleClose}
          values={values}
          page_number={page_number}
        />
        <ViewCountryModal
          handleClose={closeCountryModal}
          show={values.openCountryModal}
          allList={allCountryList}
        />
      </div>
    </>
  );
};

export default CountryCode;
