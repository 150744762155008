import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../store/slice/userSlice";
import "../../css/component.css";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import { logoutIcon, sidebarToggleIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { clearSidemenuList } from "../../store/slice/sidemenuSlice";

const Navbar = () => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation("common");

  const languageChangeHandler = (e) => {
    if (e.target.checked) {
      i18n.changeLanguage("th");
      localStorage.setItem("language", "th");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  };

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(clearSidemenuList());
    toast.success("Logout successfully !", {
      autoClose: 2000,
    });
  };

  return (
    <header className="top_header_section">
      <label htmlFor="sidebar_toggel" className="sidebar_toggel_btn">
        {sidebarToggleIcon}
      </label>
      <ul className="top_navbar_nav">
        <li className="top_navbar_item">
          {/* <div> */}
          <label className="language-switcher">
            <input
              type="checkbox"
              id="language"
              name="language"
              onChange={languageChangeHandler}
              checked={localStorage.getItem("language") === "th"}
            />
            <span className="slider round"></span>
            <span className="select-fr">EN</span>
            <span className="select-en">TH</span>
          </label>
          {/* </div> */}
        </li>
        <li className="top_navbar_item">
          <Link
            className="top_navbar_logout_btn"
            to={routes.login}
            onClick={logoutHandler}
          >
            {logoutIcon}
            <span>{t("navbar.logout")}</span>
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Navbar;
