import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteIcon, editIcon, viewIcon } from "../../icons";
import { routes } from "../../constants";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ServiceTable = ({
  values,
  setValues,
  setImgZoom,
  setImgSrc,
  viewChangeServiceStatusModal,
  page_number,
  editServiceModalHandler,
}) => {
  const service = useSelector((state) => state.service.serviceList?.data);
  const { t } = useTranslation("common");

  const deleteServiceHandler = (menu) => {
    setValues({ ...values, deleteServiceModal: true, deleteServiceData: menu });
  };

  return (
    <div className="custom_data_table_content">
      <table className="custom_data_table">
        <thead className="custom_data_table_head">
          <tr>
            <th className="custom_data_table_heading">{t("table.image")}</th>
            <th className="custom_data_table_heading">
              {t("service.service_type")}
            </th>
            <th className="custom_data_table_heading">
              {t("floor.floor/level")}
            </th>
            <th className="custom_data_table_heading">
              {t("floor.locationOn")}
            </th>
            <th className="custom_data_table_heading">{t("table.name")}</th>
            <th className="custom_data_table_heading">
              {t("table.phoneNumber")}
            </th>
            <th className="custom_data_table_heading">{t("table.role")}</th>
            <th className="custom_data_table_heading">{t("table.status")}</th>
            <th className="custom_data_table_heading">
              {t("table.createdAt")}
            </th>
            <th className="custom_data_table_heading">
              {t("table.updatedAt")}
            </th>
            <th className="custom_data_table_heading">{t("table.action")}</th>
          </tr>
        </thead>
        <tbody className="custom_data_table_body">
          {service?.map((menu, i) => {
            return (
              <tr className="custom_data_table_row" key={menu.id}>
                <td className="custom_data_table_item table_item">
                  <img
                    src={
                      menu.service_type_image
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.service_type_image
                        : process.env.REACT_APP_FILE_BASE_URL +
                          "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                    }
                    alt={"service_type_image"}
                    className={`custom_data_table_img `}
                    onClick={() => {
                      setImgZoom(true);
                      setImgSrc(
                        menu.service_type_image
                          ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              menu.service_type_image
                          : process.env.REACT_APP_FILE_BASE_URL +
                              "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                      );
                    }}
                  />
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.service_type_name}
                </td>
                <td className="custom_data_table_item table_item">
                  {/* {menu.floor_name} */}
                  {menu.service_floor}
                </td>
                <td className="custom_data_table_item table_item">
                  {/* {menu.level_name} */}
                  {menu.service_location}
                </td>
                <td className="custom_data_table_item table_item custom_data_link">
                  <Link to={`${routes.users}/view/${menu.request_by}`}>
                    {menu.first_name + " " + menu.last_name}
                  </Link>
                </td>

                <td className="custom_data_table_item table_item">
                  {menu.phone_number
                    ? "+" + menu.country_code + " " + menu.phone_number
                    : "-"}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.role_display_name}
                </td>

                <td className={`custom_data_table_item table_item `}>
                  {/* <p
                    className={`service_request_back ${
                      menu.service_request_status === "PENDING"
                        ? "pending_status_color"
                        : menu.service_request_status === "IN_PROGRESS"
                        ? "inprogress_status_color"
                        : "completed_status_color"
                    }`}
                  > */}
                  {menu.service_request_status === "PENDING"
                    ? "Pending"
                    : menu.service_request_status === "IN_PROGRESS"
                    ? "In Progress"
                    : "Completed"}
                  {/* </p> */}
                </td>

                <td className="custom_data_table_item table_item">
                  {menu.created_at &&
                    moment
                      .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                </td>
                <td className="custom_data_table_item table_item">
                  {menu.updated_at &&
                    moment
                      .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                </td>
                <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                  <div className="custom_data_table_view_edit_btn_item_row custom_table_three_btn_row">
                    <div className="tooltip">
                      <Link
                        onClick={() => editServiceModalHandler(menu)}
                        className="custom_data_table_view_edit_item_btn"
                        state={{
                          value: {
                            search_key: values.search_key,
                            phone_number: values.phone_number,
                            service_type: values.service_type,
                            service_request_status:
                              values.service_request_status.value,
                          },
                        }}
                      >
                        {viewIcon}
                      </Link>
                      <span className="tooltiptext">View</span>
                    </div>
                    {menu.service_request_status !== "COMPLETED" && (
                      <div className="tooltip">
                        <Link
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => viewChangeServiceStatusModal(menu)}
                          state={{
                            value: {
                              search_key: values.search_key,
                              phone_number: values.phone_number,
                              service_type: values.service_type,
                              service_request_status:
                                values.service_request_status.value,
                            },
                            page_number,
                          }}
                        >
                          {editIcon}
                        </Link>
                        <span className="tooltiptext">Edit</span>
                      </div>
                    )}
                    {menu.service_request_status !== "PENDING" && (
                      <div className="tooltip">
                        <button
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => deleteServiceHandler(menu)}
                        >
                          {deleteIcon}
                        </button>
                        <span className="tooltiptext">Delete</span>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceTable;
