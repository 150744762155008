import { isValidPhoneNumber } from "react-phone-number-input";

export const validateEmail = (temp, fieldValues, values, setErrors) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = "Email is required!";
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : "Please enter a valid email!";
    }
  }

  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : "Password is required!";
  }

  setErrors({
    ...temp,
  });

  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Phone validation in login page
export const phoneValidation = (temp, fieldValues, values, setErrors) => {
  // let isValid = false;
  if ("phone_number" in fieldValues) {
    if (!fieldValues.phone_number) {
      temp.phone_number = "Phone number is required!";
    } else {
      temp.phone_number = isValidPhoneNumber(values.phone_number)
        ? ""
        : "Phone number is not valid!";
    }
  }

  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : "Password is required!";
  }

  setErrors({
    ...temp,
  });

  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Login page phone number code //
export function withoutMetadata(_parsedPhone) {
  if (!_parsedPhone) return _parsedPhone;
  const { metadata: _metadata, ...parsedPhone } = _parsedPhone;
  return parsedPhone;
}
