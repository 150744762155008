import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  cleanFeatures,
  createFeature,
  getAppType,
  getFeatures,
  updateFeature,
} from "../../store/slice/rolemanagementSlice";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { SidebarComponent } from "../../helpers/commonFunction";

const CreateRoleFeature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const { t } = useTranslation("common");

  const roleState = location?.state?.role;

  let featureList = useSelector(
    (state) => state.rolemanagement.featuresList?.data
  );

  const sideHeading = localStorage.getItem("sidebar_component");

  const appTypeList = useSelector((state) => state.rolemanagement.appTypeList);

  const appTypeOptions = appTypeList?.map((item) => {
    return {
      value: item.id,
      label: item.app_type_display_name,
    };
  });

  useEffect(() => {
    // dispatch(getFeatures({}));
    dispatch(getAppType());
  }, []);

  const initialFValues = {
    role_name: roleState ? roleState?.role_display_name : "",
    role_active: roleState
      ? roleState?.is_active === "1"
        ? true
        : false
      : true,
    feature_option: "",
    feature_active: roleState?.feature_ids
      ? roleState?.feature_ids?.split(",")?.map((item) => +item)
      : [],
    app_type: roleState?.app_type_id
      ? {
          value: roleState?.app_type_id,
          label: roleState?.app_type_display_name,
        }
      : "",
    select_all: false,
    none: false,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("role_name" in fieldValues) {
      temp.role_name = fieldValues.role_name ? "" : t("errors.role");
    }

    if ("app_type" in fieldValues) {
      temp.app_type = fieldValues.app_type ? "" : t("errors.app_type");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      dispatch(getFeatures({}));
    }
    dispatch(cleanFeatures());
  }, [values?.feature_option]);

  useEffect(() => {
    if (ref.current || roleState) {
      setValues({
        ...values,
        feature_option: "",
      });
      setTimeout(() => {
        dispatch(getFeatures({ app_type_id: values.app_type.value }));
      }, 100);
    }
    ref.current = true;
  }, [values?.app_type]);

  const featureHandler = (e, feature) => {
    let tempFeatures = [...values.feature_active];
    if (tempFeatures?.includes(feature.id)) {
      let index = tempFeatures.findIndex((obj) => obj === feature.id);
      if (index > -1) {
        tempFeatures.splice(index, 1);
      }
      setValues({
        ...values,
        feature_active: tempFeatures,
      });
    } else {
      setValues({
        ...values,
        feature_active: [...values.feature_active, feature.id],
      });
    }
    // setValues({ ...values, select_all: false, none: false });
  };

  // const featureOptionHandler = (e) => {
  //   setValues({ ...values, feature_option: e.target.value });
  // };

  const selectAllHandler = (e) => {
    setValues({
      ...values,
      feature_active:
        featureList?.length > 0 ? [...featureList.map((i) => i.id)] : [],
    });
    // setValues({ ...values, select_all: true, none: false });
  };

  const noneHandler = (e) => {
    setValues({
      ...values,
      feature_active: [],
      select_all: false,
    });
    // setValues({ ...values, none: true, select_all: false });
  };

  // useEffect(() => {
  //   if (values.select_all) {
  //     setTimeout(() => {
  //       setValues({
  //         ...values,
  //         feature_active:
  //           featureList?.length > 0 ? [...featureList?.map((i) => i.id)] : [],
  //       });
  //     }, 200);
  //   } else if (roleState && !values.select_all) {
  //     setValues({
  //       ...values,
  //       feature_active: roleState?.feature_ids
  //         ? roleState?.feature_ids?.split(",")?.map((item) => +item)
  //         : [],
  //     });
  //   } else if (!roleState && !values.select_all) {
  //     setValues({ ...values, feature_active: [] });
  //   }
  // }, [values.select_all, featureList]);

  // useEffect(() => {
  //   if (values.none) {
  //     setValues({
  //       ...values,
  //       feature_active: [],
  //     });
  //   }
  //   // else if (roleState && !values.none) {
  //   //   setValues({
  //   //     ...values,
  //   //     feature_active: roleState?.feature_ids
  //   //       ? roleState?.feature_ids?.split(",")?.map((item) => +item)
  //   //       : [],
  //   //   });
  //   // } else if (!roleState && !values.none) {
  //   //   setValues({ ...values, feature_active: [] });
  //   // }
  // }, [values.none]);

  const createRoleHandler = () => {
    if (validate()) {
      const data = {
        role_display_name: values.role_name,
        is_active: 1,
        // is_active: values.role_active === true ? 1 : 0,
        // role_ref_code: values.role_code,
        feature_ids: values.feature_active,
        app_type_id: values.app_type.value,
      };

      roleState
        ? dispatch(
            updateFeature({
              data,
              navigate,
              id: roleState.id,
              toast: t("toast.update_feature"),
            })
          )
        : dispatch(
            createFeature({ data, navigate, toast: t("toast.create_feature") })
          );
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {/* {roleState ? `${sideHeading} > Edit` : `${sideHeading} > Create`} */}
          {roleState
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <Link
          to={routes.rolemanagement}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="project_edit_feature_input mt-0"
            labelClassName="create_from_label required"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.namePlace")}
            label={t("form.name")}
            id="role_name"
            name="role_name"
            value={values?.role_name}
            onChange={handleInputChange}
            error={errors?.role_name || ""}
          />
          <div className="project_edit_feature_input mt-0">
            <label className="create_from_label required">
              {t("form.app_type")}
            </label>
            <Select
              placeholder={t("form.app_type_place")}
              value={values?.app_type}
              className="basic-multi-select"
              classNamePrefix="select"
              name="app_type"
              options={appTypeOptions}
              onChange={(e) => handleInputChange(e, "app_type")}
            />
            {errors?.app_type && (
              <span className="err_text">{errors?.app_type}</span>
            )}
          </div>

          {/* <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter role code"
            label="Code"
            id="role_code"
            name="role_code"
            value={values.role_code}
            onChange={handleInputChange}
            error={errors?.role_code || ""}
          /> */}
          {/* <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName={`create_from_radio_label_role`}
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label="Is Active"
              id="role_active"
              name="role_active"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.role_active}
            />
          </div> */}
          <div className="create_role_heaing_radio_row">
            <label className="create_from_radio_label_role ml-0 feature_label">
              {t("form.features")}
            </label>
            {/* <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName={`create_from_radio_label_role`}
              inputClassName="create_from_radio_checkbox"
              type="radio"
              label={t("form.all_features")}
              id="feature_option_1"
              name="feature_option"
              value="all"
              onChange={(e) => featureOptionHandler(e)}
              checked={values?.feature_option === "all"}
            /> */}
            {(values?.app_type || values?.feature_option) && (
              <>
                <button
                  className={`comman_btn btn-toggle ${
                    !values?.select_all && "inactive_btn"
                  }`}
                  onClick={selectAllHandler}
                >
                  Select All
                </button>
                <button
                  className={`comman_btn btn-toggle inactive_btn`}
                  onClick={noneHandler}
                >
                  Clear All
                </button>
              </>
            )}
            {/* {(values.app_type || values.feature_option) && (
              <Input
                className="create_from_radio_checkbox_contentainer"
                labelClassName={`create_from_radio_label_role`}
                inputClassName="create_from_radio_checkbox"
                type="checkbox"
                label={t("form.select_all")}
                id="select_all"
                name="select_all"
                value="all"
                onChange={(e) => selectAllHandler(e)}
                checked={values?.select_all}
              />
            )} */}
            {/* <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName={`create_from_radio_label_role`}
              inputClassName="create_from_radio_checkbox"
              type="checkbox"
              label={t("form.none")}
              id="none"
              name="none"
              value="none"
              onChange={(e) => noneHandler(e)}
              checked={values?.none}
            /> */}
            <div className="create_role_heaing_radio_row_input">
              {featureList?.map((item, i) => {
                return (
                  <div key={item.id}>
                    <Input
                      className="create_from_radio_checkbox_contentainer"
                      labelClassName={`create_from_radio_label_role`}
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={item.feature_display_name}
                      id={item.feature_display_name}
                      name="feature_active"
                      value={item.id}
                      onChange={(e) => featureHandler(e, item)}
                      checked={values?.feature_active?.includes(item.id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="create_from_row create_from_four_row"> */}
        {/* <label className="create_from_radio_label_role ml-0">
            {t("form.app_type")} :
          </label> */}

        {/* <Input
            className="create_from_radio_checkbox_contentainer"
            labelClassName={`create_from_radio_label_role`}
            inputClassName="create_from_radio_checkbox"
            type="radio"
            label="Mobile App Features"
            id="feature_option_2"
            name="feature_option"
            value="Mobile App"
            onChange={(e) => featureOptionHandler(e)}
            checked={values?.feature_option === "Mobile App"}
          />
          <Input
            className="create_from_radio_checkbox_contentainer"
            labelClassName={`create_from_radio_label_role`}
            inputClassName="create_from_radio_checkbox"
            type="radio"
            label="Admin App Features"
            id="feature_option_3"
            name="feature_option"
            value="Admin App"
            onChange={(e) => featureOptionHandler(e)}
            checked={values?.feature_option === "Admin App"}
          /> */}
        {/* </div> */}
        {/* <div className="create_from_row create_from_four_row"></div>
        <div className="create_from_row create_from_four_row"></div> */}
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createRoleHandler}
          text={`${roleState ? t("common.save") : t("common.create")}`}
        />
      </div>
    </>
  );
};

export default CreateRoleFeature;
