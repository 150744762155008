import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { fileUpload } from "../../../store/slice/fileUploadSlice";
import { withoutMetadata } from "./LoginPageComponents";

export const dayName = [
  "",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// export const directoryTypeOptions = [
//   {
//     label: "Food & Bevarages",
//     value: "FB",
//   },
//   {
//     label: "Shops",
//     value: "SH",
//   },
// ];

export const dateFunction = (time) => {
  return moment(`2016-12-08 ${time}`, "YYYY-MM-DD h:m:s A").format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const directoryTypeValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("directoryType_icon" in fieldValues) {
    temp.directoryType_icon = "";
    if (!fieldValues.directoryType_icon) {
      temp.directoryType_icon = t("errors.icon_require");
    }
    if (fieldValues.directoryType_icon.size > 1000000) {
      temp.directoryType_icon = t("errors.large_icon_size");
      setValues({
        ...values,
        directoryType_icon_preview: "",
        directoryType_icon: "",
      });
    }
    if (
      fieldValues.directoryType_icon?.name &&
      !fieldValues.directoryType_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.directoryType_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        directoryType_icon_preview: "",
        directoryType_icon: "",
      });
    }
  }

  if ("directoryType_name" in fieldValues) {
    temp.directoryType_name = fieldValues.directoryType_name
      ? ""
      : t("errors.name");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const getInitialDirectoryState = (directory) => {
  return {
    directory_name: directory ? directory?.directory_name : "",
    directory_short_description: directory
      ? directory?.directory_short_description
      : "",
    directory_description: directory ? directory?.directory_description : "",
    directory_type: directory
      ? {
          label: directory?.directory_type_name,
          value: directory?.directory_type,
        }
      : "",
    directory_logo: directory ? directory?.directory_logo : "",
    directory_logo_preview: directory
      ? `${process.env.REACT_APP_FILE_BASE_URL}/${directory?.directory_logo}`
      : "",
    directory_main_image: directory ? directory?.directory_main_image : "",
    directory_main_image_preview: directory
      ? `${process.env.REACT_APP_FILE_BASE_URL}/${directory?.directory_main_image}`
      : "",
    directory_contact_number: directory
      ? (directory.directory_contact_country_code != "") ? "+" +
        directory.directory_contact_country_code +
        directory.directory_contact_number : ""
      : "",
    directory_floor_level_id: directory
      ? {
          value: directory.level_name + " - " + directory.floor_name,
          label: directory.level_name + " - " + directory.floor_name,
          id: directory.directory_floor_level_id,
        }
      : "",
    directory_website_link: directory ? directory.directory_website_link : "",
    directory_email_link: directory ? directory.directory_email_link : "",
    directory_facebook_link: directory ? directory.directory_facebook_link : "",
    directory_opening_times: directory
      ? [
          {
            name: 1,
            id: directory.opening_times[0].id,
            weekday: 1,
            start_hour: new Date(
              dateFunction(directory.opening_times[0].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[0].end_hour)
            ),
            closed: directory.opening_times[0].is_closed
              ? directory.opening_times[0].is_closed
              : "0",
          },
          {
            name: 2,
            id: directory.opening_times[1].id,
            weekday: 2,
            start_hour: new Date(
              dateFunction(directory.opening_times[1].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[1].end_hour)
            ),
            closed: directory.opening_times[1].is_closed
              ? directory.opening_times[1].is_closed
              : "0",
          },
          {
            name: 3,
            id: directory.opening_times[2].id,
            weekday: 3,
            start_hour: new Date(
              dateFunction(directory.opening_times[2].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[2].end_hour)
            ),
            closed: directory.opening_times[2].is_closed
              ? directory.opening_times[2].is_closed
              : "0",
          },
          {
            name: 4,
            id: directory.opening_times[3].id,
            weekday: 4,
            start_hour: new Date(
              dateFunction(directory.opening_times[3].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[3].end_hour)
            ),
            closed: directory.opening_times[3].is_closed
              ? directory.opening_times[3].is_closed
              : "0",
          },
          {
            name: 5,
            id: directory.opening_times[4].id,
            weekday: 5,
            start_hour: new Date(
              dateFunction(directory.opening_times[4].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[4].end_hour)
            ),
            closed: directory.opening_times[4].is_closed
              ? directory.opening_times[4].is_closed
              : "0",
          },
          {
            name: 6,
            id: directory.opening_times[5].id,
            weekday: 6,
            start_hour: new Date(
              dateFunction(directory.opening_times[5].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[5].end_hour)
            ),
            closed: directory.opening_times[5].is_closed
              ? directory.opening_times[5].is_closed
              : "0",
          },
          {
            name: 0,
            id: directory.opening_times[6].id,
            weekday: 7,
            start_hour: new Date(
              dateFunction(directory.opening_times[6].start_hour)
            ),
            end_hour: new Date(
              dateFunction(directory.opening_times[6].end_hour)
            ),
            closed: directory.opening_times[6].is_closed
              ? directory.opening_times[6].is_closed
              : "0",
          },
        ]
      : [
          {
            name: 1,
            weekday: 1,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 2,
            weekday: 2,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 3,
            weekday: 3,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 4,
            weekday: 4,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 5,
            weekday: 5,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 6,
            weekday: 6,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
          {
            name: 0,
            weekday: 7,
            start_hour: "",
            end_hour: "",
            closed: "0",
          },
        ],
    directoryTypeComponent: false,
    directoryType_name: "",
    directoryType_icon: "",
    directoryType_icon_preview: "",
  };
};

export const directoryValidate = (
  temp,
  fieldValues,
  values,
  setValues,
  setErrors,
  t
) => {
  var expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  var regex = new RegExp(expression);

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  var webExpression =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  var webRegex = new RegExp(webExpression);

  if ("directory_name" in fieldValues) {
    temp.directory_name = fieldValues.directory_name ? "" : t("errors.name");
  }

  if ("directory_short_description" in fieldValues) {
    temp.directory_short_description = fieldValues.directory_short_description
      ? ""
      : t("errors.shortdescription");
  }

  if ("directory_description" in fieldValues) {
    temp.directory_description = fieldValues.directory_description
      ? ""
      : t("errors.description");
  }

  if ("directory_type" in fieldValues) {
    temp.directory_type = fieldValues.directory_type ? "" : t("errors.type");
  }

  if ("directory_logo" in fieldValues) {
    temp.directory_logo = "";
    if (!fieldValues.directory_logo) {
      temp.directory_logo = t("errors.logo_require");
    }
    if (fieldValues.directory_logo.size > 1000000) {
      temp.directory_logo = t("errors.small_logo_size");
      setValues({
        ...values,
        directory_logo_preview: "",
        directory_logo: "",
      });
    }
    if (
      fieldValues.directory_logo?.name &&
      !fieldValues.directory_logo?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.directory_logo = t("errors.img_invalid");
      setValues({
        ...values,
        directory_logo_preview: "",
        directory_logo: "",
      });
    }
  }

  if ("directory_main_image" in fieldValues) {
    temp.directory_main_image = "";
    if (!fieldValues.directory_main_image) {
      temp.directory_main_image = t("errors.img_require");
    }
    if (fieldValues.directory_main_image.size > 1000000) {
      temp.directory_main_image = t("errors.large_img_size");
      setValues({
        ...values,
        directory_main_image_preview: "",
        directory_main_image: "",
      });
    }
    if (
      fieldValues.directory_main_image?.name &&
      !fieldValues.directory_main_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.directory_main_image = t("errors.img_invalid");
      setValues({
        ...values,
        directory_main_image_preview: "",
        directory_main_image: "",
      });
    }
  }

  // if ("directory_contact_number" in fieldValues) {
  //   temp.directory_contact_number = fieldValues.directory_contact_number
  //     ? isValidPhoneNumber(fieldValues.directory_contact_number)
  //       ? ""
  //       : t("errors.phone_valid")
  //     : t("errors.phone_required");
  // }
  if ("directory_floor_level_id" in fieldValues) {
    temp.directory_floor_level_id = fieldValues.directory_floor_level_id
      ? ""
      : t("errors.floor_level");
  }

  // if ("directory_website_link" in fieldValues) {
  //   temp.directory_website_link = fieldValues.directory_website_link
  //     ? fieldValues.directory_website_link.match(webRegex)
  //       ? ""
  //       : t("errors.link")
  //     : t("errors.website_link");
  // }

  // if ("directory_email_link" in fieldValues) {
  //   temp.directory_email_link = fieldValues.directory_email_link
  //     ? fieldValues.directory_email_link.match(mailReg)
  //       ? ""
  //       : t("errors.link")
  //     : t("errors.email_link");
  // }

  // if ("directory_facebook_link" in fieldValues) {
  //   temp.directory_facebook_link = fieldValues.directory_facebook_link
  //     ? !fieldValues.directory_facebook_link.match(regex)
  //       ? t("errors.link")
  //       : ""
  //     : t("errors.facebook_link");
  // }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const DirectoryData = async (values, dispatch, setValues) => {
  const parsedPhone =
    values.directory_contact_number &&
    withoutMetadata(parsePhoneNumber(values.directory_contact_number));

  const formData = new FormData();
  let attachmentLogoUrl;
  if (values.directory_logo && typeof values.directory_logo !== "string") {
    formData.append("file", values.directory_logo);
    attachmentLogoUrl = await dispatch(fileUpload(formData));
    if (attachmentLogoUrl && attachmentLogoUrl.payload) {
      setValues({
        ...values,
        directory_logo: attachmentLogoUrl.payload,
      });
    }
    formData.delete("file");
  } else {
    attachmentLogoUrl = values.directory_logo;
  }

  let attachmentDirectoryImageUrl;
  if (
    values.directory_main_image &&
    typeof values.directory_main_image !== "string"
  ) {
    formData.append("file", values.directory_main_image);
    attachmentDirectoryImageUrl = await dispatch(fileUpload(formData));
    if (attachmentDirectoryImageUrl && attachmentDirectoryImageUrl.payload) {
      setValues({
        ...values,
        directory_main_image: attachmentDirectoryImageUrl.payload,
      });
    }
    formData.delete("file");
  } else {
    attachmentDirectoryImageUrl = values.directory_main_image;
  }

  const data = {
    directory_name: values.directory_name,
    directory_short_description: values.directory_short_description,
    directory_description: values.directory_description,
    directory_opening_times: values.directory_opening_times?.map((item) => {
      if (!item.start_hour || !item.end_hour) {
        return {
          weekday: item.name,
          start_hour: "",
          end_hour: "",
          is_closed: item.closed,
          // start_hour: moment("2017-08-30T00:00").format("HH:mm"),
          // end_hour: moment("2017-08-30T00:00").format("HH:mm"),
        };
      } else {
        return {
          id: item.id,
          weekday: item.name,
          start_hour: moment(item.start_hour).format("HH:mm"),
          end_hour: moment(item.end_hour).format("HH:mm"),
          is_closed: item.closed,
        };
      }
    }),
    directory_type: values.directory_type.value,
    directory_logo:
      typeof values.directory_logo !== "string"
        ? attachmentLogoUrl.payload
        : attachmentLogoUrl,
    directory_main_image:
      typeof values.directory_main_image !== "string"
        ? attachmentDirectoryImageUrl.payload
        : attachmentDirectoryImageUrl,
    directory_floor_level_id: values.directory_floor_level_id.id,
    directory_contact_country_code: parsedPhone && parsedPhone.countryCallingCode ? parsedPhone.countryCallingCode : "",
    directory_contact_number: parsedPhone && parsedPhone.nationalNumber ? parsedPhone.nationalNumber : "",
    directory_website_link: values.directory_website_link,
    directory_email_link: values.directory_email_link,
    directory_facebook_link: values.directory_facebook_link,
  };
  return data;
};
