import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createStaticPage = createAsyncThunk(
  "createStaticPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/types/static-page`,
        data.data,
        authHeaders()
      );
      if ((response.status === 200 || response.status === 201) && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.settings);
        return response.data;
      }
      if (data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.settings);
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createToken = createAsyncThunk(
  "createToken",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/roles/token`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.settings);
        return response.data;
      }

      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getVisitorPrivacyPolicy = createAsyncThunk(
  "getVisitorPrivacyPolicy",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/types/static-page?page_type=visitor_privacy_policy`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getPolicyPage = createAsyncThunk(
  "getPolicyPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/types/static-page?page_type=privacy_policy`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getTermsPage = createAsyncThunk(
  "getTermsPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/types/static-page?page_type=t_and_c`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getToken = createAsyncThunk("getToken", async (data, thunkAPI) => {
  try {
    const response = await Axios.get(`/onecity/roles/token`, authHeaders());
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

export const clearPolicy = createAsyncThunk("clearPolicy", async () => {
  return;
});

export const clearTerms = createAsyncThunk("clearTerms", async () => {
  return;
});

export const updateStaticPage = createAsyncThunk(
  "updateStaticPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/types/static-page/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.settings);
        return response.data;
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const publishStaticPage = createAsyncThunk(
  "publishStaticPage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/types/static-page/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });

        // data.navigate(routes.settings);
        return data.cb(null, response.data);
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getMobileConfigDetail = createAsyncThunk(
  "getMobileConfigDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/onecity/check-app-status",
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const addMobileConfigData = createAsyncThunk(
  "addMobileConfigData",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/admin-users/app-status-update?id=${data.id || ""}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Mobile Configuration Updated Successfully !", {
          autoClose: 2000,
        });
        data.navigate(routes.settings);
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    policyList: [],
    termsList: [],
    mobileConfigDetail: "",
    visitorPrivacy: [],
    token: "",
    createSetting: "",
    updateSetting: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Static Page
    builder.addCase(getPolicyPage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPolicyPage.fulfilled, (state, action) => {
      state.loader = false;
      state.policyList = action.payload;
    });
    builder.addCase(getPolicyPage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Get Visitor privacy policy
    builder.addCase(getVisitorPrivacyPolicy.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVisitorPrivacyPolicy.fulfilled, (state, action) => {
      state.loader = false;
      state.visitorPrivacy = action.payload;
    });
    builder.addCase(getVisitorPrivacyPolicy.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Mobile config
    builder.addCase(getMobileConfigDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMobileConfigDetail.fulfilled, (state, action) => {
      state.loader = false;
      state.mobileConfigDetail = action.payload;
    });
    builder.addCase(getMobileConfigDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    builder.addCase(clearPolicy.fulfilled, (state, action) => {
      state.policyList = {};
    });

    // Get Token
    builder.addCase(getToken.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.loader = false;
      state.token = action.payload;
    });
    builder.addCase(getToken.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Static Page
    builder.addCase(getTermsPage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTermsPage.fulfilled, (state, action) => {
      state.loader = false;
      state.termsList = action.payload;
    });
    builder.addCase(getTermsPage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    builder.addCase(clearTerms.fulfilled, (state, action) => {
      state.termsList = {};
    });

    // Create Static page
    builder.addCase(createStaticPage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createStaticPage.fulfilled, (state, action) => {
      state.loader = false;
      state.createSetting = action.payload;
    });
    builder.addCase(createStaticPage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Update Static page
    builder.addCase(updateStaticPage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateStaticPage.fulfilled, (state, action) => {
      state.loader = false;
      state.updateSetting = action.payload;
    });
    builder.addCase(updateStaticPage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default settingSlice.reducer;
