import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const AnnouncementViewModal = ({ handleClose, show, announcementDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const sideHeading = localStorage.getItem("sidebar_component");
  const { t } = useTranslation("common");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {/* {`${sideHeading} > View`} */}
            {`${SidebarComponent()} > View`}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.announcement_time")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(
                      announcementDetail?.announcement_date_and_time,
                      "YYYYMMDD HH:mm:ss"
                    )
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.start_date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment(announcementDetail?.start_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.end_date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment(announcementDetail?.end_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.title")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.announcement_title}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.announcement_type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.announcement_type_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text ">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.userRole")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.role_display_name?.map(
                    (item) => item + ", "
                  )}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text span_3">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.description")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.announcement_description}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.created_by")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.user_name
                    ? announcementDetail?.user_name
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(announcementDetail?.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {announcementDetail?.updated_at
                    ? moment
                        .utc(
                          announcementDetail?.updated_at,
                          "YYYYMMDD HH:mm:ss"
                        )
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Announcement Image
                </h2>
                <p className="inquiry_complaint_detail_name">
                  <img
                    className="view_page_img_icon_view"
                    style={{ width: "100px", height: "100px" }}
                    src={
                      announcementDetail?.announcement_image
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          announcementDetail.announcement_image
                        : process.env.REACT_APP_FILE_BASE_URL +
                          "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                    }
                    alt="directory_image"
                    onClick={() => {
                      setImgZoom(true);
                      setImgSrc(
                        process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          announcementDetail?.announcement_image
                      );
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default AnnouncementViewModal;
