import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDetail } from "../../store/slice/dashboardSlice";
import moment from "moment";
import CsvDownload from "./CsvDownload";
import { useTranslation } from "react-i18next";
import Loader from "../../component/Common/Loader";
import { useCallback } from "react";
import { SidebarComponent } from "../../helpers/commonFunction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");

  const [dateDashboard, setDateDashboard] = useState("");
  const [btnLoaders, setBtnLoaders] = useState({
    pageLoading: false,
    user: { type: "user", isLoading: false },
    feedback: { type: "feedback", isLoading: false },
    service: { type: "service", isLoading: false },
    event: { type: "event", isLoading: false },
    contact: { type: "contact", isLoading: false },
    announcement: { type: "announcement", isLoading: false },
    visitor_invite: { type: "visitor_invite", isLoading: false },
    promotion: { type: "promotion", isLoading: false },
  });

  const sideHeading = localStorage.getItem("sidebar_component");
  let sidebarList = useSelector((state) => state?.sidemenu?.sidemenuList);

  const isDashboard = sidebarList?.some(
    (item) => item.sidemenu_title === "Dashboard"
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const reports = useSelector(
    (state) => state.dashboard.dashboardNumbers
  )?.reports;

  const dashBoardLoader = useSelector((state) => state.dashboard.loader);

  const userReport = useSelector((state) => state.dashboard.eventReport)
    ?.reports?.events;

  useEffect(() => {
    const data = {
      sdate:
        dateDashboard.value && moment(dateDashboard.value).format("YYYY-MM-DD"),
      edate: moment(new Date()).format("YYYY-MM-DD"),
    };
    dispatch(getDashboardDetail({ data }));
  }, [dateDashboard]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const dateGetter = (day) => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - day);
  };

  const dateOptions = [
    { value: "", label: t("dashboard.all") },
    {
      value: dateGetter(30),
      label: t("dashboard.last_month"),
    },
    {
      value: dateGetter(7),
      label: t("dashboard.last_week"),
    },
    { value: new Date(), label: t("dashboard.today") },
  ];

  const handleInputChange = (e) => {
    setDateDashboard(e);
  };

  useEffect(() => {
    if (dateDashboard) {
      setDateDashboard({
        ...dateDashboard,
        label:
          dateDashboard.label === "All"
            ? "ทั้งหมด"
            : dateDashboard.label === "This Month"
            ? "เดือนนี้"
            : dateDashboard.label === "This Week"
            ? "ในสัปดาห์นี้"
            : dateDashboard.label === "Today"
            ? "วันนี้"
            : dateDashboard.label === "ทั้งหมด"
            ? "All"
            : dateDashboard.label === "เดือนนี้"
            ? "This Month"
            : dateDashboard.label === "ในสัปดาห์นี้"
            ? "This Week"
            : dateDashboard.label === "วันนี้"
            ? "Today"
            : "",
      });
    }
  }, [i18n.language]);

  // const userDownloadHandler = (type) => {
  //   console.log("type", type);
  //   let data;
  //   if (startDate && endDate) {
  //     data = {
  //       sdate: startDate,
  //       edate: endDate,
  //     };
  //   } else {
  //     data = {
  //       sdate: "",
  //       edate: new Date(),
  //     };
  //   }
  //   console.log("data", data);
  //   type === "user"
  //     ? dispatch(userReportDetail({ data }))
  //     : type === "feedback"
  //     ? dispatch(feedbackReportDetail({ data }))
  //     : type === "service"
  //     ? dispatch(serviceReportDetail({ data }))
  //     : type === "event" && dispatch(eventReportDetail({ data }));
  // };

  function yyyymmdd(x) {
    // var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    d.length == 1 && (d = "0" + d);
    m.length == 1 && (m = "0" + m);
    var yyyymmdd = y.slice(2, 4) + m + d;
    return yyyymmdd;
  }

  const toDate = new Date();

  // Handle specific download buttons loader
  const handleBtnLoaderCallback = useCallback(
    (type, value) => {
      if (type === "user") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          user: { type: type, isLoading: value },
        });
      } else if (type === "feedback") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          feedback: { type: type, isLoading: value },
        });
      } else if (type === "service") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          service: { type: type, isLoading: value },
        });
      } else if (type === "event") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          event: { type: type, isLoading: value },
        });
      } else if (type === "contact") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          contact: { type: type, isLoading: value },
        });
      } else if (type === "announcement") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          announcement: { type: type, isLoading: value },
        });
      } else if (type === "visitor_invite") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          visitor_invite: { type: type, isLoading: value },
        });
      } else if (type === "promotion") {
        setBtnLoaders({
          ...btnLoaders,
          pageLoading: value,
          promotion: { type: type, isLoading: value },
        });
      }
    },
    [btnLoaders]
  );

  return (
    <div className="project_edit_main_content">
      <>
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {/* <h2 className="project_subheading">{sideHeading}</h2> */}
        </div>
        {dashBoardLoader && btnLoaders?.pageLoading === false ? (
          <Loader />
        ) : (
          <>
            <div className="user_data_search_select_box_row">
              <div className="user_data_search dashboard_data_filter">
                <Select
                  placeholder={t("dashboard.time_period")}
                  value={dateDashboard}
                  onChange={(e) => handleInputChange(e)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="role"
                  options={dateOptions}
                />
              </div>
            </div>
            <div className="dashboard_top_column_row">
              <div className="dashboard_top_column pd_10">
                <div className="dashboard_top_column_main_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.012"
                    height="18.235"
                    viewBox="0 0 35.012 18.235"
                  >
                    <g id="user" transform="translate(-8 -12.583)">
                      <g
                        id="Group_1111"
                        dataname="Group 1111"
                        transform="translate(8 14.773)"
                      >
                        <circle
                          id="Ellipse_46"
                          dataname="Ellipse 46"
                          cx="2.917"
                          cy="2.917"
                          r="2.917"
                          transform="translate(2.919)"
                          fill="#1"
                          opacity="0.5"
                        ></circle>
                        <path
                          id="Path_1020"
                          dataname="Path 1020"
                          d="M17.731,17.5A6.115,6.115,0,0,0,15.3,22.386v.365h-6.2A1.1,1.1,0,0,1,8,21.657v-.729a4.009,4.009,0,0,1,4.011-4.011h3.648A4,4,0,0,1,17.731,17.5Z"
                          transform="translate(-8 -9.623)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></path>
                        <circle
                          id="Ellipse_47"
                          dataname="Ellipse 47"
                          cx="2.917"
                          cy="2.917"
                          r="2.917"
                          transform="translate(26.259)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></circle>
                        <path
                          id="Path_1021"
                          dataname="Path 1021"
                          d="M29.283,20.929v.729a1.1,1.1,0,0,1-1.094,1.094h-6.2v-.365A6.112,6.112,0,0,0,19.554,17.5a3.978,3.978,0,0,1,2.072-.584h3.648A4.012,4.012,0,0,1,29.283,20.929Z"
                          transform="translate(5.729 -9.625)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></path>
                      </g>
                      <circle
                        id="Ellipse_48"
                        dataname="Ellipse 48"
                        cx="4"
                        cy="4"
                        r="4"
                        transform="translate(21 12.583)"
                        fill="#1e1e1e"
                      ></circle>
                      <path
                        id="Path_1022"
                        dataname="Path 1022"
                        d="M24.369,17.583H16.344a4.015,4.015,0,0,0-4.011,4.011v2.188a1.1,1.1,0,0,0,1.094,1.094H27.286a1.1,1.1,0,0,0,1.094-1.094V21.594A4.015,4.015,0,0,0,24.369,17.583Z"
                        transform="translate(5.149 5.941)"
                        fill="#1e1e1e"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {reports?.tenant_signup}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    {t("dashboard.tenant_signup")}
                  </p>
                </div>
              </div>
              <div className="dashboard_top_column pd_10">
                <div className="dashboard_top_column_main_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.012"
                    height="18.235"
                    viewBox="0 0 35.012 18.235"
                  >
                    <g id="user" transform="translate(-8 -12.583)">
                      <g
                        id="Group_1111"
                        dataname="Group 1111"
                        transform="translate(8 14.773)"
                      >
                        <circle
                          id="Ellipse_46"
                          dataname="Ellipse 46"
                          cx="2.917"
                          cy="2.917"
                          r="2.917"
                          transform="translate(2.919)"
                          fill="#1"
                          opacity="0.5"
                        ></circle>
                        <path
                          id="Path_1020"
                          dataname="Path 1020"
                          d="M17.731,17.5A6.115,6.115,0,0,0,15.3,22.386v.365h-6.2A1.1,1.1,0,0,1,8,21.657v-.729a4.009,4.009,0,0,1,4.011-4.011h3.648A4,4,0,0,1,17.731,17.5Z"
                          transform="translate(-8 -9.623)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></path>
                        <circle
                          id="Ellipse_47"
                          dataname="Ellipse 47"
                          cx="2.917"
                          cy="2.917"
                          r="2.917"
                          transform="translate(26.259)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></circle>
                        <path
                          id="Path_1021"
                          dataname="Path 1021"
                          d="M29.283,20.929v.729a1.1,1.1,0,0,1-1.094,1.094h-6.2v-.365A6.112,6.112,0,0,0,19.554,17.5a3.978,3.978,0,0,1,2.072-.584h3.648A4.012,4.012,0,0,1,29.283,20.929Z"
                          transform="translate(5.729 -9.625)"
                          fill="#1e1e1e"
                          opacity="0.5"
                        ></path>
                      </g>
                      <circle
                        id="Ellipse_48"
                        dataname="Ellipse 48"
                        cx="4"
                        cy="4"
                        r="4"
                        transform="translate(21 12.583)"
                        fill="#1e1e1e"
                      ></circle>
                      <path
                        id="Path_1022"
                        dataname="Path 1022"
                        d="M24.369,17.583H16.344a4.015,4.015,0,0,0-4.011,4.011v2.188a1.1,1.1,0,0,0,1.094,1.094H27.286a1.1,1.1,0,0,0,1.094-1.094V21.594A4.015,4.015,0,0,0,24.369,17.583Z"
                        transform="translate(5.149 5.941)"
                        fill="#1e1e1e"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {reports?.visitor_signup}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    {t("dashboard.visitor_signup")}
                  </p>
                </div>
              </div>

              {/* <div className="dashboard_top_column pd_10">
            <div className="dashboard_top_column_main_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35.012"
                height="18.235"
                viewBox="0 0 35.012 18.235"
              >
                <g id="user" transform="translate(-8 -12.583)">
                  <g
                    id="Group_1111"
                    dataname="Group 1111"
                    transform="translate(8 14.773)"
                  >
                    <circle
                      id="Ellipse_46"
                      dataname="Ellipse 46"
                      cx="2.917"
                      cy="2.917"
                      r="2.917"
                      transform="translate(2.919)"
                      fill="#1e1e1e"
                      opacity="0.5"
                    ></circle>
                    <path
                      id="Path_1020"
                      dataname="Path 1020"
                      d="M17.731,17.5A6.115,6.115,0,0,0,15.3,22.386v.365h-6.2A1.1,1.1,0,0,1,8,21.657v-.729a4.009,4.009,0,0,1,4.011-4.011h3.648A4,4,0,0,1,17.731,17.5Z"
                      transform="translate(-8 -9.623)"
                      fill="#1e1e1e"
                      opacity="0.5"
                    ></path>
                    <circle
                      id="Ellipse_47"
                      dataname="Ellipse 47"
                      cx="2.917"
                      cy="2.917"
                      r="2.917"
                      transform="translate(26.259)"
                      fill="#1e1e1e"
                      opacity="0.5"
                    ></circle>
                    <path
                      id="Path_1021"
                      dataname="Path 1021"
                      d="M29.283,20.929v.729a1.1,1.1,0,0,1-1.094,1.094h-6.2v-.365A6.112,6.112,0,0,0,19.554,17.5a3.978,3.978,0,0,1,2.072-.584h3.648A4.012,4.012,0,0,1,29.283,20.929Z"
                      transform="translate(5.729 -9.625)"
                      fill="#1e1e1e"
                      opacity="0.5"
                    ></path>
                  </g>
                  <circle
                    id="Ellipse_48"
                    dataname="Ellipse 48"
                    cx="4"
                    cy="4"
                    r="4"
                    transform="translate(21 12.583)"
                    fill="#1e1e1e"
                  ></circle>
                  <path
                    id="Path_1022"
                    dataname="Path 1022"
                    d="M24.369,17.583H16.344a4.015,4.015,0,0,0-4.011,4.011v2.188a1.1,1.1,0,0,0,1.094,1.094H27.286a1.1,1.1,0,0,0,1.094-1.094V21.594A4.015,4.015,0,0,0,24.369,17.583Z"
                    transform="translate(5.149 5.941)"
                    fill="#1e1e1e"
                  ></path>
                </g>
              </svg>
            </div>
            <div className="dashboard_top_column_left_content">
              <h2 className="dashboard_top_column_left_count_text">786</h2>
              <p className="dashboard_top_column_left_peragraph_text">
                {t("dashboard.logins")}
              </p>
            </div>
          </div> */}
              <div className="dashboard_top_column pd_10">
                <div className="dashboard_top_column_main_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1e1e1e"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                    <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                    <line x1="12" y1="22.76" x2="12" y2="11"></line>
                  </svg>
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {reports?.feedback_signup}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    {t("dashboard.feedbacks")}
                  </p>
                </div>
              </div>
              <div className="dashboard_top_column pd_10">
                <div className="dashboard_top_column_main_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1e1e1e"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                    <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                    <line x1="12" y1="22.76" x2="12" y2="11"></line>
                  </svg>
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {reports?.service_request}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    {t("dashboard.service_requests")}
                  </p>
                </div>
              </div>
              <div className="dashboard_top_column pd_10">
                <div className="dashboard_top_column_main_icon">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.1963 13.1953C19.1963 15.8456 17.0478 17.994 14.3976 17.994C11.7474 17.994 9.59898 15.8456 9.59898 13.1953C9.59898 10.5451 11.7474 8.3967 14.3976 8.3967C17.0478 8.3967 19.1963 10.5451 19.1963 13.1953Z"
                      fill="black"
                    />
                    <path
                      d="M6 25.192C6 21.593 8.39932 20.3933 14.3976 20.3933C20.3959 20.3933 22.7953 21.5695 22.7953 25.192V30H6V25.192Z"
                      fill="black"
                    />
                    <g opacity="0.5">
                      <path
                        d="M21.1502 15.5551C21.4079 14.8147 21.5532 14.0227 21.5532 13.198C21.5532 10.7143 20.2926 8.52585 18.376 7.22778C19.2242 6.4733 20.3301 6 21.5532 6C24.2056 6 26.3519 8.14627 26.3519 10.7986C26.3519 13.451 24.2056 15.5973 21.5532 15.5973C21.4742 15.5973 21.3968 15.5862 21.3191 15.5751C21.2632 15.5671 21.2071 15.559 21.1502 15.5551Z"
                        fill="black"
                      />
                      <path
                        d="M19.6553 18.0482C20.2458 18.0154 20.8737 17.9966 21.5532 17.9966C27.5515 17.9966 29.9508 19.1728 29.9508 22.7953V27.6033H25.1475V25.1946C25.1475 20.7052 22.3124 19.0182 19.3273 18.3856C19.4397 18.2778 19.5475 18.1653 19.6553 18.0482Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
                <div className="dashboard_top_column_left_content">
                  <h2 className="dashboard_top_column_left_count_text">
                    {reports?.justCo_registered}
                  </h2>
                  <p className="dashboard_top_column_left_peragraph_text">
                    {t("dashboard.just-co-signup")}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </>
      {isDashboard && (
        <>
          <div className="user_data_search_select_box_row">
            <div className="user_data_search h-35">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                dateFormat="dd/MM/yyyy"
                placeholderText={t("dashboard.date_place")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          </div>
          <div className="dashboard_top_column_row">
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.user_report")}
                </h2>
                {/* <p className="dashboard_top_column_left_peragraph_text"> */}
                <CsvDownload
                  type={"user"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_mobile-user-report_${yyyymmdd(
                          startDate
                          //   )}-${yyyymmdd(endDate)}.csv`
                          // : `${yyyymmdd(toDate)}_mobile-user-report.csv`
                        )}-${yyyymmdd(endDate)}.xlsx`
                      : `${yyyymmdd(toDate)}_mobile-user-report.xlsx`
                  }
                  isBtnLoading={btnLoaders.user}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
                {/* </p> */}
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.feedback_report")}
                </h2>
                {/* <p className="dashboard_top_column_left_peragraph_text"> */}
                <CsvDownload
                  type={"feedback"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_feedback-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_feedback-report.csv`
                  }
                  isBtnLoading={btnLoaders.feedback}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
                {/* </p> */}
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.service_report")}
                </h2>
                {/* <p className="dashboard_top_column_left_peragraph_text">
                <button
                  className="download_btn comman_btn"
                  onClick={() => userDownloadHandler("service")}
                >
                  Download
                </button>
              </p> */}
                <CsvDownload
                  type={"service"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_service-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_service-report.csv`
                  }
                  isBtnLoading={btnLoaders.service}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.event_report")}
                </h2>
                <CsvDownload
                  type={"event"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_event-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_event-report.csv`
                  }
                  isBtnLoading={btnLoaders.event}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.contact_report")}
                </h2>
                <CsvDownload
                  type={"contact"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_contact-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_contact-report.csv`
                  }
                  isBtnLoading={btnLoaders.contact}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.announcement_report")}
                </h2>
                <CsvDownload
                  type={"announcement"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_announcement-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_announcement-report.csv`
                  }
                  isBtnLoading={btnLoaders.announcement}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.visitor_invite_report")}
                </h2>
                <CsvDownload
                  type={"visitor_invite"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_visitor_invite-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_visitor_invite-report.csv`
                  }
                  isBtnLoading={btnLoaders.visitor_invite}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
            <div className="dashboard_top_column flex_center">
              <div className="dashboard_top_column_left_content flex_column">
                <h2 className="dashboard_top_column_left_count_text fs-19 ta_c">
                  {t("dashboard.promotion_report")}
                </h2>
                <CsvDownload
                  type={"promotion"}
                  startDate={startDate}
                  endDate={endDate}
                  fileName={
                    startDate && endDate
                      ? `${yyyymmdd(toDate)}_promotion-report_${yyyymmdd(
                          startDate
                        )}-${yyyymmdd(endDate)}.csv`
                      : `${yyyymmdd(toDate)}_promotion-report.csv`
                  }
                  isBtnLoading={btnLoaders.promotion}
                  handleBtnLoaderCallback={handleBtnLoaderCallback}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
