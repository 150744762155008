export const routes = {
  homepage: "/",
  all: "*",
  login: "/login",
  general: "/onecity/visitor-info/:id",
  completionForm: "/onecity/thank-you",

  privacyPolicyPage: "/onecity/privacy-policy",
  termsConditionsPage: "/onecity/terms-conditions",

  passwordPage: "/password_page",
  dashboard: "/dashboard",

  adminUsers: "/admin-user",
  createAdminUsers: "/admin-user/create",
  editAdminUsers: "/admin-user/edit/:id",

  users: "/users",
  usersByPage: "/users/page/:page_number",
  editUser: "/users/edit/:id",
  viewUser: "/users/view/:id",

  rolemanagement: "/rolemanagement",
  createRole: "/rolemanagement/create",
  editRole: "/rolemanagement/edit/:id",

  features: "/features",
  viewFeaturemanagement: "/features/view/:id",

  amenities: "/amenities",
  createAmenities: "/amenities/create",
  editAmenities: "/amenities/edit/:id",

  sidemenu: "/sidemenu",
  createSidemenu: "/sidemenu/create",
  editSidemenu: "/sidemenu/edit/:id",

  announcement: "/announcements",
  announcementByPage: "/announcements/page/:page_number",
  createAnnouncement: "/announcements/create",
  // createAnnouncement: "/create-announcements",
  editAnnouncement: "/announcements/edit/:id",

  contact: "/contacts",
  contactByPage: "/contacts/page/:page_number",
  createContact: "/contacts/create",
  // createContact: "/contacts/create-contacts",
  editContact: "/contacts/edit/:id",

  floorLevel: "/floorlevel",
  floorLevelByPage: "/floorlevel/page/:page_number",
  createFloorLevel: "/floorlevel/create",
  editFloorLevel: "/floorlevel/edit/:id",

  directory: "/directory",
  directoryByPage: "/directory/page/:page_number",
  createDirectory: "/directory/create",
  editDirectory: "/directory/edit/:id",

  services: "/services",
  servicesByPage: "/services/page/:page_number",

  notifications: "/notifications",
  notificationsByPage: "/notifications/page/:page_number",
  createNotification: "/notifications/create",
  editNotification: "/notifications/edit/:id",

  events: "/events",
  eventsByPage: "/events/page/:page_number",
  createEvent: "/events/create",
  editEvent: "/events/edit/:id",

  promotions: "/promotions",
  promotionsByPage: "/promotions/page/:page_number",
  createPromotion: "/promotions/create",
  // createPromotion: "/create-promotions",
  editPromotion: "/promotions/edit/:id",

  visitorInvite: "/visitor-invite",
  visitorInviteByPage: "/visitor-invite/page/:page_number",
  createVisitorInvite: "/visitor-invite/create",
  editVisitorInvite: "/visitor-invite/edit/:id",

  feedback: "/feedback",
  feedbackByPage: "/feedback/page/:page_number",

  settings: "/settings",
  privacyPolicy: "/settings/privacy-policy",
  termsConditions: "/settings/terms-conditions",
  visitorPolicy: "/settings/visitor-policy",
  tokenComponent: "/settings/token-component",
  mobileConComponent: "/settings/mobile-configuration",

  countryCodes: "/settings/country-codes",
  countryCodesByPage: "/settings/country-codes/page/:page_number",
  createCountryCodes: "/settings/country-codes/create",
  editCountryCodes: "/settings/country-codes/edit/:id",

  justCo: "/just-co",
  editJustCo: "/just-co/edit",
  editSecretApplicationKey: "/just-co/secret/application/key/edit",
  editTermsAndConditions: "/just-co/terms/and/conditions",
  editLogo: "/just-co/logo",
  editTitleDescription: "/just-co/title/and/description",

  foodBeverage: "/food_beverage",
  foodBeverageByPage: "/food_beverage/page/:page_number",
  createRestaurant: "/food_beverage/create",
  editRestaurant: "/food_beverage/edit/:id",

  purpose: "/purpose",

  docType: "/doc-type",
};
