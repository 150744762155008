import { isValidPhoneNumber } from "react-phone-number-input";

export const visitorUserValidation = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  t
) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);
  // if ("name" in fieldValues) {
  //   temp.name = fieldValues.name ? "" : "Name is required !";
  // }

  if ("document_type" in fieldValues) {
    temp.document_type = fieldValues.document_type
      ? ""
      : "Document type is required!";
  }

  if ("doc_number" in fieldValues) {
    temp.doc_number = fieldValues.doc_number
      ? ""
      : "Document number is required!";
  }

  // if ("email" in fieldValues) {
  //   if (!fieldValues.email) {
  //     temp.email = "Email is required!";
  //   } else {
  //     temp.email = fieldValues.email.match(mailReg)
  //       ? ""
  //       : "Enter a valid email !";
  //   }
  // }

  // if ("subvisitor_1" in fieldValues) {
  //   if (!fieldValues.subvisitor_1) {
  //     temp.subvisitor_1 = t("errors.email");
  //   } else {
  //     temp.subvisitor_1 = fieldValues.subvisitor_1.match(mailReg)
  //       ? ""
  //       : t("errors.email_val");
  //   }
  // }
  // if ("subvisitor_2" in fieldValues) {
  //   if (!fieldValues.subvisitor_2) {
  //     temp.subvisitor_2 = t("errors.email");
  //   } else {
  //     temp.subvisitor_2 = fieldValues.subvisitor_2.match(mailReg)
  //       ? ""
  //       : t("errors.email_val");
  //   }
  // }
  // if ("subvisitor_3" in fieldValues) {
  //   if (!fieldValues.subvisitor_3) {
  //     temp.subvisitor_3 = t("errors.email");
  //   } else {
  //     temp.subvisitor_3 = fieldValues.subvisitor_3.match(mailReg)
  //       ? ""
  //       : t("errors.email_val");
  //   }
  // }
  // if ("subvisitor_4" in fieldValues) {
  //   if (!fieldValues.subvisitor_4) {
  //     temp.subvisitor_4 = t("errors.email");
  //   } else {
  //     temp.subvisitor_4 = fieldValues.subvisitor_4.match(mailReg)
  //       ? ""
  //       : t("errors.email_val");
  //   }
  // }
  if ("issue_date" in fieldValues) {
    temp.issue_date = fieldValues.issue_date ? "" : "Issue date is required!";
  }

  if ("expiry_date" in fieldValues) {
    temp.expiry_date = fieldValues.expiry_date
      ? ""
      : "Expiry date is required!";
  }

  if ("nationality" in fieldValues) {
    temp.nationality = fieldValues.nationality
      ? ""
      : "Nationality is required!";
  }

  if ("phone_number" in fieldValues) {
    temp.phone_number = fieldValues.phone_number
      ? isValidPhoneNumber(fieldValues.phone_number)
        ? ""
        : "Phone number is not valid !"
      : "Phone number is required !";
  }

  // if ("vehicle_type" in fieldValues) {
  //   temp.vehicle_type = fieldValues.vehicle_type
  //     ? ""
  //     : "Vehicle type is required!";
  // }

  // if ("license_plate_number" in fieldValues) {
  //   temp.license_plate_number = fieldValues.license_plate_number
  //     ? ""
  //     : "License plate number is required!";
  // }

  // if ("make" in fieldValues) {
  //   temp.make = fieldValues.make ? "" : "Make is required!";
  // }

  if ("doc_image" in fieldValues) {
    temp.doc_image = "";
    if (!fieldValues.doc_image) {
      temp.doc_image = "Image required !";
    }
    if (fieldValues.doc_image.size > 1000000) {
      temp.doc_image = "Image size should not be greater than 5MB";
      setValues({
        ...values,
        doc_image_preview: "",
        doc_image: "",
      });
    }
    if (
      fieldValues.doc_image?.name &&
      !fieldValues.doc_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.doc_image = "Invalid image";
      setValues({
        ...values,
        doc_image_preview: "",
        doc_image: "",
      });
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
