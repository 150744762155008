import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../component/Common/Loader";
import { checkIcon, crossIcon } from "../../icons";
import {
  getAppType,
  getFeatures,
  updateFeaturesRole,
} from "../../store/slice/rolemanagementSlice";
import Select from "react-select";
import { useForm } from "../../hooks/useForm";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const Features = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const initialFValues = {
    role: "",
    appStatus: "",
  };

  const sideHeading = localStorage.getItem("sidebar_component");

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const featureList = useSelector(
    (state) => state.rolemanagement.featuresList?.data
  );

  const appTypeList = useSelector((state) => state.rolemanagement.appTypeList);

  const featureLoader = useSelector(
    (state) => state.rolemanagement.featureLoader
  );

  useEffect(() => {
    // dispatch(getFeatures({}));
    dispatch(getAppType());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getFeatures({
        app_type_id: values.role.value,
        app_status: values.appStatus.value,
      })
    );
  }, [values.role, values.appStatus]);

  const appTypeOptions = appTypeList?.map((item) => {
    return {
      value: item.id,
      label: item.app_type_display_name,
    };
  });

  const userAppTypeOptions = appTypeOptions && [
    { value: "", label: "All" },
    ...appTypeOptions,
  ];

  const appStatusOptions = [
    { value: "", label: "All" },
    { value: "ACTIVE", label: "Active" },
    { value: "NON_ACTIVE", label: "Non Active" },
  ];

  const activateFeatureHandler = (item) => {
    const data = {
      is_active: item?.is_active === 1 ? 0 : 1,
    };

    dispatch(
      updateFeaturesRole({
        data,
        id: item?.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              `${t("feature.head")} ${
                item.is_active === 1
                  ? t("feature.deactivated")
                  : t("feature.activated")
              } ${t("feature.successfully")}`,
              {
                autoClose: 3000,
              }
            );
            dispatch(getFeatures({}));
          }
        },
      })
    );
  };

  if (featureLoader) {
    return <Loader />;
  }

  return (
    <div className="project_edit_main_content">
      <div className="rolemanagement_project_btn_row_flex mb-10">
        <h2 className="project_subheading">
          {/* {sideHeading} */}
          {SidebarComponent()}
        </h2>
        <div className="user_data_search_select_box_row mb-0 w-460">
          <div className="user_data_search">
            <Select
              placeholder={t("filter.search_app_status")}
              value={values.appStatus}
              onChange={(e) => handleInputChange(e, "appStatus")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="appStatus"
              options={appStatusOptions}
            />
          </div>
          <div className="user_data_search">
            <Select
              placeholder={t("filter.search_app_type")}
              value={values.role}
              onChange={(e) => handleInputChange(e, "role")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="role"
              options={userAppTypeOptions}
            />
          </div>
        </div>
      </div>
      <div className="custom_data_table_content">
        {featureList?.length === 0 ? (
          <h2 className="ta_c">No results found</h2>
        ) : (
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">
                  {t("table.title")}
                </th>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.app_type")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.active")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.createdAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.updatedAt")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {featureList?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.feature_title}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.feature_display_name}
                    </td>

                    <td className="custom_data_table_item table_item">
                      {menu.app_type_display_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.is_active === 1 ? checkIcon : crossIcon}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.created_at &&
                        moment
                          .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {menu.updated_at
                        ? moment
                            .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")
                        : "-"}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <button
                          className={`custom_data_table_view_edit_item_btn active_feature_btn ${
                            menu.is_active === 1 ? "red" : "green"
                          }`}
                          onClick={() => activateFeatureHandler(menu)}
                        >
                          {menu.is_active === 1
                            ? t("feature.deactivate")
                            : t("table.activate")}
                        </button>
                        {/* <Link
                        to={`${routes.features}/view/${menu.id}`}
                        state={{ role_name: menu.feature_display_name }}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {viewIcon}
                      </Link> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Features;
