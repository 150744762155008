import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import { createToken, getToken } from "../../store/slice/settingSlice";

const Token = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");

  const tokenContent = useSelector((state) => state.setting.token);
  const { t } = useTranslation("common");

  const generateToken = () => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let characterLength = characters.length;
    for (let i = 0; i <= 26; i++) {
      result += characters.charAt(Math.floor(Math.random() * characterLength));
    }
    setToken(result);
    return result;
  };

  useEffect(() => {
    if (!tokenContent.token) {
      generateToken();
    } else {
      setToken(tokenContent.token);
    }
  }, [tokenContent]);

  useEffect(() => {
    dispatch(getToken());
  }, []);

  const createTokenHandler = () => {
    const data = {
      token: generateToken(),
    };
    dispatch(createToken({ data, toast: t("toast.create_token"), navigate }));
  };

  return (
    <div className="project_edit_main_content">
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">{"Token"}</h2>
        <Link to={routes.settings} className="comman_btn ml-auto secondary_btn">
          {t("common.back")}
        </Link>
      </div>
      <div className="create_from_row  settings_container">
        <div className="col_span_2 token_regenerate_input_box language_container">
          <Input
            className="create_from_input_content setting_token_input"
            inputClassName="create_from_input disabled_input token_container"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Token"
            id="token"
            name="token"
            value={token}
            disabled={true}
          />
          <Button
            className="create_from_bottom_btn col_span_2 token_btn"
            buttonClassName="comman_btn"
            onClick={createTokenHandler}
            text={t("setting.policy.regenerate")}
          />
        </div>
      </div>
    </div>
  );
};

export default Token;
