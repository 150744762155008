import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

const ViewModalVisitorInvite = ({ handleClose, show, visitorDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  // console.log("visitorDetail", visitorDetail);

  // const dispatch = useDispatch();

  // const { page_number } = params;

  // const buttonList = [
  //   { display_name: "Requested", value: "requested" },
  //   {
  //     display_name: "Pending Visitor Information",
  //     value: "pending_visitor_information",
  //   },
  //   { display_name: "Pending Approval", value: "pending_approval" },
  //   { display_name: "Approved", value: "approved" },
  //   { display_name: "Rejected", value: "rejected" },
  //   { display_name: "Cancelled", value: "cancelled" },
  // ];

  // const [status, setStatus] = useState("");
  // const [showModal, setShowModal] = useState(false);
  // const [statusForChange, setStatusForChange] = useState("");

  // console.log("visitorDetail", visitorDetail);

  // const updateStatusModal = (e) => {
  //   setStatusForChange(e.target.value);
  //   setShowModal(true);
  // };

  // useEffect(() => {
  //   setStatus(visitorDetail?.invite_status);
  // }, [visitorDetail]);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  //   setStatusForChange("");
  // };

  // const ChangeStatusHandler = () => {
  //   const data = {
  //     invite_status: statusForChange,
  //   };
  //   dispatch(
  //     updateVisitorInvite({
  //       data,
  //       id: visitorDetail?.id,
  //       cb: (err, res) => {
  //         if (err) {
  //           console.log("err", err);
  //         } else {
  //           dispatch(
  //             getVisitorInvite({
  //               start: (page_number - 1) * 10,
  //             })
  //           );
  //           setStatus(data.invite_status);
  //           setShowModal(false);
  //           setStatusForChange("");
  //         }
  //       },
  //       toast: t("toast.update_status"),
  //     })
  //   );
  //   console.log("data", data);
  // };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("visitorInvite.visitor_invite_detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="visitor_infom_form_col">
              <h2 className="visitor_infom_form_heading">
                {t("dashboard.tenant_details")}
              </h2>
            </div>
            <div className="inquiry_complaint_detail_body view-modal_container oc_directory_detail_modal">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.company")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_company
                    ? visitorDetail?.tenant_company
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.department")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_department
                    ? visitorDetail?.tenant_department
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.firstname")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_first_name
                    ? visitorDetail?.tenant_first_name
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.lastname")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_last_name
                    ? visitorDetail?.tenant_last_name
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.position")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_position
                    ? visitorDetail?.tenant_position
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.email")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_email
                    ? visitorDetail?.tenant_email
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("contact.head")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {/* {visitorDetail?.contact
                    ? "+" +
                      visitorDetail?.tenant_country_code +
                      " " +
                      visitorDetail?.tenant_phone_number
                    : "-"} */}
                  {visitorDetail?.tenant_phone_number
                    ? "+" +
                      visitorDetail?.tenant_country_code +
                      " " +
                      visitorDetail?.tenant_phone_number
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("floor.floor")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.tenant_floor
                    ? visitorDetail?.tenant_floor
                    : "-"}
                </p>
              </div>
            </div>
            <div className="visitor_infom_form_col">
              <h2 className="visitor_infom_form_heading">
                {t("dashboard.visitor_details")}
              </h2>
            </div>
            <div className="inquiry_complaint_detail_body view-modal_container oc_directory_detail_modal">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.visit_date
                    ? moment(visitorDetail?.visit_date).format(
                        "DD/MM/YYYY, hh:mm A"
                      )
                    : "-"}
                </p>
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.purpose")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.purpose_title
                    ? visitorDetail?.purpose_title
                    : "-"}
                </p>
              </div> */}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.email")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  {visitorDetail?.email}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.firstname")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.first_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.lastname")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.last_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("contact.head")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.visitor_phone_number
                    ? "+" +
                      visitorDetail?.visitor_country_code +
                      " " +
                      visitorDetail?.visitor_phone_number
                    : "-"}
                </p>
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.phoneNumber")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {"+" +
                    visitorDetail?.country_code +
                    " " +
                    visitorDetail?.mobile_number}
                </p>
              </div> */}
              {/* </div>
            <div className="inquiry_complaint_detail_body view-modal_container oc_directory_detail_modal"> */}
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.invite_status")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  {visitorDetail.invite_status === "pending_visitor_information"
                    ? "pending information"
                    : visitorDetail.invite_status === "pending_approval"
                    ? "pending approval"
                    : visitorDetail.invite_status}
                </p>
              </div> */}

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.company")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.visitor_company
                    ? visitorDetail?.visitor_company
                    : visitorDetail?.tenant_company
                    ? visitorDetail?.tenant_company
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.document_type")}
                </h2>
                <p className="inquiry_complaint_detail_name directory_link_validation">
                  {visitorDetail?.document_type_title
                    ? visitorDetail?.document_type_title
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.document_number")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.document_number
                    ? visitorDetail?.document_number
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_nationality")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.nationality
                    ? visitorDetail?.nationality
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_issuedate")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.issue_date
                    ? moment(visitorDetail?.issue_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_expirydate")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.expiry_date
                    ? moment(visitorDetail?.expiry_date).format("DD/MM/YYYY")
                    : "-"}
                </p>
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_vehicletype")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.vehicle_type
                    ? visitorDetail?.vehicle_type
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.visitor_vehicle_number")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.licence_plate_number
                    ? visitorDetail?.licence_plate_number
                    : "-"}
                </p>
              </div> */}
              <div></div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("visitorInvite.document_image")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.document_image ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${visitorDetail?.document_image}`}
                      alt="visitor_invite_image"
                    />
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              {visitorDetail?.system_qr && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("visitorInvite.system_qr")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {visitorDetail?.system_qr ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={`${process.env.REACT_APP_FILE_BASE_URL}/${visitorDetail?.system_qr}`}
                        alt="visitor_invite_qr"
                      />
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              )}
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment(visitorDetail?.created_at).format(
                    "DD/MM/YYYY, kk:mm:ss"
                  )}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.update_at
                    ? moment(visitorDetail?.update_at).format(
                        "DD/MM/YYYY, kk:mm:ss"
                      )
                    : "-"}
                </p>
              </div> */}
              {/* <div className={`create_from_input_content span_2`}>
                <label className="create_from_label required">
                  {t("table.status")}{" "}
                </label>
                <div class="visitor-button-row visitor-button-row1">
                  {buttonList.map((btn) => {
                    return (
                      <button
                        class={`visitor-button ${
                          btn.value === status && "is-active"
                        }`}
                        value={btn.value}
                        onClick={(e) => updateStatusModal(e)}
                      >
                        {btn.display_name}
                      </button>
                    );
                  })}
                </div>
              </div> */}
            </div>
            <div className="visitor_infom_form_col">
              <h2 className="visitor_infom_form_heading">
                Additional Visitors
              </h2>
            </div>
            <div className="inquiry_complaint_detail_body view-modal_container oc_directory_detail_modal">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Subvisitor 1
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.subvisitor_1
                    ? visitorDetail?.subvisitor_1
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Subvisitor 2
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.subvisitor_2
                    ? visitorDetail?.subvisitor_2
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Subvisitor 3
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.subvisitor_3
                    ? visitorDetail?.subvisitor_3
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Subvisitor 4
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {visitorDetail?.subvisitor_4
                    ? visitorDetail?.subvisitor_4
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={handleClose}
          className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
        >
          {t("common.close")}
        </button>
      </section>
      {/* <ChangeStatusModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        ChangeStatusHandler={ChangeStatusHandler}
      /> */}
    </>
  );
};

export default ViewModalVisitorInvite;
