import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { useForm } from "../../hooks/useForm";
import {
  getService,
  getServiceTypes,
  updateService,
} from "../../store/slice/serviceSlice";
import EditServiceModal from "./EditServiceModal";
import ServiceTable from "./ServiceTable";
import ServiceTypeForm from "./ServiceTypeForm";
import settingImage from "../../images/setting.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteServiceModal from "./DeleteServiceModal";
import ViewServiceModal from "./ViewServiceModal";
import { useRef } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DeleteServicetypeModal from "./DeleteServicetypeModal";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";
import ChangeStatusModal from "./ChangeStatusModal";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { searchIcon } from "../../icons";
import { routes } from "../../constants";
import { SidebarComponent } from "../../helpers/commonFunction";

const statusOptions = [
  { value: "", label: "All" },
  { value: "PENDING", label: "Pending" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "COMPLETED", label: "Completed" },
];

const Services = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const params = useParams();
  const { page_number } = params;
  const ref = useRef(false);
  const [updateState, setUpdateState] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);
  const navigate = useNavigate();

  const location = useLocation();
  const value = location?.state?.value;

  const sideHeading = localStorage.getItem("sidebar_component");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const service = useSelector((state) => state.service.serviceList?.data);
  const isLoading = useSelector((state) => state.service.loader);

  const [statusChange, setStatusChange] = useState(false);

  const serviceListLoader = useSelector((state) => state.service.loader);
  const serviceCount = useSelector((state) => state.service.serviceList?.count);

  const [apiErr, setApiErr] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (+pageN === 0) {
        dispatch(
          getService({
            noAuthority: (error) => {
              if (error) {
                console.log("err", error);
                setApiErr(true);
              } else {
                setApiErr(false);
              }
            },
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        serviceType_name: updateState.service_type_name
          ? updateState.service_type_name
          : "",
        serviceType_icon: updateState.service_type_image
          ? updateState.service_type_image
          : "",
        serviceType_icon_preview: updateState.service_type_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.service_type_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const [status, setStatus] = useState("");
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const initialFValues = {
    serviceTypeForm: false,
    serviceType_name: "",
    serviceType_icon: "",
    serviceType_icon_preview: "",
    serviceEditModal: false,
    editModalData: "",
    // service_request_status: status ? status : "PENDING",
    deleteServiceModal: false,
    deleteServiceData: "",
    viewServiceModal: false,
    viewServiceData: "",
    deleteModal: false,
    deleteModalId: "",
    changeStatusModal: false,
    changeStatusModalId: "",
    changeStatusModalStatus: "",
    in_progress_remark: "",
    in_progress_image: "",
    respond_image: "",
    respond_image_preview: "",
    respond_image_err: "",
    service_remark: "",
    editPageViewServiceDetail: "",
    search_key: value?.search_key ? value?.search_key : "",
    phone_number: value?.phone_number ? value?.phone_number : "",
    service_type: value?.service_type ? value?.service_type : "",
    service_request_status: value?.service_request_status?.value
      ? value?.service_request_status?.value
      : "",
    status: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("serviceType_icon" in fieldValues) {
      temp.serviceType_icon = "";
      if (!fieldValues.serviceType_icon) {
        temp.serviceType_icon = t("errors.icon_require");
      }
      if (fieldValues.serviceType_icon.size > 1000000) {
        temp.serviceType_icon = t("errors.small_icon_size");
        setValues({
          ...values,
          serviceType_icon_preview: "",
          serviceType_icon: "",
        });
      }
      if (
        fieldValues.serviceType_icon?.name &&
        !fieldValues.serviceType_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.serviceType_icon = t("errors.icon_invalid");
        setValues({
          ...values,
          serviceType_icon_preview: "",
          serviceType_icon: "",
        });
      }
    }

    if ("serviceType_name" in fieldValues) {
      temp.serviceType_name = fieldValues.serviceType_name
        ? ""
        : t("errors.name");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const addServiceType = () => {
    dispatch(getServiceTypes());
    setValues({ ...values, serviceTypeForm: true });
  };

  const handleCloseHandler = () => {
    setValues({
      ...values,
      deleteModal: false,
      deleteModalId: "",
    });
  };

  const backServicesHandler = () => {
    setValues({
      ...values,
      serviceTypeForm: false,
      serviceType_name: "",
      serviceType_icon: "",
      serviceType_icon_preview: "",
    });
    setErrors({
      ...errors,
      serviceType_name: "",
      serviceType_icon: "",
    });
  };

  const handleClose = () => {
    setValues({
      ...values,
      serviceEditModal: false,
      editModalData: "",
    });
  };

  const handleServiceClose = () => {
    setValues({
      ...values,
      deleteServiceModal: false,
      deleteServiceData: "",
    });
  };
  const handleServiceViewClose = () => {
    setValues({
      ...values,
      viewServiceModal: false,
      viewServiceData: "",
    });
  };

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.services}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getService({
              start: pageN,
              search_key: values.search_key,
              phone_number: values.phone_number,
              service_type: values.service_type,
              service_request_status:
                values.service_request_status.value === ""
                  ? ""
                  : values.service_request_status.value,
              noAuthority: (error) => {
                if (error) {
                  console.log("err", error);
                  setApiErr(true);
                } else {
                  setApiErr(false);
                }
              },
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [
    dispatch,
    values.search_key,
    values.phone_number,
    values.service_type,
    values.service_request_status,
  ]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getService({
          start: pageN,
          search_key: values.search_key,
          phone_number: values.phone_number,
          service_type: values.service_type,
          service_request_status:
            values.service_request_status.value === ""
              ? ""
              : values.service_request_status.value,
          noAuthority: (error) => {
            if (error) {
              console.log("err", error);
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getService({
        start: pageN,
        search_key: values.search_key,
        phone_number: values.phone_number,
        service_type: values.service_type,
        service_request_status:
          values.service_request_status.value === ""
            ? ""
            : values.service_request_status.value,
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const handleStatusModalClose = () => {
    setValues({
      ...values,
      changeStatusModal: false,
      changeStatusModalStatus: "",
      changeStatusModalId: "",
    });
    setStatusChange(false);
  };

  const viewChangeServiceStatusModal = (service) => {
    setValues({
      ...values,
      changeStatusModal: true,
      changeStatusModalStatus: service?.service_request_status,
      changeStatusModalId: service?.id,
      editPageViewServiceDetail: service,
      in_progress_image: service?.in_progress_image,
      in_progress_image_preview: service?.in_progress_image,
      in_progress_remark: service?.in_progress_remark,
      respond_image: "",
      respond_image_preview: "",
      service_remark: "",
    });
    setStatusChange(true);
  };

  const onChangeServiceStatus = (e) => {
    setValues({
      ...values,
      changeStatusModalStatus: e.target.value,
      respond_image_err: "",
    });
  };

  const serviceImgRemoveHandler = () => {
    setValues({
      ...values,
      respond_image: "",
      respond_image_preview: "",
    });
  };

  const onChangeRespondImg = (e) => {
    const { name, files } = e.target;
    if (files[0]?.size > 1000000) {
      setValues({
        ...values,
        respond_image_err: t("errors.large_img_size"),
      });
    } else {
      setValues({
        ...values,
        [name]: files[0],
        [`${name}_preview`]: URL.createObjectURL(files[0]),
        respond_image_err: "",
      });
    }
  };

  const changeStatusHandler = async () => {
    const formData = new FormData();
    let attachmentUrl;
    if (
      values?.changeStatusModalStatus === "IN_PROGRESS" &&
      values.in_progress_image
    ) {
      formData.append("file", values.in_progress_image);
      attachmentUrl = await dispatch(fileUpload(formData));
      formData.delete("file");
    } else if (
      values.changeStatusModalStatus === "COMPLETED" &&
      values.respond_image
    ) {
      formData.append("file", values.respond_image);
      attachmentUrl = await dispatch(fileUpload(formData));
      formData.delete("file");
    } else {
      attachmentUrl = values.image;
    }

    const data = {
      service_request_status: values.changeStatusModalStatus,
    };

    if (values.changeStatusModalStatus === "IN_PROGRESS") {
      if (values.in_progress_image) {
        data.in_progress_image = attachmentUrl.payload;
      }
      if (values.in_progress_remark?.trim()) {
        data.in_progress_remark = values.in_progress_remark;
      }
    } else if (values.changeStatusModalStatus === "COMPLETED") {
      if (values.respond_image) {
        data.service_image = attachmentUrl.payload;
      }
      if (values.service_remark?.trim()) {
        data.service_remark = values.service_remark;
      }
    }

    dispatch(
      updateService({
        data,
        id: values.changeStatusModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getService({
                start: (page_number - 1) * 10,
                service_request_status:
                  values.service_request_status.value === ""
                    ? ""
                    : values.service_request_status.value,
              })
            );
            setValues({
              ...values,
              changeStatusModal: false,
              changeStatusModalStatus: "",
              changeStatusModalId: "",
              respond_image_err: "",
            });
            setStatusChange(false);
          }
        },
      })
    );
  };

  if (serviceListLoader) {
    return <Loader />;
  }

  const editServiceModalHandler = (service) => {
    setValues({
      ...values,
      serviceEditModal: true,
      editModalData: service,
    });
    setStatus(service.service_request_status);
  };

  if (fileUploadLoader || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex ">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {/* <h2 className="project_subheading">{sideHeading}</h2> */}
          {service && !apiErr && (
            <div className="creat_edit_project_btn_row">
              {!values.serviceTypeForm ? (
                <button
                  className="comman_btn setting_btn"
                  onClick={addServiceType}
                >
                  <img
                    src={settingImage}
                    alt="setting_img"
                    width={"100%"}
                    height={"100%"}
                  />
                </button>
              ) : (
                <button
                  className="comman_btn ml-auto secondary_btn"
                  onClick={() => backServicesHandler()}
                >
                  {t("common.back")}
                </button>
              )}
            </div>
          )}
        </div>
        {!values.serviceTypeForm && (
          <div className="user_data_search_select_box_row">
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_name")}
                id="search_key"
                name="search_key"
                value={values.search_key}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_phone")}
                id="phone_number"
                name="phone_number"
                value={values.phone_number}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_service_type")}
                id="service_type"
                name="service_type"
                value={values.service_type}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Select
                placeholder={t("table.status")}
                value={values.service_request_status}
                onChange={(e) => handleInputChange(e, "service_request_status")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="service_request_status"
                options={statusOptions}
              />
            </div>
          </div>
        )}

        {values.serviceTypeForm ? (
          <ServiceTypeForm
            updateState={updateState}
            values={values}
            setValues={setValues}
            setErrors={setErrors}
            handleInputChange={handleInputChange}
            errors={errors}
            setUpdateState={setUpdateState}
            validate={validate}
          />
        ) : service?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !service && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <ServiceTable
            values={values}
            setValues={setValues}
            setImgZoom={setImgZoom}
            setImgSrc={setImgSrc}
            viewChangeServiceStatusModal={viewChangeServiceStatusModal}
            page_number={page_number}
            editServiceModalHandler={editServiceModalHandler}
          />
        )}

        <EditServiceModal
          values={values}
          setValues={setValues}
          handleInputChange={handleInputChange}
          show={values.serviceEditModal}
          serviceDetail={values.editModalData}
          handleClose={handleClose}
          status={status}
          page_number={page_number}
        />

        <DeleteServiceModal
          handleClose={handleServiceClose}
          values={values}
          setValues={setValues}
          page_number={page_number}
        />
        <ViewServiceModal
          handleClose={handleServiceViewClose}
          show={values.viewServiceModal}
          serviceDetail={values.viewServiceData}
        />
        {+serviceCount > 0 && !values.serviceTypeForm && (
          <Pagination totalRecords={serviceCount} onPageChange={onPageChange} />
        )}
      </div>

      <DeleteServicetypeModal
        values={values}
        setValues={setValues}
        handleClose={handleCloseHandler}
      />
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
      <ChangeStatusModal
        show={values.changeStatusModal}
        handleStatusModalClose={handleStatusModalClose}
        changeStatusHandler={changeStatusHandler}
        values={values}
        handleInputChange={handleInputChange}
        imageRemoveHandler={serviceImgRemoveHandler}
        fileUploadLoader={fileUploadLoader}
        onChangeRespondImg={onChangeRespondImg}
        onChangeServiceStatus={onChangeServiceStatus}
        editPageViewServiceDetail={values.editPageViewServiceDetail}
        statusChange={statusChange}
      />
    </>
  );
};

export default Services;
