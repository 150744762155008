import React from "react";
import oneCityLogo from "../images/onecitylogo/Logo-Black.png";

const AlreadyFillForm = (props) => {
  // console.log("props", props);

  return (
    <div className="oc-thankyou-modal-page">
      <div className="oc-thankyou-modal" style={{ maxWidth: "660px" }}>
        <div className="oc-thankyou-modal-content">
          <div className="oc-thankyou-modal-banner-img">
            <img src={oneCityLogo} alt="thankyou img" />
          </div>
          {props?.alreadyFill?.status === "cancelled" ||
          props?.alreadyFill?.status === "rejected" ? (
            <div className="oc-thankyou-modal-text">
              <h2 style={{ fontWeight: "normal" }}>
                We regret to inform you that your visitor invitation has been{" "}
                <span style={{ fontWeight: "800" }}>
                  {props?.alreadyFill?.status}
                </span>
                . For further information or clarification, we kindly request
                you to contact the respective tenant or CBRE at <br />
                <a href="mailto:pmocc-thailand@cbre.com">
                  pmocc-thailand@cbre.com
                </a>
                <br />
                Thank you for your understanding.
              </h2>
              {/* <p>Admin will contact you through E-mail after review.</p> */}
            </div>
          ) : (
            <div className="oc-thankyou-modal-text">
              <h2 style={{ fontWeight: "normal" }}>
                Your invitation has been{" "}
                <span style={{ fontWeight: "800" }}>
                  {props?.alreadyFill?.status}.
                </span>
                Please refer to the latest email for invitation details and
                access instructions. If you did not receive an email for the
                approved invitation, please contact the tenant or CBRE at <br />
                <a href="mailto:pmocc-thailand@cbre.com">
                  pmocc-thailand@cbre.com
                </a>
              </h2>
              {/* <p>Admin will contact you through E-mail after review.</p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlreadyFillForm;
