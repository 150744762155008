import { isValidPhoneNumber } from "react-phone-number-input";

export const getInitialUserState = (userInfo) => {
  return {
    first_name: userInfo ? userInfo?.first_name : "",
    last_name: userInfo ? userInfo?.last_name : "",
    phone_number: userInfo?.phone_number
      ? "+" + userInfo.country_code + userInfo.phone_number
      : "",
    email: userInfo ? userInfo?.email : "",
    role: userInfo
      ? { value: userInfo?.user_role_id, label: userInfo?.role_display_name }
      : "",
    justco_access:
      userInfo && userInfo.justco_access ? userInfo.justco_access : "0",
  };
};

export const updateUserValidate = (temp, values, fieldValues, setErrors, t) => {
  // eslint-disable-next-line no-useless-escape
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("first_name" in fieldValues) {
    temp.first_name = fieldValues.first_name ? "" : t("errors.first_name");
  }

  if ("last_name" in fieldValues) {
    temp.last_name = fieldValues.last_name ? "" : t("errors.last_name");
  }

  if ("phone_number" in fieldValues) {
    temp.phone_number = fieldValues.phone_number
      ? isValidPhoneNumber(fieldValues.phone_number)
        ? ""
        : t("errors.phone_valid")
      : t("errors.phone_required");
  }

  if ("email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = t("errors.email");
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : t("errors.email_val");
    }
  }

  if ("role" in fieldValues) {
    temp.role = fieldValues.role ? "" : t("errors.user_role");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
