import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { deleteIcon, editIcon, searchIcon, viewIcon } from "../../icons";
import {
  getVisitorInvite,
  sendNotificationVisitorInvite,
} from "../../store/slice/visitorInviteSlice";
import DeleteVisitorInviteModal from "./DeleteVisitorInviteModal";
import Select from "react-select";
import ViewModalVisitorInvite from "./ViewModalVisitorInvite";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";

const VisitorInvite = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef(false);
  const { page_number } = params;
  const [pageN, setPageN] = useState(page_number - 1);

  const location = useLocation();
  const value = location?.state?.value;
  const page = location?.state?.page;

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState("");

  const statusOptions = [
    { value: "", label: "All" },
    // { value: "ARCHIVE", label: "Archive" },
    { value: "PENDING_INFORMATION", label: "Pending Information" },
    { value: "PENDING_APPROVAL", label: "Pending Approval" },
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
    { value: "CANCELLED", label: "Cancelled" },
  ];

  const deleteModalHandler = (menu) => {
    setDeleteModal(true);
    setDeleteModalId(menu.id);
  };

  const handleClose = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  const handleCloseViewModal = () => {
    setViewModal(false);
    setViewModalData("");
  };

  const viewAnnouncementHandler = (data) => {
    setViewModal(true);
    setViewModalData(data);
  };

  const visitorInvite = useSelector(
    (state) => state.visitorInvite.visitorInviteList
  )?.data;

  const visitorInviteCount = useSelector(
    (state) => state.visitorInvite.visitorInviteList
  )?.count;
  const visitorInviteLoader = useSelector(
    (state) => state.visitorInvite.loader
  );

  const initialFValues = {
    title: value?.title ? value?.title : "",
    status: value?.status ? value?.status : "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.visitorInvite}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getVisitorInvite({
              title: values.title,
              status: values.status.value,
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [values.title, values.status]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getVisitorInvite({
          start: pageN,
          title: values.title,
          status: values.status.value,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getVisitorInvite({
        start: (page - 1) * 10,
        title: values.title,
        status: values.status.value,
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendNowNotificationHandler = (id) => {
    const data = {
      id,
    };
    dispatch(
      sendNotificationVisitorInvite({
        data,
        toast: t("toast.notification_visitorinvite"),
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getVisitorInvite({
                start: (page_number - 1) * 10,
                title: values.title,
                status: values.status.value,
              })
            );
          }
        },
      })
    );
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">
            {/* {sideHeading} */}
            {SidebarComponent()}
          </h2>
          <div className="creat_edit_project_btn_row creat_edit_project_btn_row_flex">
            <Link
              to={routes.createVisitorInvite}
              state={{ page_number, values }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search") + " " + t("filter.search_name")}
              id="title"
              name="title"
              value={values.title}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Select
              placeholder={t("table.status")}
              value={values.status}
              onChange={(e) => handleInputChange(e, "status")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="status"
              options={statusOptions}
            />
          </div>
        </div>
        {visitorInviteLoader ? (
          <Loader />
        ) : visitorInvite?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <>
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    <th className="custom_data_table_heading">
                      {t("table.name")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.email")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("contact.head")}
                    </th>
                    {/* <th className="custom_data_table_heading">
                      {t("form.purpose")}
                    </th> */}
                    <th className="custom_data_table_heading">
                      {t("table.status")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.date")}
                    </th>
                    {/* <th className="custom_data_table_heading">
                      {t("visitorInvite.time")}
                    </th> */}
                    <th className="custom_data_table_heading">
                      {t("table.createdAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.updatedAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.visitoremail")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {visitorInvite?.length > 0 &&
                    visitorInvite?.map((menu, i) => {
                      return (
                        <tr className="custom_data_table_row" key={menu.id}>
                          <td className="custom_data_table_item table_item">
                            {menu.first_name || menu.last_name
                              ? menu.first_name + " " + menu.last_name
                              : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {menu.email ? menu.email : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {menu.visitor_country_code
                              ? "+" +
                                menu.visitor_country_code +
                                " " +
                                menu.visitor_phone_number
                              : "-"}
                          </td>
                          {/* <td className="custom_data_table_item table_item">
                            {menu.purpose_title ? menu.purpose_title : "-"}
                          </td> */}
                          <td className="custom_data_table_item table_item">
                            {menu.invite_status ===
                            "pending_visitor_information"
                              ? "Pending Information"
                              : menu.invite_status === "pending_approval"
                              ? "Pending Approval"
                              : menu.invite_status === "cancelled"
                              ? "Cancelled"
                              : menu.invite_status === "rejected"
                              ? "Rejected"
                              : menu.invite_status === "approved"
                              ? "Approved"
                              : menu.invite_status === "requested"
                              ? "Requested"
                              : menu.invite_status}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {menu.visit_date
                              ? moment(menu.visit_date).format(
                                  "DD/MM/YYYY, hh:mm A"
                                )
                              : "-"}
                            {/* {menu.visit_date
                              ? moment
                                  .utc(menu.visit_date, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, kk:mm:ss")
                              : "-"} */}
                          </td>
                          {/* <td className="custom_data_table_item table_item">
                            {menu.visit_time}
                          </td> */}

                          <td className="custom_data_table_item table_item">
                            {menu.created_at &&
                              moment
                                .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")}
                          </td>
                          <td className="custom_data_table_item table_item">
                            {menu.update_at
                              ? moment
                                  .utc(menu.update_at, "YYYYMMDD HH:mm:ss")
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY, hh:mm A")
                              : "-"}
                          </td>
                          <td className="custom_data_table_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              {((menu.invite_status !== "cancelled" &&
                                menu.invite_status !== "rejected" &&
                                menu.invite_status !== "pending_approval") ||
                                (menu.invite_status == "approved" &&
                                  new Date(menu.visit_date).getTime() >=
                                    new Date().getTime())) && (
                                <button
                                  onClick={() =>
                                    sendNowNotificationHandler(menu.id)
                                  }
                                  className="custom_data_table_view_edit_item_btn hide_compoenent_btn w-fc"
                                  title={
                                    new Date(menu.visit_date) + " " + new Date()
                                  }
                                >
                                  {t("table.resend")}
                                </button>
                              )}
                            </div>
                          </td>
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              <div className="tooltip">
                                <button
                                  className="custom_data_table_view_edit_item_btn"
                                  onClick={() => viewAnnouncementHandler(menu)}
                                >
                                  {viewIcon}
                                </button>
                                <span className="tooltiptext">View</span>
                              </div>
                              {menu.invite_status !== "cancelled" &&
                                menu.invite_status !== "rejected" && (
                                  <div className="tooltip">
                                    <Link
                                      to={`${routes.visitorInvite}/edit/${menu.id}`}
                                      state={{
                                        user: menu,
                                        page_number,
                                        values,
                                      }}
                                      className="custom_data_table_view_edit_item_btn"
                                    >
                                      {editIcon}
                                    </Link>
                                    <span className="tooltiptext">Edit</span>
                                  </div>
                                )}
                              {menu.invite_status == "approved" ||
                              menu.invite_status === "cancelled" ||
                              menu.invite_status === "rejected" ? (
                                <div className="tooltip">
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    onClick={() => deleteModalHandler(menu)}
                                  >
                                    {deleteIcon}
                                  </button>
                                  <span className="tooltiptext">Delete</span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {+visitorInviteCount > 0 && (
              <Pagination
                totalRecords={visitorInviteCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}

        <ViewModalVisitorInvite
          handleClose={handleCloseViewModal}
          show={viewModal}
          visitorDetail={viewModalData}
        />
        <DeleteVisitorInviteModal
          show={deleteModal}
          serviceDetail={deleteModalId}
          handleClose={handleClose}
          setDeleteModal={setDeleteModal}
          setDeleteModalId={setDeleteModalId}
          page_number={page_number}
          values={values}
        />
      </div>
    </>
  );
};

export default VisitorInvite;
