import React from "react";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  createFeatureRole,
  getRoleFeaturesData,
} from "../../store/slice/rolemanagementSlice";
import { t } from "i18next";

const CreateFeature = ({
  values,
  handleInputChange,
  setValues,
  setAddFormData,
  id,
}) => {
  const dispatch = useDispatch();
  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  const roleFeatureTypeOptions = roleList?.map((item) => {
    return {
      value: item.role_display_name,
      label: item.role_display_name,
      id: item.id,
    };
  });

  const validate = () => {
    let isValid = false;
    if (!values.role_feature) {
      setValues({ ...values, role_feature_err: "Role feature is required" });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const createFeatureRoleHandler = () => {
    if (validate()) {
      const data = {
        role_id: values.role_feature.id.toString(),
        feature_id: values.feature_id,
        is_create: values.is_create === true ? "1" : "0",
        is_delete: values.is_delete === true ? "1" : "0",
        is_update: values.is_update === true ? "1" : "0",
        is_read: values.is_read === true ? "1" : "0",
      };

      dispatch(
        createFeatureRole({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setAddFormData(false);
              setValues({ ...values, role_feature: "" });
              dispatch(getRoleFeaturesData({ id }));
            }
          },
          toast: t("toast.create_featurerole"),
        })
      );
    }
  };

  return (
    <>
      <div className="edit-feature-column">
        <h3 className="mobile_configuration_heading  edit-feature-headings">
          Add Role Feature
        </h3>
        <div className="create_from_row">
          <div className={`create_from_input_content`}>
            <label className="create_from_label">Feature Role </label>
            <Select
              placeholder="Select Role feature"
              value={values.role_feature}
              className="basic-multi-select"
              classNamePrefix="select"
              name="role_feature"
              options={roleFeatureTypeOptions}
              onChange={(e) => handleInputChange(e, "role_feature")}
            />
            {values.role_feature_err && (
              <span className="err_text">{values.role_feature_err}</span>
            )}
          </div>
          <div></div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Create"
              id="is_create"
              name="is_create"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.is_create}
            />
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Delete"
              id="is_delete"
              name="is_delete"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.is_delete}
            />
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Read"
              id="is_read"
              name="is_read"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.is_read}
            />
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_contentainer"
              labelClassName="create_from_radio_label_role"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Update"
              id="is_update"
              name="is_update"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.is_update}
            />
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createFeatureRoleHandler}
          text="Create"
        />
      </div>
    </>
  );
};

export default CreateFeature;
