import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createDirectoryType = createAsyncThunk(
  "createDirectoryType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/types/directory`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      // return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const createDirectory = createAsyncThunk(
  "createDirectory",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/directories`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.directory);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateDirectory = createAsyncThunk(
  "updateDirectory",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/directories/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.directory}/page/1`, {
          state: { value: data.value, page: data.page },
        });
        return response.data;
      }
      if (data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const updateDirectoryType = createAsyncThunk(
  "updateDirectoryType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/types/directory/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const getDirectory = createAsyncThunk(
  "getDirectory",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/directories?start=${
          data.start || 0
        }&floor_name=&directory_type&directory_name=&directory_type_name=${
          data.directory_type_name || ""
        }&floor=${data.floor || ""}`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getDirectoryTypes = createAsyncThunk(
  "getDirectoryTypes",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/types/directories`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const directorySlice = createSlice({
  name: "directory",
  initialState: {
    directoryList: [],
    directoryTypeList: [],
    createDirectoryComponent: "",
    updateAmenityComponent: "",
    error: null,
    loader: false,
    updateLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get directory
    builder.addCase(getDirectory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDirectory.fulfilled, (state, action) => {
      state.loader = false;
      state.directoryList = action.payload;
    });
    builder.addCase(getDirectory.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get directory types
    builder.addCase(getDirectoryTypes.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDirectoryTypes.fulfilled, (state, action) => {
      state.loader = false;
      state.directoryTypeList = action.payload;
    });
    builder.addCase(getDirectoryTypes.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Directory
    builder.addCase(createDirectory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createDirectory.fulfilled, (state, action) => {
      state.loader = false;
      state.createDirectoryComponent = action.payload;
    });
    builder.addCase(createDirectory.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default directorySlice.reducer;
