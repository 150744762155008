import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  editIcon,
  viewIcon,
} from "../../icons";
import {
  getAnnouncement,
  updateAnnouncement,
} from "../../store/slice/announcementSlice";
import { defaultTimeZone } from "../../helpers/commonFunction";

const AnnouncementList = ({
  values,
  setValues,
  page_number,
  handleNotificationShow,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const announcements = useSelector(
    (state) => state.announcement.announcementList?.data
  );

  const viewAnnouncementHandler = (menu) => {
    setValues({
      ...values,
      viewAnnouncementModal: true,
      announcementDetail: menu,
    });
  };

  const deleteModalHandler = (menu) => {
    setValues({
      ...values,
      announcementDeleteModal: true,
      deleteModalId: menu.id,
    });
  };

  const updateAnnouncementHandler = (menu) => {
    const data = {
      is_active: menu.is_active === "1" ? "0" : "1",
    };
    dispatch(
      updateAnnouncement({
        data,
        id: menu.id,
        toastText: t("toast.update_announcement"),
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getAnnouncement({}));
          }
        },
      })
    );
  };

  const dateIsPast = (date) => {
    let today = moment().startOf("day");
    let givenDate = moment(date, "YYYY-MM-DDTHH:mm:ss");
    return givenDate.isBefore(today);
  };

  return (
    <>
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading">{t("table.title")}</th>
              <th className="custom_data_table_heading">
                {t("announcement.announcement_type")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.announcement_time")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.start_date")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.end_date")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.is_active")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.createdAt")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.updatedAt")}
              </th>
              <th className="custom_data_table_heading">Notification</th>
              <th className="custom_data_table_heading">{t("table.action")}</th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {announcements?.map((menu, i) => {
              // console.log(
              //   "menu",
              //   moment
              //     .utc(menu.announcement_date_and_time, "YYYYMMDD HH:mm:ss")
              //     .clone()
              //     .tz(defaultTimeZone)
              //     .format("DD/MM/YYYY, hh:mm A")
              // );
              return (
                <tr className="custom_data_table_row" key={menu.id}>
                  <td className="custom_data_table_item table_item">
                    {menu.announcement_title}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.announcement_type_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {moment
                      .utc(menu.announcement_date_and_time, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.start_date &&
                      moment(menu.start_date).format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.end_date
                      ? moment(menu.end_date).format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.is_active === "1" ? checkIcon : crossIcon}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.created_at &&
                      moment
                        .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {menu.updated_at
                      ? moment
                          .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      {menu.send_now === "0" &&
                      menu.end_date &&
                      !dateIsPast(menu.end_date) ? (
                        <button
                          onClick={() => handleNotificationShow(menu)}
                          className="custom_data_table_view_edit_item_btn hide_compoenent_btn w-fc"
                        >
                          {t("table.send")}
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row custom_table_four_btn_row">
                      <button
                        className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                          menu.is_active === "1" ? "red" : "green"
                        }`}
                        onClick={() => updateAnnouncementHandler(menu)}
                        disabled={
                          menu.end_date && dateIsPast(menu.end_date)
                            ? true
                            : false
                        }
                      >
                        {menu.is_active === "1"
                          ? t("feature.deactivate")
                          : t("table.activate")}
                      </button>

                      <div className="tooltip">
                        <button
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => viewAnnouncementHandler(menu)}
                        >
                          {viewIcon}
                        </button>
                        <span className="tooltiptext">View</span>
                      </div>
                      {((menu.end_date && !dateIsPast(menu.end_date)) ||
                        menu.end_date == null) && (
                        <div className="tooltip">
                          <Link
                            to={`${routes.announcement}/edit/${menu.id}`}
                            state={{
                              menu,
                              value: {
                                announcement_type: values.announcement_type,
                                announcement_title: values.announcement_title,
                                startDate: values.startDate,
                                endDate: values.endDate,
                              },
                              page_number,
                            }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      )}
                      <div className="tooltip">
                        <button
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => deleteModalHandler(menu)}
                        >
                          {deleteIcon}
                        </button>
                        <span className="tooltiptext">Delete</span>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnnouncementList;
