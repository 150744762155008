export const notificationTypeValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("notificationType_icon" in fieldValues) {
    temp.notificationType_icon = "";
    if (!fieldValues.notificationType_icon) {
      temp.notificationType_icon = t("errors.icon_require");
    }

    if (fieldValues.notificationType_icon.size > 1000000) {
      temp.notificationType_icon = t("errors.small_icon_size");
      setValues({
        ...values,
        notificationType_icon_preview: "",
        notificationType_icon: "",
      });
    }
    if (
      fieldValues.notificationType_icon?.name &&
      !fieldValues.notificationType_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.notificationType_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        notificationType_icon_preview: "",
        notificationType_icon: "",
      });
    }
  }

  if ("notificationType_name" in fieldValues) {
    temp.notificationType_name = fieldValues.notificationType_name
      ? ""
      : t("errors.name");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
