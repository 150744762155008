import React from "react";
import { useTranslation } from "react-i18next";
import { SidebarComponent } from "../../helpers/commonFunction";

const DeleteAmenityModal = ({ values, handleClose, deleteAmenityHandler }) => {
  const showHideClassName = values?.deleteModal && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            Delete {SidebarComponent()}
            {/* {sideHeading} */}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteAmenityDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={() => deleteAmenityHandler(values.deleteModalId)}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20 "
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteAmenityModal;
