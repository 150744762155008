import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import {
  deleteAmenity,
  getAmenities,
  updateAmenity,
} from "../../store/slice/amenitySlice";
import Button from "../../component/Common/Button";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import MenuListTable from "../../component/Common/Table/MenuListTable";
import { SidebarComponent, reorder } from "../../helpers/commonFunction";
import Loader from "../../component/Common/Loader";
import { dataForUpdate } from "../../component/Common/PageComponent/AmenityComponents";
import DeleteAmenityModal from "./DeleteAmenityModal";
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const Amenities = () => {
  const dispatch = useDispatch();
  let amenityList = useSelector((state) => state.amenity.amenitiesList);
  let amenityLoader = useSelector((state) => state.amenity.loader);
  let amenityUpdateLoader = useSelector((state) => state.amenity.updateLoader);

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  const [apiErr, setApiErr] = useState(false);

  // const [saveDisabled, setSaveDisabled] = useState(false);
  const ref = useRef(false);

  useEffect(() => {
    dispatch(
      getAmenities({
        noAuthority: (error) => {
          if (error) {
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [dispatch]);

  const initialFValues = {
    deleteModal: false,
    deleteModalId: "",
  };

  const { values, setValues } = useForm(initialFValues, true);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const handleClose = () => {
    setValues({ ...values, deleteModal: false, deleteModalId: "" });
  };

  const updateAmenityHandler = (amenity, action) => {
    const data = dataForUpdate(amenity, action);

    dispatch(
      updateAmenity({
        data,
        id: amenity.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            action === "delete"
              ? toast.error(t("toast.delete_amenity"), {
                  autoClose: 3000,
                })
              : toast.success(
                  `${t("amenity.head")} ${
                    amenity.hidden === "0"
                      ? t("feature.deactivated")
                      : t("feature.activated")
                  } ${t("amenity.successfully")}`,
                  {
                    autoClose: 3000,
                  }
                );
            setValues({ ...values, deleteModal: false, deleteModalId: "" });
            dispatch(getAmenities());
          }
        },
      })
    );
  };

  const deleteAmenityHandler = (id) => {
    dispatch(
      deleteAmenity({
        id: id.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getAmenities());
            setValues({ ...values, deleteModal: false, deleteModalId: "" });
          }
        },
      })
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      amenityList,
      result.source.index,
      result.destination.index
    );
    amenityList = items;

    // setSaveDisabled(true);
    ref.current = true;
  };

  const saveAmenityHandler = () => {
    for (let i = 0; i < amenityList.length; i++) {
      const data = {
        amenity_name: amenityList[i].amenity_name,
        amenity_icon: amenityList[i].amenity_icon,
        order_number: i + 1,
      };

      dispatch(
        updateAmenity({
          data,
          id: amenityList[i].id,
          toast: 1,
          // eslint-disable-next-line no-loop-func
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              if (i === amenityList.length - 1) {
                dispatch(getAmenities());
                toast.success(t("toast.update_amenity"), {
                  autoClose: 2000,
                });
              }
              ref.current = false;
              // setSaveDisabled(false);
            }
          },
        })
      );
    }
  };

  if (amenityLoader || amenityUpdateLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">
            {/* {sideHeading} */}
            {SidebarComponent()}
          </h2>
          <div className="creat_edit_project_btn_row">
            <Link to={routes.createAmenities} className="comman_btn ml-auto">
              {t("common.create")}
            </Link>
          </div>
        </div>
        {/* {amenityList?.length > 0 && (
        <Button
          buttonClassName={`comman_btn mb-15 ${!ref.current && "disabled_btn"}`}
          className="create_from_bottom_btn"
          onClick={() => saveAmenityHandler()}
          text={t("common.save")}
          disabled={!ref.current}
        />
      )} */}
        {amenityList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !amenityList && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="custom_data_table_content"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <Button
                    buttonClassName={`comman_btn mb-15 ${
                      !ref.current && "disabled_btn"
                    }`}
                    className="create_from_bottom_btn"
                    onClick={() => saveAmenityHandler()}
                    text={t("common.save")}
                    disabled={!ref.current}
                  />
                  <MenuListTable
                    list={amenityList}
                    updateMenu={updateAmenityHandler}
                    name={"amenity"}
                    values={values}
                    setValues={setValues}
                    setImgZoom={setImgZoom}
                    setImgSrc={setImgSrc}
                  />
                  {amenityList?.length > 0 && (
                    <Button
                      buttonClassName={`comman_btn save_featured ${
                        !ref.current && "disabled_btn"
                      }`}
                      className="create_from_bottom_btn"
                      onClick={() => saveAmenityHandler()}
                      text={t("common.save")}
                      disabled={!ref.current}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <DeleteAmenityModal
          values={values}
          handleClose={handleClose}
          deleteAmenityHandler={deleteAmenityHandler}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default Amenities;
