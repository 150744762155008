import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDatePicker from "../../component/Common/DatePicker";
import Input from "../../component/Common/Input";
import { visitorInviteValidation } from "../../component/Common/PageComponent/VisitorInviteComponents";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import { parsePhoneNumber } from "react-phone-number-input";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import moment from "moment";
import {
  createVisitorInvite,
  getTenantsByCompanyId,
  getVisitorCompany,
  getVisitorInviteFloor,
  updateVisitorInvite,
} from "../../store/slice/visitorInviteSlice";

import { routes } from "../../constants";
import Select from "react-select";
import { useState } from "react";
import { getPurpose } from "../../store/slice/purposeSlice";
import Loader from "../../component/Common/Loader";
import { toast } from "react-toastify";
import { SidebarComponent } from "../../helpers/commonFunction";

const CreateVisitorInvite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.user;
  const value = location?.state?.values;
  const page = location?.state?.page_number;
  const sideHeading = localStorage.getItem("sidebar_component");
  const loader = useSelector((state) => state.visitorInvite.loader);
  const [isSaving, setIsSaving] = useState(false); // Add this state

  const purposeList = useSelector((state) => state.purpose.purposeList?.data);

  const { t } = useTranslation("common");

  const statusList = [
    // { display_name: "Requested", value: "requested" },
    {
      display_name: "Pending Visitor Information",
      value: "pending_visitor_information",
      disableValue:
        editComponent?.invite_status === "pending_visitor_information"
          ? true
          : false,
    },
    {
      display_name: "Pending Approval",
      value: "pending_approval",
      disableValue:
        editComponent?.invite_status === "pending_visitor_information" ||
        editComponent?.invite_status === "approved" ||
        editComponent?.invite_status === "rejected" ||
        editComponent?.invite_status === "cancelled"
          ? false
          : true,
    },
    {
      display_name: "Approved",
      value: "approved",
      disableValue:
        editComponent?.invite_status === "pending_visitor_information" ||
        editComponent?.invite_status === "rejected" ||
        editComponent?.invite_status === "cancelled"
          ? false
          : true,
    },
    {
      display_name: "Rejected",
      value: "rejected",
      disableValue:
        editComponent?.invite_status === "cancelled" ||
        editComponent?.invite_status === "approved"
          ? false
          : true,
    },
    {
      display_name: "Cancelled",
      value: "cancelled",
      disableValue: editComponent?.invite_status === "rejected" ? false : true,
    },
  ];

  const initialFValues = {
    first_name: editComponent ? editComponent.first_name : "",
    last_name: editComponent ? editComponent.last_name : "",
    visitor_phone_number:
      editComponent &&
      editComponent.visitor_country_code !== "" &&
      editComponent.visitor_country_code !== null
        ? "+" +
          editComponent.visitor_country_code +
          editComponent.visitor_phone_number
        : "",
    visitor_company:
      editComponent && editComponent?.visitor_company
        ? editComponent?.visitor_company
        : "",
    visitor_floor: editComponent
      ? editComponent.visitor_floor_id == "" ||
        editComponent.visitor_floor_id == null
        ? {
            value: "",
            label: "Select Visitor Floor",
            id: "",
          }
        : {
            value: editComponent.visitor_floor_id,
            label: editComponent.visitor_floor_id,
            id: editComponent.visitor_floor_id,
          }
      : "",
    email: editComponent ? editComponent?.email : "",
    email_one:
      editComponent && editComponent?.email_one ? editComponent?.email_one : "",
    email_two:
      editComponent && editComponent?.email_two ? editComponent?.email_two : "",
    email_three:
      editComponent && editComponent?.email_three
        ? editComponent?.email_three
        : "",
    email_four:
      editComponent && editComponent?.email_four
        ? editComponent?.email_four
        : "",
    date: editComponent ? new Date(editComponent?.visit_date) : "",

    purpose: editComponent
      ? { label: editComponent?.purpose_title, value: editComponent?.purpose }
      : "",
    status: editComponent ? editComponent?.invite_status : "",
    tenant_name: editComponent
      ? {
          value: editComponent.tenant_id,
          label:
            editComponent.tenant_first_name +
            " " +
            editComponent.tenant_last_name,
          data: editComponent,
        }
      : "",
    tenant_company: editComponent
      ? {
          value: editComponent.tenant_id,
          label: editComponent?.tenant_company,
          data: editComponent,
        }
      : "",
    vehicle_type: editComponent
      ? editComponent.vehicle_type == "" || editComponent.vehicle_type == null
        ? {
            value: "",
            label: "No vehicle",
          }
        : {
            value: editComponent.vehicle_type,
            label: editComponent.vehicle_type,
          }
      : "",
    license_plate_number: editComponent
      ? editComponent?.licence_plate_number
      : "",
    subvisitor_1:
      editComponent && editComponent?.subvisitor_1
        ? editComponent?.subvisitor_1
        : "",
    subvisitor_2:
      editComponent && editComponent?.subvisitor_2
        ? editComponent?.subvisitor_2
        : "",
    subvisitor_3:
      editComponent && editComponent?.subvisitor_3
        ? editComponent?.subvisitor_3
        : "",
    subvisitor_4:
      editComponent && editComponent?.subvisitor_4
        ? editComponent?.subvisitor_4
        : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return visitorInviteValidation(
      editComponent,
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      t
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  // console.log("values", values);

  const [floor, setFloor] = useState("");
  const [contact, setContact] = useState("");
  const [vehiclePlateErr, setVehiclePlateErr] = useState("");

  useEffect(() => {
    if (
      values?.tenant_name?.data?.tenant_floor ||
      values?.tenant_name?.data?.default_floor_name
    ) {
      setFloor(
        values?.tenant_name?.data?.tenant_first_name
          ? {
              label: values?.tenant_name?.data?.tenant_floor,
              value: values?.tenant_name?.data?.tenant_floor,
              id: values?.tenant_name?.data?.tenant_floor_id,
            }
          : {
              label: values?.tenant_name?.data?.default_floor_name,
              value: values?.tenant_name?.data?.default_floor_name,
              id: values?.tenant_name?.data?.default_floor_id,
            }
      );
    }

    setContact(
      editComponent && editComponent.tenant_country_code
        ? "+" +
            editComponent.tenant_country_code +
            " " +
            editComponent.tenant_phone_number
        : values?.tenant_name?.data && values?.tenant_name?.data?.phone_number
        ? "+" +
          values?.tenant_name?.data?.country_code +
          " " +
          values?.tenant_name?.data?.phone_number
        : ""
    );
  }, [values?.tenant_name]);

  // Get visitor company list
  useEffect(() => {
    if (editComponent === undefined) {
      dispatch(getVisitorCompany());
    }
    dispatch(getPurpose({}));
  }, []);

  useEffect(() => {
    if (values.tenant_name) {
      dispatch(getVisitorInviteFloor({ id: values.tenant_name?.value }));
    }
  }, [values?.tenant_name]);

  useEffect(() => {
    setErrors({
      ...errors,
      visitor_phone_number: "",
    });
  }, [values?.visitor_phone_number]);

  const visitorFloorList = useSelector(
    (state) => state.visitorInvite.visitorInviteFloorList
  );

  const floorLevelOptions = visitorFloorList?.map((floor) => {
    return {
      value: floor.default_floor_name,
      label: floor.default_floor_name,
      id: floor.id,
    };
  });

  const purposeOptions = purposeList?.map((purpose) => {
    return {
      value: purpose.id,
      label: purpose.title,
      id: purpose.id,
    };
  });

  // Get tenants company list from store
  const tenantsCompany = useSelector(
    (state) => state.visitorInvite.visitorCompany
  );

  const tenantCompanyOptions =
    tenantsCompany &&
    tenantsCompany.map((item) => {
      return {
        value: item.id,
        label: item.company_name,
        data: item,
      };
    });

  // Get tenantsList by company Id
  useEffect(() => {
    if (values?.tenant_company && editComponent === undefined) {
      dispatch(getTenantsByCompanyId(values?.tenant_company?.data));
    }
  }, [dispatch, editComponent, values?.tenant_company]);

  // Get tenantsList from store
  const tenantsList = useSelector((state) => state.visitorInvite.tenantsList);
  const tenantListOptions = tenantsList?.map((item) => {
    return {
      value: item.id,
      label: item.first_name + " " + item.last_name,
      data: item,
    };
  });

  const vehicleTypeOptions = [
    {
      value: "",
      label: "No vehicle",
    },
    {
      value: "Car",
      label: "Car",
    },
    {
      value: "Motorcycle",
      label: "Motorcycle",
    },
  ];

  const changeVehTypeHandler = (e) => {
    if (e.value === "") {
      setValues({
        ...values,
        vehicle_type: e,
        license_plate_number: "",
      });
      setVehiclePlateErr("");
    } else {
      setValues({
        ...values,
        vehicle_type: e,
      });
      setVehiclePlateErr("");
    }
  };

  const vehicleValidate = () => {
    if (values.vehicle_type === "" || values.vehicle_type.value === "") {
      return true;
    } else if (values.license_plate_number?.trim() !== "") {
      return true;
    } else {
      setVehiclePlateErr(t("errors.number_plate_err"));
      return false;
    }
  };

  const visitorInviteHandler = () => {
    if (validate() && vehicleValidate()) {
      // setIsSaving(true);

      const parsedPhone =
        values?.visitor_phone_number &&
        withoutMetadata(parsePhoneNumber(values.visitor_phone_number));

      const data = {
        // first_name: values?.first_name,
        // last_name: values?.last_name,
        visitor_company: values?.visitor_company,
        floor_id: floor.id,
        visit_date: moment(values?.date).format("YYYY-MM-DD HH:mm:ss"),
        // purpose: values?.purpose.value ? values?.purpose.value : "",
        tenant_id: values?.tenant_name.value,
        // vehicle_type: values?.vehicle_type?.value
        //   ? values?.vehicle_type?.value
        //   : "",
        // licence_plate_number: values?.license_plate_number,
        visitor_floor_id: values?.visitor_floor?.value,
      };

      if (editComponent) {
        data.email = values?.email;
      } else {
        const bulkEmails = {
          visitor_email_1: values?.email,
          visitor_email_2: values?.email_one,
          visitor_email_3: values?.email_two,
          visitor_email_4: values?.email_three,
          visitor_email_5: values?.email_four,
        };

        // Filter out empty email values and create a new object
        data.bulk_email = Object.fromEntries(
          Object.entries(bulkEmails).filter(([key, value]) => value)
        );
      }

      if (
        editComponent &&
        (editComponent?.visitor_country_code !== "" ||
          editComponent?.visitor_country_code !== null) &&
        (values.visitor_phone_number === undefined ||
          values.visitor_phone_number === "")
      ) {
        data.visitor_country_code = "";
        data.visitor_phone_number = "";
      }
      if (parsedPhone?.countryCallingCode && parsedPhone?.nationalNumber) {
        data.visitor_country_code = parsedPhone?.countryCallingCode;
        data.visitor_phone_number = parsedPhone?.nationalNumber;
      }

      if (editComponent) data.invite_status = values.status;

      // console.log("data", data);

      if (editComponent) {
        dispatch(
          updateVisitorInvite({
            data,
            navigate,
            id: editComponent?.id,
            toast: t("toast.update_visitorinvite"),
            page,
            value,
            setIsSaving,
          })
        );
      } else {
        dispatch(
          createVisitorInvite({
            data,
            navigate,
            toast: t("toast.create_visitorinvite"),
            setIsSaving,
          })
        );
      }
    }
  };

  if (isSaving) {
    return <Loader />;
  }

  // Handle filter times
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {/* {editComponent ? `${sideHeading} > Edit` : `${sideHeading} > Create`} */}
          {editComponent
            ? `${SidebarComponent()} > Edit`
            : `${SidebarComponent()} > Create`}
        </h2>
        <button
          onClick={() => {
            navigate(`${routes.visitorInvite}/page/${page}`, {
              state: { page, value },
            });
            setIsSaving(false);
          }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content visitor_invite_form_row">
        <div className="create_from_row">
          <div>
            <label className="create_from_label required">
              {t("visitorInvite.tenant_company")}
            </label>
            <Select
              placeholder={t("visitorInvite.tenant_company_place")}
              value={values?.tenant_company}
              className={`basic-multi-select visitor_label_tenant`}
              classNamePrefix="select"
              name="tenant_company"
              options={tenantCompanyOptions}
              onChange={(e) => handleInputChange(e, "tenant_company")}
              isDisabled={editComponent ? true : false}
            />
            {errors?.tenant_company && (
              <span className="err_text">{errors?.tenant_company}</span>
            )}
          </div>
          {values?.tenant_company && (
            <div>
              <label className="create_from_label required">
                {t("visitorInvite.tenant_name")}
              </label>
              <Select
                placeholder={t("visitorInvite.tenant_name_place")}
                value={values?.tenant_name}
                className={`basic-multi-select visitor_label_tenant`}
                classNamePrefix="select"
                name="tenant_name"
                options={tenantListOptions}
                onChange={(e) => handleInputChange(e, "tenant_name")}
                isDisabled={editComponent ? true : false}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
              {errors?.tenant_name && (
                <span className="err_text">{errors?.tenant_name}</span>
              )}
            </div>
          )}
          {values?.tenant_name && (
            <>
              <div className="visitor_infom_form_col">
                <h2 className="visitor_infom_form_heading">Tenant Details</h2>
              </div>

              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_department")}
                id="department"
                name="department"
                value={
                  values?.tenant_name?.data?.tenant_department ||
                  values?.tenant_name?.data?.department_name ||
                  "-"
                }
                onChange={handleInputChange}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_first_name")}
                id="first_name"
                name="first_name"
                value={
                  values?.tenant_name?.data?.tenant_first_name
                    ? values?.tenant_name?.data?.tenant_first_name
                    : values?.tenant_name?.data?.first_name || "-"
                }
                onChange={handleInputChange}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_last_name")}
                id="last_name"
                name="last_name"
                value={
                  values?.tenant_name?.data?.tenant_first_name
                    ? values?.tenant_name?.data?.tenant_last_name
                    : values?.tenant_name?.data?.last_name || "-"
                }
                onChange={handleInputChange}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_job_title")}
                id="job_title"
                name="job_title"
                value={
                  values?.tenant_name?.data?.tenant_first_name
                    ? values?.tenant_name?.data?.tenant_position
                    : values?.tenant_name?.data?.job_title || "-"
                }
                onChange={handleInputChange}
                disabled={true}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_email")}
                id="email"
                name="email"
                value={
                  values?.tenant_name?.data?.tenant_first_name
                    ? values.tenant_name?.data?.tenant_email
                    : values?.tenant_name?.data?.email || "-"
                }
                onChange={handleInputChange}
                disabled={true}
              />

              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input visitor_disabled_input`}
                type="text"
                label={t("visitorInvite.tenant_contact")}
                id="contact"
                name="contact"
                value={contact || ""}
                onChange={(e) => setContact(e.target.value)}
                disabled={true}
              />
              <div className="login_input_row mb-0 tenant_floor_selectBox">
                <label>{t("visitorInvite.tenant_floor")}</label>
                <Select
                  placeholder={t("visitorInvite.tenant_floor_place")}
                  value={floor}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="floor"
                  options={floorLevelOptions}
                  onChange={(e) => setFloor(e)}
                  isDisabled={editComponent?.invite_status === "approved"}
                />
              </div>
            </>
          )}
          <div className="visitor_infom_form_col">
            <h2 className="visitor_infom_form_heading">Visitor Details</h2>
          </div>
          <CommonDatePicker
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.date") + "/Time of visit"}
            name="date"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeSelect={true}
            timeIntervals={15}
            filterTime={filterPassedTime}
            isDisabled={editComponent?.invite_status === "approved"}
          />
          <div className="login_input_row mb-0 tenant_floor_selectBox visitor_floor_id">
            <label>{t("visitorInvite.visitor_floor")}</label>
            <Select
              placeholder={t("visitorInvite.visitor_floor_place")}
              value={values.visitor_floor}
              className="basic-multi-select"
              classNamePrefix="select"
              name="visitor_floor"
              options={floorLevelOptions}
              onChange={(e) => handleInputChange(e, "visitor_floor")}
              isDisabled={editComponent?.invite_status === "approved"}
            />
          </div>
        </div>
        {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName={`create_from_input ${
              editComponent?.invite_status === "approved" &&
              "visitor_disabled_input"
            }`}
            errorClassName="err_text"
            type="text"
            placeholder={t("visitorInvite.company_place")}
            label={t("form.company")}
            id="visitor_company"
            name="visitor_company"
            value={values?.visitor_company}
            onChange={handleInputChange}
            disabled={editComponent?.invite_status === "approved"}
          /> */}
        {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input ${
              editComponent && "visitor_disabled_input"
            }`}
            errorClassName="err_text"
            type="text"
            placeholder={t("form.firstnamePlace")}
            label={t("form.firstname")}
            id="first_name"
            name="first_name"
            value={values?.first_name}
            onChange={handleInputChange}
            error={errors?.first_name || ""}
            disabled={editComponent}
          /> */}
        {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input ${
              editComponent && "visitor_disabled_input"
            }`}
            errorClassName="err_text"
            type="text"
            placeholder={t("form.lastnamePlace")}
            label={t("form.lastname")}
            id="last_name"
            name="last_name"
            value={values?.last_name}
            onChange={handleInputChange}
            error={errors?.last_name || ""}
            disabled={editComponent}
          /> */}
        <label className="create_from_nested_label_visitor">
          {/* {t("table.visitoremail") + "s"} */}
          Visitor Invite Emails
        </label>
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            // inputClassName={`create_from_input ${
            //   (editComponent?.invite_status === "approved" || editComponent) &&
            //   "visitor_disabled_input"
            // }`}
            inputClassName={`create_from_input ${
              editComponent?.invite_status === "approved" &&
              "visitor_disabled_input"
            }`}
            errorClassName="err_text"
            type="email"
            placeholder={t("form.emailPlace")}
            label={t("table.visitoremail") + " 1"}
            id="email"
            name="email"
            value={values?.email}
            onChange={handleInputChange}
            // disabled={
            //   editComponent || editComponent?.invite_status === "approved"
            // }
            disabled={editComponent?.invite_status === "approved"}
            error={errors?.email || ""}
          />
          {!editComponent && (
            <>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  (editComponent?.invite_status === "approved" ||
                    editComponent) &&
                  "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("table.visitoremail") + " 2"}
                id="email_one"
                name="email_one"
                value={values?.email_one}
                onChange={handleInputChange}
                disabled={
                  editComponent || editComponent?.invite_status === "approved"
                }
                error={errors?.email_one || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  (editComponent?.invite_status === "approved" ||
                    editComponent) &&
                  "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("table.visitoremail") + " 3"}
                id="email_two"
                name="email_two"
                value={values?.email_two}
                onChange={handleInputChange}
                disabled={
                  editComponent || editComponent?.invite_status === "approved"
                }
                error={errors?.email_two || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  (editComponent?.invite_status === "approved" ||
                    editComponent) &&
                  "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("table.visitoremail") + " 4"}
                id="email_three"
                name="email_three"
                value={values?.email_three}
                onChange={handleInputChange}
                disabled={
                  editComponent || editComponent?.invite_status === "approved"
                }
                error={errors?.email_three || ""}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  (editComponent?.invite_status === "approved" ||
                    editComponent) &&
                  "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label={t("table.visitoremail") + " 5"}
                id="email_four"
                name="email_four"
                value={values?.email_four}
                onChange={handleInputChange}
                disabled={
                  editComponent || editComponent?.invite_status === "approved"
                }
                error={errors?.email_four || ""}
              />
            </>
          )}
          {/* <div className="login_input_row mb-0">
          <label className="">Visitor Contact</label>
          <PhoneInput
          placeholder="Enter Phone number"
          value={values?.visitor_phone_number}
          defaultCountry="TH"
          onChange={(e) => {
            setValues({
              ...values,
              visitor_phone_number: e,
            });
          }}
          className={`${
            editComponent?.invite_status === "approved" &&
            "visitor_disabled_input"
          }`}
          disabled={editComponent?.invite_status === "approved"}
          />
          {errors?.visitor_phone_number && (
            <span className="err_text">{errors?.visitor_phone_number}</span>
            )}
          </div> */}
          {/* <div>
          <label className="create_from_label ">{t("form.veh_type")}</label>
          <Select
          placeholder={t("form.select_veh_type")}
              value={values?.vehicle_type}
              className={`basic-multi-select visitor_label_tenant`}
              classNamePrefix="select"
              name="vehicle_type"
              options={vehicleTypeOptions}
              onChange={(e) => changeVehTypeHandler(e)}
              isDisabled={editComponent?.invite_status === "approved"}
            />
          </div> */}
          {/* <Input
          className="create_from_input_content null"
          labelClassName={`create_from_label ${
              (values?.vehicle_type?.value === "Car" ||
                values?.vehicle_type?.value === "Motorcycle") &&
              "required"
            }`}
            inputClassName={`create_from_input ${
              editComponent?.invite_status === "approved" &&
              "visitor_disabled_input"
            } ${
              (values?.vehicle_type?.value === "" ||
              values?.vehicle_type === "") &&
            
              "visitor_disabled_input"
            }`}
            errorClassName="err_text"
            type="text"
            placeholder="Enter License plate number"
            label="Vehicle Number"
            id="license_plate_number"
            name="license_plate_number"
            value={values?.license_plate_number}
            onChange={(e) => {
              setValues({
                ...values,
                license_plate_number: e.target.value,
              });
              setVehiclePlateErr("");
            }}
            disabled={
              editComponent?.invite_status === "approved" ||
              values?.vehicle_type?.value === "" ||
              values?.vehicle_type === ""
            }
            error={vehiclePlateErr || ""}
          /> */}
          {/* <div className="create_from_input_content">
            <label className="create_from_label required">
              {t("form.purpose")}
            </label>
            <Select
              placeholder={t("visitorInvite.purpose_place")}
              className="basic-multi-select purpose_selectBox"
              classNamePrefix="select "
              value={values?.purpose}
              name="purpose"
              options={purposeOptions}
              onChange={(e) => handleInputChange(e, "purpose")}
              isDisabled={editComponent?.invite_status === "approved"}
              menuIsOpen={true}
              menuShouldScrollIntoView={false}
              onClick={handleClick}
              menuPosition={shouldBeFixed ? "fixed" : "auto"}
            />

            {errors?.purpose && (
              <span className="err_text">{errors?.purpose}</span>
            )}
          </div> */}
          <div></div>
          {editComponent && (
            <div className={`create_from_input_content`}>
              <label className="create_from_label">
                {t("table.status")}{" "}
              </label>
              <div className="visitor-button-row visitor-button-row1">
                {statusList.map((btn, i) => {
                  return (
                    <button
                      key={i}
                      className={`visitor-button ${
                        btn.value === values.status && "is-active"
                      } ${!btn.disableValue && "disabled-visitor-button"}`}
                      value={btn.value}
                      onClick={(e) => {
                        setValues({ ...values, status: e.target.value });
                        setErrors({ ...errors, date: "" });
                      }}
                      disabled={!btn.disableValue}
                    >
                      {btn.display_name}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          {editComponent && (
            <>
              <hr className="col_span_2" />
              <div className="visitor_infom_form_col">
                <h2 className="visitor_infom_form_heading">
                  Additional Visitor
                </h2>
              </div>
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  editComponent && "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label="Subvisitor 1"
                id="subvisitor_1"
                name="subvisitor_1"
                value={values?.subvisitor_1}
                onChange={handleInputChange}
                disabled={editComponent}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  editComponent && "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label="Subvisitor 2"
                id="subvisitor_2"
                name="subvisitor_2"
                value={values?.subvisitor_2}
                onChange={handleInputChange}
                disabled={editComponent}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  editComponent && "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label="Subvisitor 3"
                id="subvisitor_3"
                name="subvisitor_3"
                value={values?.subvisitor_3}
                onChange={handleInputChange}
                disabled={editComponent}
              />
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label"
                inputClassName={`create_from_input ${
                  editComponent && "visitor_disabled_input"
                }`}
                errorClassName="err_text"
                type="email"
                placeholder={t("form.emailPlace")}
                label="Subvisitor 4"
                id="subvisitor_4"
                name="subvisitor_4"
                value={values?.subvisitor_4}
                onChange={handleInputChange}
                disabled={editComponent}
              />
            </>
          )}
        </div>

        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName={`comman_btn ${
            (editComponent?.invite_status === "cancelled" ||
              editComponent?.invite_status === "rejected") &&
            "disabled_btn_visitor_page"
          }`}
          onClick={visitorInviteHandler}
          text={`${editComponent ? t("common.save") : t("common.create")}`}
          disabled={
            editComponent &&
            (editComponent?.invite_status === "cancelled" ||
              editComponent?.invite_status === "rejected")
          }
        />
      </div>
    </>
  );
};

export default CreateVisitorInvite;
