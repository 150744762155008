export const promotionValidation = (
  editComponent,
  temp,
  fieldValues,
  values,
  setValues,
  setErrors,
  t
) => {
  if ("title" in fieldValues) {
    temp.title = fieldValues.title ? "" : t("errors.title");
  }
  if ("sub_title" in fieldValues) {
    temp.sub_title = fieldValues.sub_title ? "" : t("errors.sub_title");
  }
  if ("description" in fieldValues) {
    temp.description = fieldValues.description ? "" : t("errors.description");
  }
  // if ("start_date" in fieldValues) {
  //   // temp.start_date = fieldValues.start_date ? "" : t("errors.start_date");
  //   temp.start_date =
  //     new Date(values?.start_date) < new Date()
  //       ? "Selected time is in the past."
  //       : fieldValues.start_date
  //       ? ""
  //       : t("errors.date");
  // }
  if ("start_date" in fieldValues) {
    if (!fieldValues.start_date) {
      temp.start_date = t("errors.date");
    } else {
      if (!editComponent) {
        if (new Date(values?.start_date) < new Date()) {
          temp.start_date = "Selected time is in the past";
        } else {
          temp.start_date = "";
        }
      }
    }
  }
  if ("end_date" in fieldValues) {
    temp.end_date =
      fieldValues.end_date &&
      new Date(values?.end_date) < new Date(values?.start_date)
        ? "Selected time is in the past"
        : fieldValues.end_date &&
          new Date(values?.end_date).getTime() ===
            new Date(values?.start_date).getTime()
        ? "End date should not be same as start date"
        : "";
  }
  // if ("end_date" in fieldValues) {
  //   temp.end_date = fieldValues.end_date ? "" : t("errors.end_date");
  // }
  // if ("start_time" in fieldValues) {
  //   temp.start_time = fieldValues.start_time ? "" : t("errors.start_time");
  // }
  // if ("end_time" in fieldValues) {
  //   temp.end_time = fieldValues.end_time ? "" : t("errors.end_time");
  // }
  if ("address" in fieldValues) {
    temp.address = fieldValues.address ? "" : t("errors.address");
  }
  if ("promotion_link" in fieldValues) {
    temp.promotion_link = fieldValues.promotion_link
      ? ""
      : t("errors.promotion_link");
  }
  if ("user_type" in fieldValues) {
    temp.user_type =
      fieldValues.user_type.length > 0 ? "" : t("errors.user_role");
  }
  if ("image" in fieldValues) {
    temp.image = "";
    if (!fieldValues.image) {
      temp.image = t("errors.img_require");
    }
    // if (values?.include_image === "1" && !fieldValues.image) {
    //   temp.image = t("errors.img_require");
    // }
    if (fieldValues.image.size > 1000000) {
      temp.image = t("errors.small_image_size1");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
    if (
      fieldValues.image?.name &&
      !fieldValues.image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.image = t("errors.img_invalid");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
  }

  // if ("optional_promotion_img" in fieldValues) {
  //   temp.optional_promotion_img = "";
  //   if (fieldValues.optional_promotion_img?.size > 1000000) {
  //     temp.optional_promotion_img = t("errors.small_image_size1");
  //     setValues({
  //       ...values,
  //       optional_promotion_img: "",
  //       optional_promotion_img_preview: "",
  //     });
  //   } else {
  //     temp.optional_promotion_img = "";
  //   }
  // }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
