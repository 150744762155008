export const adminUserValidation = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  t
) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mailReg = new RegExp(mailformat);

  if ("first_name" in fieldValues) {
    temp.first_name = fieldValues.first_name ? "" : t("errors.first_name");
  }

  if ("last_name" in fieldValues) {
    temp.last_name = fieldValues.last_name ? "" : t("errors.last_name");
  }

  if (fieldValues.profile_image?.size > 1000000) {
    temp.profile_image = t("errors.small_image_size");
    setValues({
      ...values,
      profile_image_preview: "",
      profile_image: "",
    });
  } else {
    temp.profile_image = "";
  }

  // if ("phone_number" in fieldValues) {
  //   temp.phone_number = fieldValues.phone_number
  //     ? isValidPhoneNumber(fieldValues.phone_number)
  //       ? ""
  //       : t("errors.phone_valid")
  //     : t("errors.phone_required");
  // }

  if ("email" in fieldValues) {
    if (!fieldValues.email) {
      temp.email = t("errors.email");
    } else {
      temp.email = fieldValues.email.match(mailReg)
        ? ""
        : t("errors.email_val");
    }
    // temp.email = fieldValues.email ? "" : t("errors.email");
  }
  if ("user_role_id" in fieldValues) {
    temp.user_role_id = fieldValues.user_role_id ? "" : t("errors.user_role");
  }

  // if ("profile_image" in fieldValues) {
  //   temp.profile_image = "";
  //   if (!fieldValues.profile_image) {
  //     temp.profile_image = t("errors.img_require");
  //   }
  //   if (fieldValues.profile_image.size > 1000000) {
  //     temp.profile_image = t("errors.large_img_size");
  //     setValues({
  //       ...values,
  //       profile_image_preview: "",
  //       profile_image: "",
  //     });
  //   }
  //   if (
  //     fieldValues?.profile_image?.name &&
  //     !fieldValues?.profile_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
  //   ) {
  //     temp.profile_image = t("errors.img_invalid");
  //     setValues({
  //       ...values,
  //       profile_image_preview: "",
  //       profile_image: "",
  //     });
  //   }
  // }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const generate_password = () => {
  return Math.floor(1000000 + Math.random() * 9000000).toString();
};
