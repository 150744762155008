import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";
import Logo from "../../images/onecitylogo/Logo-Black.png";
import sidebarSmallIcon from "../../images/onecitylogo/Logo.png";
import { routes } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getSidemenu } from "../../store/slice/sidemenuSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let menuList = useSelector((state) => state.sidemenu.sidemenuList);

  useEffect(() => {
    dispatch(getSidemenu({ admin: 0 }));
  }, [dispatch]);

  return (
    <div className="sidebar_container">
      <Link
        to={routes.dashboard}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <div>
          <img
            src={Logo}
            alt="onecity_centre-logo"
            className="full_sidebar_logo"
          />
          <img
            src={sidebarSmallIcon}
            alt="onecity_centre-logo"
            className="small_sidebar_logo"
          />
        </div>
      </Link>
      <ul className="sidebar_nav">
        {menuList?.map((item, i) => {
          return (
            <li className="sidebar_item" key={i}>
              <Link
                className={`sidebar_link ${
                  pathname.match(
                    item.url_link?.split("/")[1] || item.url_link?.split("/")[0]
                  ) && "active"
                } `}
                to={item.url_link}
                onClick={() =>
                  localStorage.setItem("sidebar_component", item.sidemenu_title)
                }
              >
                <img
                  className="sidebar_icon_img"
                  alt="sidebar_icon"
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${item.sidemenu_icon}`}
                />
                <span>{item.sidemenu_title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
