import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getDocType, updateDocType } from "../../store/slice/docTypeSlice";
import { SidebarComponent } from "../../helpers/commonFunction";

const DeleteDocTypeModal = ({
  closeDeleteModal,
  deleteModal,
  deleteDocTypeId,
  setDeleteModal,
  setDeleteDocTypeId,
}) => {
  const showHideClassName = deleteModal && "user_detail_modal_show";

  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const sideHeading = localStorage.getItem("sidebar_component");

  const deleteDocTypeHandler = () => {
    const data = { delete_status: 1 };
    dispatch(
      updateDocType({
        data,
        id: deleteDocTypeId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getDocType({}));
            setDeleteModal(false);
            setDeleteDocTypeId("");
            toast.error(t("toast.delete_docType"), {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={closeDeleteModal}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            Delete {SidebarComponent()}
            {/* {sideHeading} */}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteDocTypeDesc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={closeDeleteModal}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteDocTypeHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteDocTypeModal;
