import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { eyeIcon, hideEyeIcon } from "../../icons";
import { confirmPassword } from "../../store/slice/adminUserSlice";
import { logout } from "../../store/slice/userSlice";

const Password = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const initialFValues = {
    current_password: "",
    currentPasswordType: "password",
    current_password_err: "",
    new_password: "",
    newPasswordType: "password",
    new_password_err: "",
    confirm_password: "",
    conPasswordType: "password",
    confirm_password_err: "",
  };

  const validatePassword = () => {
    let isValid = false;

    if (!values.current_password) {
      setValues({
        ...values,
        current_password_err: "Current password is required!",
      });
    } else if (!values.new_password) {
      setValues({
        ...values,
        new_password_err: "New password is required!",
      });
    } else if (!values.confirm_password) {
      setValues({
        ...values,
        confirm_password_err: "Confirm password is required!",
      });
    } else if (values.confirm_password !== values.new_password) {
      setValues({
        ...values,
        confirm_password_err: "Password & Confirm Password should be same!",
      });
    } else {
      isValid = true;
    }

    return isValid;
  };

  const validate = (fieldValues = values) => {
    return validatePassword();
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const curPassEyeIconHandler = () => {
    setValues({
      ...values,
      currentPasswordType:
        values.currentPasswordType === "password" ? "text" : "password",
    });
  };

  const newPassEyeIconHandler = () => {
    setValues({
      ...values,
      newPasswordType:
        values.newPasswordType === "password" ? "text" : "password",
    });
  };

  const conPassEyeIconHandler = () => {
    setValues({
      ...values,
      conPasswordType:
        values.conPasswordType === "password" ? "text" : "password",
    });
  };

  const currentPassHandler = (e) => {
    setValues({
      ...values,
      current_password: e.target.value,
      current_password_err: "",
    });
  };
  const newPassHandler = (e) => {
    setValues({
      ...values,
      new_password: e.target.value,
      new_password_err: "",
    });
  };
  const conPassHandler = (e) => {
    setValues({
      ...values,
      confirm_password: e.target.value,
      confirm_password_err: "",
    });
  };

  const updatePasswordHandler = () => {
    if (validatePassword()) {
      const data = {
        current_password: values.current_password,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      };

      dispatch(
        confirmPassword({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              toast.success(t("toast.change_password"), {
                autoClose: 3000,
              });
              dispatch(logout());
              navigate(routes.login);
              setValues({
                ...values,
                current_password: "",
                new_password: "",
                confirm_password: "",
                changePasswordComponent: false,
              });
            }
          },
        })
      );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <h2 className="project_subheading send_notification-btn m_reset">
          Reset Password
        </h2>
        <div className="create_from_row">
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values?.currentPasswordType}
            placeholder={t("form.currentPasswordPlace")}
            label={t("form.currentPassword")}
            id="current_password"
            name="current_password"
            value={values?.current_password}
            onChange={currentPassHandler}
            eyeIcon={
              values?.currentPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={curPassEyeIconHandler}
            autoComplete="on"
            error={values?.current_password_err || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values?.newPasswordType}
            placeholder={t("form.newPasswordPlace")}
            label={t("form.newPassword")}
            id="new_password"
            name="new_password"
            value={values?.new_password}
            onChange={newPassHandler}
            eyeIcon={
              values?.newPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={newPassEyeIconHandler}
            autoComplete="on"
            error={values?.new_password_err || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values?.conPasswordType}
            placeholder={t("form.confirmPasswordPlace")}
            label={t("form.confirmPassword")}
            id="confirm_password"
            name="confirm_password"
            value={values?.confirm_password}
            onChange={conPassHandler}
            eyeIcon={
              values?.conPasswordType === "password" ? eyeIcon : hideEyeIcon
            }
            eyeIconHandler={conPassEyeIconHandler}
            autoComplete="on"
            error={values?.confirm_password_err || ""}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={updatePasswordHandler}
          text={t("common.update")}
        />
      </div>
    </>
  );
};

export default Password;
