import { fileUpload } from "../../../store/slice/fileUploadSlice";

// Update Sidemenu Delete/Hide
export const dataForUpdate = (sidemenu, action) => {
  let data;
  data =
    action === "delete"
      ? {
          sidemenu_title: sidemenu.sidemenu_title,
          sidemenu_icon: sidemenu.sidemenu_icon,
          delete_status: "1",
        }
      : {
          sidemenu_title: sidemenu.sidemenu_title,
          sidemenu_icon: sidemenu.sidemenu_icon,
          hidden: sidemenu.hidden === "0" ? "1" : "0",
        };
  return data;
};

// Create Sidemenu
export const createSidemenuData = async (dispatch, values, setValues) => {
  const formData = new FormData();
  let attachmentUrl;
  if (values.sidemenu_icon && typeof values.sidemenu_icon !== "string") {
    formData.append("file", values.sidemenu_icon);
    attachmentUrl = await dispatch(fileUpload(formData));
    if (attachmentUrl && attachmentUrl.payload) {
      setValues({
        ...values,
        sidemenu_icon: attachmentUrl.payload,
      });
    }
  } else {
    attachmentUrl = values.sidemenu_icon;
  }
  const data = {
    sidemenu_title: values.sidemenu_title,
    sidemenu_icon:
      typeof values.sidemenu_icon !== "string"
        ? attachmentUrl.payload
        : attachmentUrl,
    feature_id: values.feature_display_name.id,
  };
  return data;
};

// Sidemenu validate

export const sidemenuValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("sidemenu_icon" in fieldValues) {
    temp.sidemenu_icon = "";
    if (!fieldValues.sidemenu_icon) {
      temp.sidemenu_icon = t("errors.icon_require");
    }
    if (fieldValues.sidemenu_icon.size > 1000000) {
      temp.sidemenu_icon = t("errors.small_icon_size");
      setValues({
        ...values,
        sidemenu_icon_preview: "",
        sidemenu_icon: "",
      });
    }
    if (
      fieldValues.sidemenu_icon?.name &&
      !fieldValues.sidemenu_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.sidemenu_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        sidemenu_icon_preview: "",
        sidemenu_icon: "",
      });
    }
  }

  if ("sidemenu_title" in fieldValues) {
    temp.sidemenu_title = fieldValues.sidemenu_title ? "" : t("errors.title");
  }

  if ("feature_display_name" in fieldValues) {
    temp.feature_display_name = fieldValues.feature_display_name
      ? ""
      : t("errors.type");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
