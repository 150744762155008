import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const ViewModalContact = ({ handleClose, show, contactDetail }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const sideHeading = localStorage.getItem("sidebar_component");

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {/* {`${sideHeading} > View`} */}
            {`${SidebarComponent()} > View`}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {contactDetail?.contact_first_name +
                    " " +
                    contactDetail?.contact_last_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.company")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {contactDetail?.company ? contactDetail?.company : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.department")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {contactDetail?.contact_post}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.phoneNumber")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {/* {"+" +
                    contactDetail?.contact_country_code +
                    " " +
                    contactDetail?.contact_phone_number} */}
                  {contactDetail?.contact_phone_number
                    ? contactDetail.contact_phone_number
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.email")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {contactDetail?.email ? contactDetail?.email : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("form.position")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {contactDetail?.contact_position
                    ? contactDetail?.contact_position
                    : "-"}
                </p>
              </div>
              {
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.contact_link")}
                  </h2>
                  {contactDetail?.contact_link &&
                  JSON.parse(contactDetail?.contact_link).length > 0 ? (
                    <div className="inquiry_complaint_detail_name">
                      {JSON.parse(contactDetail?.contact_link).map(
                        (contact) => {
                          return (
                            <div
                              className="contact_detail_link_container"
                              key={contact.contact_link_type}
                            >
                              <img
                                src={`${process.env.REACT_APP_FILE_BASE_URL}/${contact.contact_link_image}`}
                                alt="contact_link_pic"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "5px",
                                  marginRight: "0.75rem",
                                }}
                              />
                              :
                              {/* <span>{contact.contact_link_type + " "} : </span> */}
                              <span className="contact_link_name">
                                <a
                                  href={contact.contact_link_name}
                                  target="blank"
                                >
                                  {contact.contact_link_name}
                                </a>
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              }
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {/* {t("table.image")} */}
                  {t("contact.head") + " " + t("table.image")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  <img
                    className="view_page_img_icon_view"
                    style={{ width: "100px", height: "100px" }}
                    src={
                      contactDetail?.contact_profile_image
                        ? process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          contactDetail?.contact_profile_image
                        : process.env.REACT_APP_FILE_BASE_URL +
                          "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                    }
                    // src={`${process.env.REACT_APP_FILE_BASE_URL}/${contactDetail?.contact_profile_image}`}
                    alt="directory_image"
                    onClick={() => {
                      setImgZoom(true);
                      setImgSrc(
                        process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          contactDetail?.contact_profile_image
                      );
                    }}
                  />
                </p>
              </div>
              <div className="inquiry_complaint_detail_text contact_modal_time">
                <div>
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.createdAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {moment
                      .utc(contactDetail?.created_at, "YYYYMMDD HH:mm:ss")
                      .clone()
                      .tz(defaultTimeZone)
                      .format("DD/MM/YYYY, hh:mm A")}
                  </p>
                </div>
                <div>
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.updatedAt")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {contactDetail?.updated_at
                      ? moment
                          .utc(contactDetail?.updated_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")
                      : "-"}
                  </p>
                </div>
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                
              </div> */}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default ViewModalContact;
