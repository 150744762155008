import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { searchIcon } from "../../icons";
import DatePicker from "react-datepicker";

const AnnouncementFilters = ({ values, handleInputChange, onChange }) => {
  const { t } = useTranslation("common");

  return (
    <div className="user_data_search_select_box_row">
      {/* <div className="user_data_search">
        <Input
          inputClassName="user_data_search_input"
          type="search"
          placeholder="Search by User-type"
          id="user_type"
          name="user_type"
          value={values.user_type}
          onChange={handleInputChange}
        />
        <Button buttonClassName="user_data_search_btn" text={searchIcon} />
      </div> */}
      <div className="user_data_search h-35">
        <DatePicker
          isClearable
          selected={values.startDate}
          onChange={onChange}
          startDate={values.startDate}
          endDate={values.endDate}
          selectsRange
          dateFormat="dd/MM/yyyy"
          placeholderText={t("table.announcement_time")}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="user_data_search">
        <Input
          inputClassName="user_data_search_input"
          type="search"
          placeholder={t("filter.search_type")}
          id="announcement_type"
          name="announcement_type"
          value={values.announcement_type}
          onChange={handleInputChange}
        />
        <Button buttonClassName="user_data_search_btn" text={searchIcon} />
      </div>
      <div className="user_data_search">
        <Input
          inputClassName="user_data_search_input"
          type="search"
          placeholder={t("filter.search_title")}
          id="announcement_title"
          name="announcement_title"
          value={values.announcement_title}
          onChange={handleInputChange}
        />
        <Button buttonClassName="user_data_search_btn" text={searchIcon} />
      </div>
    </div>
  );
};

export default AnnouncementFilters;
