import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { useTranslation } from "react-i18next";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { updateJustCoConfig } from "../../store/slice/justCoSlice";
import { addIcon, editIcon } from "../../icons";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import TextArea from "../../component/Common/TextArea";

const JustCoSecretApplication = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { staticContent } = location.state;
  const [uploading, setUploading] = useState(false);

  let initialLogoPreview;
  if (staticContent?.logo) {
    initialLogoPreview = `${process.env.REACT_APP_FILE_BASE_URL}/${staticContent?.logo}`;
  }

  let initialBannerImagePreview;
  if (staticContent?.banner_image) {
    initialBannerImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${staticContent?.banner_image}`;
  }

  const initialFValues = {
    api_key: staticContent ? staticContent.api_key : "", //Api key
    api_client: staticContent ? staticContent.api_client : "", //Api Client
    client_code: staticContent ? staticContent.client_code : "",
    api_url: staticContent ? staticContent.api_url : "",
    enable_tnc:
      staticContent && staticContent.enable_tnc ? staticContent.enable_tnc : 0,
    logo: staticContent ? staticContent.logo : "",
    logo_preview: staticContent ? initialLogoPreview : "",
    banner_image: staticContent ? staticContent.banner_image : "",
    banner_image_preview: staticContent ? initialBannerImagePreview : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("api_key" in fieldValues) {
      temp.api_key = fieldValues.api_key ? "" : "Please Enter Api key !";
    }
    if ("api_client" in fieldValues) {
      temp.api_client = fieldValues.api_client
        ? ""
        : "Please Enter Client Key !";
    }
    if ("client_code" in fieldValues) {
      temp.client_code = fieldValues.client_code
        ? ""
        : "Please Enter Client code !";
    }
    if ("api_url" in fieldValues) {
      temp.api_url = fieldValues.api_url ? "" : "Please Enter Api Url !";
    }

    if ("logo" in fieldValues) {
      temp.logo = "";
      if (!fieldValues.logo) {
        temp.logo = t("errors.logo_require");
      }
      if (fieldValues.logo.size > 1000000) {
        temp.logo = t("errors.small_logo_size");
        setValues({
          ...values,
          logo_preview: "",
          logo: "",
        });
      }
      if (
        fieldValues.logo?.name &&
        !fieldValues.logo?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.logo = t("errors.logo_invalid");
        setValues({
          ...values,
          logo_preview: "",
          logo: "",
        });
      }
    }

    if ("banner_image" in fieldValues) {
      temp.banner_image = "";
      if (!fieldValues.banner_image) {
        temp.banner_image = t("errors.img_require");
      }
      if (fieldValues.banner_image.size > 1000000) {
        temp.banner_image = t("errors.small_image_size");
        setValues({
          ...values,
          banner_image_preview: "",
          banner_image: "",
        });
      }
      if (
        fieldValues.banner_image?.name &&
        !fieldValues.banner_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.banner_image = t("errors.img_invalid");
        setValues({
          ...values,
          banner_image_preview: "",
          banner_image: "",
        });
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const imageRemoveHandler = () => {
    setValues({ ...values, logo: "", logo_preview: "" });
  };

  const bannerImageRemoveHandler = () => {
    setValues({ ...values, banner_image: "", banner_image_preview: "" });
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  //SECRET APPLICATION KEY HANDLER
  const secretApplicationKeyHandler = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();

      let logoUrl;
      if (values.logo && typeof values.logo !== "string") {
        formData.append("file", values.logo);
        logoUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        logoUrl = values.logo;
      }

      let bannerImageUrl;
      if (values.banner_image && typeof values.banner_image !== "string") {
        formData.append("file", values.banner_image);
        bannerImageUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        bannerImageUrl = values.banner_image;
      }

      setUploading(false);
      const data = {
        api_key: values.api_key,
        api_client: values.api_client,
        client_code: values.client_code,
        api_url: values.api_url,
        enable_tnc: values.enable_tnc,
        logo: typeof values.logo !== "string" ? logoUrl.payload : logoUrl,
        banner_image:
          typeof values.banner_image !== "string"
            ? bannerImageUrl.payload
            : bannerImageUrl,
      };

      dispatch(
        updateJustCoConfig({
          data,
          id: staticContent.id,
          navigate,
          toast: t("toast.keys_update"),
        })
      );
    }
  };

  const onChangePrivacyAndTerms = () => {
    setValues({
      ...values,
      enable_tnc: values.enable_tnc === 0 ? 1 : 0,
    });
  };
  return (
    <div className="project_edit_main_content">
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("form.justCo_config")}
        </h2>
        <Link to={routes.justCo} className="comman_btn ml-auto secondary_btn">
          {t("common.back")}
        </Link>
      </div>
      <div className="create_from_row  settings_container">
        <div className="justco_secret_application_edit_form_row col_span_2">
          <div className="first_input">
            <TextArea
              className="create_from_input_content"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Api Url"
              label={t("announcement.api_url")}
              id="api_url"
              name="api_url"
              rows={1}
              value={values.api_url}
              onChange={handleInputChange}
              error={errors.api_url || ""}
            />
          </div>
          <div className="second_input">
            <Input
              className="create_from_input_content null"
              inputClassName="create_from_input"
              labelClassName="create_from_label required"
              label={t("announcement.project_number")}
              type="text"
              placeholder="Enter client code"
              id="client_code"
              name="client_code"
              value={values.client_code}
              onChange={handleInputChange}
            />
            {errors.client_code && (
              <span className="just_co_err_text">{errors.client_code}</span>
            )}
          </div>
        </div>
        <div>
          <Input
            className="create_from_input_content setting_token_input"
            inputClassName="create_from_input token_container"
            labelClassName="create_from_label required"
            label={t("announcement.secret_key")}
            type="text"
            placeholder="Enter Api Key"
            id="api_key"
            name="api_key"
            value={values.api_key}
            onChange={handleInputChange}
          />
          {errors.api_key && (
            <span className="just_co_err_text">{errors.api_key}</span>
          )}
        </div>
        <div>
          <Input
            className="create_from_input_content setting_token_input"
            inputClassName="create_from_input  token_container"
            labelClassName="create_from_label required"
            label={t("announcement.application_key")}
            type="text"
            placeholder="Enter Api Client"
            id="api_client"
            name="api_client"
            value={values.api_client}
            onChange={handleInputChange}
          />
          {errors.api_client && (
            <span className="just_co_err_text">{errors.api_client}</span>
          )}
        </div>

        <div className="col_span_2">
          <Input
            className="create_from_radio_checkbox_contentainer align-items-start"
            labelClassName="create_from_radio_label_role_enable_checkbox"
            inputClassName="create_from_radio_checkbox"
            type="checkbox"
            label={t("form.enable_pp_tc")}
            id={"enable_tnc"}
            name="enable_tnc"
            value={1}
            onChange={() => onChangePrivacyAndTerms()}
            checked={values.enable_tnc === 1}
          />
        </div>

        <div className="project_edit_detail_divider">
          <label className="create_from_label required">
            {t("directory.logo")}
          </label>
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`logo`}
              labelOtherProps={<span>{values.logo ? editIcon : addIcon}</span>}
              name="logo"
              accept="image/*"
              onChange={handleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={imagePreviewProps(
                values.logo,
                values.logo_preview,
                imageRemoveHandler
              )}
            />
          </div>
          <p className="create_project_review mb-0 block">
            {t("form.xsmall_size_desc")}
          </p>
          {errors.logo && <span className="err_text">{errors.logo}</span>}
        </div>

        <div className="project_edit_detail_divider">
          <label className="create_from_label required">
            {t("common.banner") + " " + t("table.image")}
          </label>
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`banner_image`}
              labelOtherProps={
                <span>{values.banner_image ? editIcon : addIcon}</span>
              }
              name="banner_image"
              accept="image/*"
              onChange={handleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={imagePreviewProps(
                values.banner_image,
                values.banner_image_preview,
                bannerImageRemoveHandler
              )}
            />
          </div>
          <p className="create_project_review mb-0 block">
            {t("form.small_size_desc")}
          </p>
          {errors.banner_image && (
            <span className="err_text">{errors.banner_image}</span>
          )}
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={secretApplicationKeyHandler}
        text={`${staticContent ? t("common.save") : t("common.create")}`}
      />
    </div>
  );
};

export default JustCoSecretApplication;
