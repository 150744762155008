import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Input from "../../component/Common/Input";
import { getService, updateService } from "../../store/slice/serviceSlice";
import { defaultTimeZone } from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";
import Loader from "../../component/Common/Loader";

const EditServiceModal = ({ handleClose, show, serviceDetail }) => {
  const { t } = useTranslation("common");
  const showHideClassName = show && "user_detail_modal_show";

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("service.service_Detail")}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("filter.search_service_type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_type_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.description")}
                </h2>
                <p className="inquiry_complaint_detail_name service_request_description">
                  {serviceDetail?.service_request_description}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {" "}
                  {t("table.location")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_location}
                </p>
              </div>
              {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("floor.level")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.level_name}
                </p>
              </div> */}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("floor.floor")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.service_floor}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.created_at
                    ? moment
                        .utc(serviceDetail?.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {serviceDetail?.updated_at
                    ? moment
                        .utc(serviceDetail?.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.status")}
                </h2>
                {serviceDetail?.service_request_status === "PENDING" ? (
                  <p className="inquiry_complaint_detail_name">Pending</p>
                ) : serviceDetail?.service_request_status === "IN_PROGRESS" ? (
                  <p className="inquiry_complaint_detail_name">In Progress</p>
                ) : serviceDetail?.service_request_status === "COMPLETED" ? (
                  <p className="inquiry_complaint_detail_name">Completed</p>
                ) : (
                  "-"
                )}
              </div>
              {serviceDetail?.service_request_attachment ? (
                <>
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("service.attachment")}
                    </h2>
                    <p className="inquiry_complaint_detail_name">
                      <img
                        className="view_page_img_icon_view"
                        style={{
                          width: "100px",
                          height: "100px",
                          maxWidth: "min-content",
                          marginLeft: "18px",
                        }}
                        src={
                          serviceDetail?.service_request_attachment
                            ? process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              serviceDetail?.service_request_attachment
                            : process.env.REACT_APP_FILE_BASE_URL +
                              "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                        }
                        alt="service_image"
                        onClick={() => {
                          setImgZoom(true);
                          setImgSrc(
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              serviceDetail?.service_request_attachment
                          );
                        }}
                      />
                    </p>
                  </div>
                  <div></div>
                </>
              ) : (
                <>
                  <div></div>
                  <div></div>
                </>
              )}
              {serviceDetail?.in_progress_image && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.response_img")}{" "}
                    {`(${t("service.in_progress")})`}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    <img
                      className="view_page_img_icon_view"
                      style={{
                        width: "100px",
                        height: "100px",
                        maxWidth: "min-content",
                        marginLeft: "18px",
                      }}
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${serviceDetail?.in_progress_image}`}
                      alt="in_progress_image"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            serviceDetail?.in_progress_image
                        );
                      }}
                    />
                  </p>
                </div>
              )}
              {serviceDetail?.in_progress_remark ? (
                <>
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("announcement.Remarks")}{" "}
                      {`(${t("service.in_progress")})`}
                    </h2>
                    <p className="inquiry_complaint_detail_name service_request_description">
                      {serviceDetail?.in_progress_remark}
                    </p>
                  </div>
                  <div></div>
                </>
              ) : (
                <>
                  <div></div>
                  <div></div>
                </>
              )}
              {serviceDetail?.service_image && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.response_img")}{" "}
                    {`(${t("service.completed")})`}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    <img
                      className="view_page_img_icon_view"
                      style={{
                        width: "100px",
                        height: "100px",
                        maxWidth: "min-content",
                        marginLeft: "18px",
                      }}
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${serviceDetail?.service_image}`}
                      alt="service_image"
                      onClick={() => {
                        setImgZoom(true);
                        setImgSrc(
                          process.env.REACT_APP_FILE_BASE_URL +
                            "/" +
                            serviceDetail?.service_image
                        );
                      }}
                    />
                  </p>
                </div>
              )}
              {serviceDetail?.service_remark && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("announcement.Remarks")} {`(${t("service.completed")})`}
                  </h2>
                  <p className="inquiry_complaint_detail_name service_request_description">
                    {serviceDetail?.service_remark}
                  </p>
                </div>
              )}
              {/* <div className="inquiry_complaint_detail_text">
                <div className="create_from_row radio_update_service">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("service.status")}
                  </h2>
                  <div className="create_from_input_content col_span_2">
                    <Input
                      className="create_from_radio_checkbox_content"
                      labelClassName="create_from_radio_label"
                      inputClassName="create_from_radio_checkbox input_none"
                      errorClassName="err_text"
                      type="radio"
                      label={t("service.pending")}
                      id="PENDING"
                      name="service_request_status"
                      value="PENDING"
                      onChange={handleInputChange}
                      checked={values.service_request_status === "PENDING"}
                    />
                    <Input
                      className="create_from_radio_checkbox_content"
                      labelClassName="create_from_radio_label"
                      inputClassName="create_from_radio_checkbox input_none"
                      errorClassName="err_text"
                      type="radio"
                      label={t("service.in_progress")}
                      id="IN_PROGRESS"
                      name="service_request_status"
                      value="IN_PROGRESS"
                      onChange={handleInputChange}
                      checked={values.service_request_status === "IN_PROGRESS"}
                    />
                    <Input
                      className="create_from_radio_checkbox_content"
                      labelClassName="create_from_radio_label"
                      inputClassName="create_from_radio_checkbox input_none"
                      errorClassName="err_text"
                      type="radio"
                      label={t("service.completed")}
                      id="COMPLETED"
                      name="service_request_status"
                      value="COMPLETED"
                      onChange={handleInputChange}
                      checked={values.service_request_status === "COMPLETED"}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
          >
            {t("common.close")}
          </button>
          {/* {serviceDetail?.service_request_status !==
            values.service_request_status && (
            <button
              type="button"
              onClick={updateServiceHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.update")}
            </button>
          )} */}
        </div>
      </section>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default EditServiceModal;
