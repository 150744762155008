import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getCountryCode,
  updateCountryCode,
} from "../../../store/slice/countryCodeSlice";

const DeleteCountryCode = ({
  show,
  deleteModalId,
  setDeleteModal,
  setDeleteModalId,
  handleClose,
  page_number,
  values,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const deleteCountryCodeHandler = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateCountryCode({
        data,
        id: deleteModalId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getCountryCode({
                start: (page_number - 1) * 10,
              })
            );
            setDeleteModal(false);
            setDeleteModalId("");
          }
        },
        toast: "Country code deleted successfully",
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("modal.delete.deleteCode")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.deleteUserCode")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteCountryCodeHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteCountryCode;
