import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <main
      className={`
      zu_privacy_terms_page`}
    >
      <div className="zu_privacy_terms_banner_img_text">
        <div className="zu_privacy_terms_banner_text">
          <h2 className="zu_privacy_terms_banner_heading">Privacy Policy</h2>
          <p className="zu_privacy_terms_banner_paragraph">
            The boring, but important stuff
          </p>
          <span className="zu_privacy_terms_banner_updat_date">
            Updated May 2023
          </span>
        </div>
      </div>
      <section className="zu_privacy_terms_section">
        <div className="container">
          <div className="zu_privacy_terms_content">
            <h2 className="zu_privacy_terms_common_heading mb-4">
              Privacy Policy
            </h2>
            <p className="zu_privacy_terms_common_paragraph mb-4">
              This is the privacy policy of Onecity Pty Ltd, ABN 18 150 556 102
              referred to as ‘we’, ‘us’ or ‘our’. The purpose of this privacy
              policy is to provide information about how we deal with and manage
              personal information.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicyPage;
