import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  getRoleFeaturesData,
  getRoles,
} from "../../store/slice/rolemanagementSlice";
import CreateFeature from "../Role-Management/CreateFeature";
import EditFeature from "../Role-Management/EditFeature";

const ViewRoleFeature = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { role_name } = location.state;
  const { id } = params;

  const [addFormData, setAddFormData] = useState(false);
  const [editDataState, setEditDataState] = useState("");

  useEffect(() => {
    dispatch(getRoleFeaturesData({ id }));
  }, [dispatch, id]);

  const initialFValues = {
    role_feature: "",
    role_feature_err: "",
    feature_id: id,
    is_create: editDataState
      ? editDataState.is_create === 0
        ? false
        : true
      : true,
    is_delete: editDataState
      ? editDataState.is_delete === 0
        ? false
        : true
      : true,
    is_read: editDataState
      ? editDataState.is_read === 0
        ? false
        : true
      : true,
    is_update: editDataState
      ? editDataState.is_update === 0
        ? false
        : true
      : true,
    editable: false,
    editableId: "",
  };

  const roleFeatureHandler = () => {
    dispatch(getRoles({}));
    setAddFormData(true);
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (values.role_feature) {
      setValues({ ...values, role_feature_err: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.role_feature]);

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">Feature : {role_name}</h2>
        <div className="center_back_btn_row">
          {!addFormData && (
            <button className="comman_btn ml-auto" onClick={roleFeatureHandler}>
              Add Role Feature
            </button>
          )}
          <Link to={routes.features} className="comman_btn ml-auto">
            Back
          </Link>
        </div>
      </div>
      <div className="project_edit_main_content center-container">
        {addFormData && (
          <CreateFeature
            values={values}
            handleInputChange={handleInputChange}
            setValues={setValues}
            setAddFormData={setAddFormData}
            id={id}
          />
        )}

        <EditFeature
          values={values}
          setValues={setValues}
          editDataState={editDataState}
          setEditDataState={setEditDataState}
        />
      </div>
    </>
  );
};

export default ViewRoleFeature;
