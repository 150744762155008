import React from "react";
import { useTranslation } from "react-i18next";
import "../../css/component.css";

const Footer = () => {
  const { t } = useTranslation("common");

  let year = new Date().getFullYear().toString().substr(-2);

  return (
    <footer className="footer_section">
      <p className="footer_copyright_text">
        {t("footer.text")}
        {` 2022-${year} © One City Centre `}
        {t("footer.text_two")}
      </p>
      <p className="footer_version_text">{t("footer.version")}</p>
    </footer>
  );
};

export default Footer;
