import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { routes } from "../../constants";
import Select from "react-select";
import TextArea from "../../component/Common/TextArea";
import { useForm } from "../../hooks/useForm";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import {
  createNotification,
  getNotificationType,
  updateNotification,
} from "../../store/slice/notificationSlice";
import Button from "../../component/Common/Button";
import Loader from "../../component/Common/Loader";
import { useCallback } from "react";
import NotificationTypeForm from "./NotificationType";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SidebarComponent } from "../../helpers/commonFunction";

const CreateNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.menu;
  const page = location?.state?.page_number;
  const value = location?.state?.value;

  const sideHeading = localStorage.getItem("sidebar_component");
  const [apiErr, setApiErr] = useState(false);

  const { t } = useTranslation("common");

  const [updateState, setUpdateState] = useState();

  useEffect(() => {
    if (updateState) {
      setValues({
        ...values,
        notificationType_name: updateState.notification_type_name
          ? updateState.notification_type_name
          : "",
        notificationType_icon: updateState?.notification_type_image
          ? updateState.notification_type_image
          : "",
        notificationType_icon_preview: updateState.notification_type_image
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${updateState.notification_type_image}`
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setErrors({
      ...errors,
      notificationType_name: "",
      notificationType_icon: "",
    });
    dispatch(getRoles({ appType: 1 }));
    dispatch(
      getNotificationType({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [dispatch]);

  const notificationTypes = useSelector(
    (state) => state.notification.notificationTypeList
  );
  const createNotificationLoader = useSelector(
    (state) => state.notification.loader
  );

  const notificationListOptions = notificationTypes?.map((item) => {
    return {
      value: item?.id,
      label: item?.notification_type_name,
    };
  });

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  const initialFValues = useCallback(() => {
    return getInitialNotificationState(editComponent);
  }, [editComponent, roleList]);

  const getInitialNotificationState = (editComponent) => {
    return {
      notification_title: editComponent
        ? editComponent?.notification_title
        : "",
      notification_description: editComponent
        ? editComponent?.notification_description
        : "",
      notification_type: editComponent
        ? {
            label: editComponent?.notification_type_name,
            value: editComponent?.notification_type_id,
          }
        : "",
      user_type:
        editComponent?.user_role && roleList
          ? JSON.parse(editComponent?.user_role).length === roleList?.length
            ? ["ALL"]
            : JSON.parse(editComponent?.user_role)
          : [],
      showNotificationTypeForm: false,
      notificationType_name: "",
      notificationType_icon: "",
      notificationType_icon_preview: "",
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("notification_title" in fieldValues) {
      temp.notification_title = fieldValues.notification_title
        ? ""
        : t("errors.title");
    }
    if ("notification_description" in fieldValues) {
      temp.notification_description = fieldValues.notification_description
        ? ""
        : t("errors.description");
    }
    if ("notification_type" in fieldValues) {
      temp.notification_type = fieldValues.notification_type
        ? ""
        : t("errors.type");
    }
    if ("user_type" in fieldValues) {
      temp.user_type =
        fieldValues.user_type.length > 0 ? "" : t("errors.user_role");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    roleList
  );

  const createTypeHandler = () => {
    setValues({ ...values, showNotificationTypeForm: true });
  };

  const userAllTypeHandler = () => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeData = [...values.user_type];
    if (tempUserTypeData.includes("ALL")) {
      const index = tempUserTypeData.findIndex((obj) => obj === "ALL");
      if (index > -1) {
        tempUserTypeData.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeData });
    } else {
      setValues({ ...values, user_type: ["ALL"] });
    }
  };

  const userTypeHandler = (e, item) => {
    setErrors({ ...errors, user_type: "" });
    let tempUserTypeHandler = [...values.user_type];
    if (tempUserTypeHandler.includes(item.id)) {
      let index = tempUserTypeHandler.findIndex((obj) => obj === item.id);
      if (index > -1) {
        tempUserTypeHandler.splice(index, 1);
      }
      setValues({ ...values, user_type: tempUserTypeHandler });
    } else {
      if (tempUserTypeHandler.includes("ALL")) {
        let index = tempUserTypeHandler.findIndex((obj) => obj === "ALL");
        if (index > -1) {
          tempUserTypeHandler.splice(index, 1);
        }
        setValues({
          ...values,
          user_type: [...tempUserTypeHandler, item.id],
        });
      } else {
        setValues({
          ...values,
          user_type: [...values.user_type, item.id],
        });
      }
    }
  };

  const createNotificationHandler = (action) => {
    if (validate()) {
      const data = {
        notification_title: values.notification_title,
        notification_description: values.notification_description,
        notification_type_id: values.notification_type.value,
        user_role: values.user_type.includes("ALL")
          ? roleList.map((item) => item.id)
          : values.user_type,
      };
      if (!editComponent) {
        data.send_now = action === "save" ? "0" : "1";
      }
      editComponent
        ? dispatch(
            updateNotification({
              data,
              id: editComponent?.id,
              navigate,
              value,
              toast: t("toast.update_noti"),
              page,
            })
          )
        : dispatch(
            createNotification({
              data,
              navigate,
              toast: t("toast.create_noti"),
            })
          );
    }
  };

  if (createNotificationLoader) {
    return <Loader />;
  }

  return (
    <>
      {values?.showNotificationTypeForm ? (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <button
              onClick={() => {
                setValues({
                  ...values,
                  showNotificationTypeForm: false,
                  notificationType_name: "",
                  notificationType_icon: "",
                  notificationType_icon_preview: "",
                });
                setErrors({
                  ...errors,
                  notificationType_name: "",
                  notificationType_icon: "",
                });
                setUpdateState("");
              }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </button>
          </div>
          <div className="project_edit_main_content">
            <NotificationTypeForm
              updateState={updateState}
              setUpdateState={setUpdateState}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              handleInputChange={handleInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="comman_btn_container center_back_btn announcement_page-container">
            <h2 className="mobile_configuration_heading">
              {/* {editComponent
                ? `${sideHeading} > Edit`
                : `${sideHeading} > Create`} */}
              {editComponent
                ? `${SidebarComponent()} > Edit`
                : `${SidebarComponent()} > Create`}
            </h2>
            <Link
              to={`${routes.notifications}/page/${page}`}
              state={{ page, value }}
              className="comman_btn ml-auto secondary_btn"
            >
              {t("common.back")}
            </Link>
          </div>
          <div className="project_edit_main_content">
            <div className="create_from_row">
              <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.announcement_title_place")}
                label={t("announcement.title")}
                id="notification_title"
                name="notification_title"
                value={values?.notification_title}
                onChange={handleInputChange}
                error={errors?.notification_title || ""}
              />
              <div className="project_edit_feature_input m-0">
                <div className="btn_container">
                  <label className="create_from_label required">
                    {t("table.type")}
                  </label>
                  <button
                    className="create_link_btn create_btn_notification"
                    onClick={createTypeHandler}
                  >
                    {t("notification.create_notification_type")}
                  </button>
                </div>
                <Select
                  placeholder={t("notification.notification_type_place")}
                  value={values?.notification_type}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="notification_type"
                  options={notificationListOptions}
                  onChange={(e) => handleInputChange(e, "notification_type")}
                />
                {errors?.notification_type && (
                  <span className="err_text">{errors?.notification_type}</span>
                )}
              </div>
              <TextArea
                className="create_from_input_content "
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.description_place")}
                label={t("announcement.description")}
                id="notification_description"
                name="notification_description"
                rows={2}
                value={values?.notification_description}
                onChange={handleInputChange}
                error={errors?.notification_description || ""}
              />
              <div
                className={`${
                  editComponent && "disable_class"
                } create_from_input_content`}
              >
                <label className="create_from_label required">
                  {t("announcement.user_role")}{" "}
                </label>
                <Input
                  className="create_from_radio_checkbox_contentainer"
                  labelClassName="create_from_radio_label_role"
                  inputClassName="create_from_radio_checkbox"
                  type="checkbox"
                  label={"All"}
                  id={"ALL"}
                  name="user_type"
                  value={"ALL"}
                  onChange={userAllTypeHandler}
                  checked={values?.user_type?.includes("ALL")}
                />
                {roleList?.map((type, i) => {
                  return (
                    <Input
                      className="create_from_radio_checkbox_contentainer"
                      // className={`create_from_radio_checkbox_contentainer ${
                      //   values?.user_type?.includes("ALL") &&
                      //   "disable_radio_btns"
                      // }`}
                      key={i}
                      labelClassName="create_from_radio_label_role"
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={type.role_display_name}
                      id={type.role_display_name}
                      name="user_type"
                      value={type.id}
                      onChange={(e) => userTypeHandler(e, type)}
                      checked={values?.user_type?.includes(type.id)}
                      // disabled={values?.user_type?.includes("ALL")}
                    />
                  );
                })}
                {errors?.user_type && (
                  <span className="err_text">{errors?.user_type}</span>
                )}
              </div>
            </div>
            <div className="notification_btn_container">
              <Button
                className="create_from_bottom_btn send_notification-btn"
                buttonClassName="comman_btn mt-20"
                onClick={() => createNotificationHandler("save")}
                text={`${
                  editComponent ? t("common.save") : t("common.save_draft")
                }`}
              />
              {!editComponent && (
                <Button
                  className="create_from_bottom_btn send_notification-btn ml-20"
                  buttonClassName="comman_btn mt-20"
                  onClick={() => createNotificationHandler("send")}
                  text={t("common.send_now")}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateNotification;
