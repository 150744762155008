import axios from "axios";
import moment from "moment";

export const authHeaders = () => {
  const language = localStorage.getItem("language") || "en";

  const authToken =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;
  return {
    headers: {
      lang: language,
      Authorization: `bearer ${authToken}`,
      time: moment.utc(moment(new Date()).utc()).format("YYYY-MM-DD HH:mm:ss"),
    },
  };
};

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export default Axios;
