import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, deleteIcon, editIcon } from "../../icons";
import {
  createFeedbackType,
  getFeedbackTypes,
  updateFeedbackType,
} from "../../store/slice/feedbackSlice";
import { fileUpload } from "../../store/slice/fileUploadSlice";

const FeedbackTypeForm = ({
  updateState,
  values,
  setValues,
  setErrors,
  handleInputChange,
  errors,
  setUpdateState,
  validate,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(false);

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.feedbackType_name && values.feedbackType_icon) {
      setErrors({
        ...errors,
        feedbackType_name: "",
        feedbackType_icon: "",
      });
    } else if (values.feedbackType_name) {
      setErrors({ ...errors, feedbackType_name: "" });
    } else if (values.feedbackType_icon) {
      setErrors({ ...errors, feedbackType_icon: "" });
    }
  }, [values.feedbackType_icon, values.feedbackType_name]);

  const feedbackTypeList = useSelector(
    (state) => state.feedback.feedbackTypesList
  );

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      feedbackType_icon: "",
      feedbackType_icon_preview: "",
    });
  };

  const createFeedbackTypeHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.feedbackType_icon &&
        typeof values.feedbackType_icon !== "string"
      ) {
        formData.append("file", values.feedbackType_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            feedbackType_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.feedbackType_icon;
      }
      const data = {
        feedback_type_name: values.feedbackType_name,
        feedback_type_image:
          typeof values.feedbackType_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
      };
      updateState
        ? dispatch(
            updateFeedbackType({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getFeedbackTypes());
                  setValues({
                    ...values,
                    feedbackType_name: "",
                    feedbackType_icon: "",
                    feedbackType_icon_preview: "",
                  });
                  ref.current = false;
                  setUpdateState("");
                }
              },
              toast: t("toast.update_feedbacktype"),
            })
          )
        : dispatch(
            createFeedbackType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getFeedbackTypes());
                  setValues({
                    ...values,
                    feedbackType_name: "",
                    feedbackType_icon: "",
                    feedbackType_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_feedbacktype"),
            })
          );
    }
  };

  const deleteModalHandler = (menu) => {
    setValues({
      ...values,
      deleteTypeModal: true,
      deleteModalId: menu.id,
    });
  };

  return (
    <>
      <div className="create_from_row servicetype_form-container">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {/* {updateState
              ? `${sideHeading} Type > Edit`
              : `${sideHeading} Type > Create`} */}
            {updateState
              ? `${SidebarComponent()} Type > Edit`
              : `${SidebarComponent()} Type > Create`}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`feedbackType_icon`}
                labelOtherProps={
                  <span>{!values.feedbackType_icon ? addIcon : editIcon}</span>
                }
                name="feedbackType_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.feedbackType_icon,
                  values.feedbackType_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.feedbackType_icon && (
              <span className="err_text">{errors?.feedbackType_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.name_place")}
              label={t("form.name")}
              id="feedbackType_name"
              name="feedbackType_name"
              value={values.feedbackType_name}
              onChange={handleInputChange}
              error={errors?.feedbackType_name || ""}
            />
          </div>
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {feedbackTypeList?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.feedback_type_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.feedback_type_image
                        }
                        alt="feedback_type_name"
                        className="custom_data_table_img"
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                        <div className="tooltip">
                          <button
                            className="custom_data_table_view_edit_item_btn"
                            onClick={() => deleteModalHandler(menu)}
                            // onClick={() => deleteFeedbackTypeHandler(menu)}
                          >
                            {deleteIcon}
                          </button>
                          <span className="tooltiptext">Delete</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {fileUploadLoader ? (
        <div className="project_submit_bottom_btn center_back_btn mw-un">
          <span className="comman_btn">Loading...</span>
        </div>
      ) : (
        <Button
          className="project_submit_bottom_btn center_back_btn mw-un"
          buttonClassName="comman_btn"
          onClick={createFeedbackTypeHandler}
          text={`${updateState ? t("common.save") : t("common.create")}`}
        />
      )}
    </>
  );
};

export default FeedbackTypeForm;
