import React from "react";

export default function Button(props) {
  const {
    text,
    onClick,
    className,
    buttonClassName,
    other,
    type,
    disabled,
    id,
  } = props;

  return (
    <div className={className}>
      <button
        onClick={onClick}
        className={buttonClassName}
        type={type}
        disabled={disabled}
        id={id}
      >
        {other && other}
        {text}
      </button>
    </div>
  );
}
