import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

const SortableCountries = (props) => {
  const { item } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transition,
    zIndex: isDragging ? "100" : "auto",
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      className="country_container"
      key={item.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {`${item.country_name} ${item.country_code}`}
    </div>
  );
};

export default SortableCountries;
