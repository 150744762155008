import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, deleteIcon, editIcon } from "../../icons";
import { contactLinkValidate } from "../../component/Common/PageComponent/ContactComponents";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import {
  createContactLink,
  getContactLinkType,
  updateContactLink,
} from "../../store/slice/contactSlice";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const ContactLink = ({
  updateState,
  setUpdateState,
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(false);

  const sideHeading = localStorage.getItem("sidebar_component");

  const { t } = useTranslation("common");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const contactLinks = useSelector((state) => state.contact.contactLinkList);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return contactLinkValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors,
      t
    );
  };

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.contactLink_name && values.contactLink_icon) {
      setErrors({
        ...errors,
        contactLink_name: "",
        contactLink_icon: "",
      });
    } else if (values.contactLink_name) {
      setErrors({ ...errors, contactLink_name: "" });
    } else if (values.contactLink_icon) {
      setErrors({ ...errors, contactLink_icon: "" });
    }
  }, [values.contactLink_icon, values.contactLink_name]);

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      contactLink_icon: "",
      contactLink_icon_preview: "",
    });
  };

  const deleteModalHandler = (menu) => {
    setValues({
      ...values,
      deleteModal: true,
      deleteModalId: menu.id,
    });
  };

  const createLinkHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.contactLink_icon &&
        typeof values.contactLink_icon !== "string"
      ) {
        formData.append("file", values.contactLink_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            contactLink_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.contactLink_icon;
      }
      const data = {
        contact_link_type: values.contactLink_name,
        contact_link_image:
          typeof values.contactLink_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
      };

      updateState
        ? dispatch(
            updateContactLink({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getContactLinkType());
                  setValues({
                    ...values,
                    contactLink_name: "",
                    contactLink_icon: "",
                    contactLink_icon_preview: "",
                  });
                  ref.current = false;
                  setUpdateState("");
                }
              },
              toast: t("toast.update_contactlink"),
            })
          )
        : dispatch(
            createContactLink({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getContactLinkType());
                  setValues({
                    ...values,
                    contactLink_name: "",
                    contactLink_icon: "",
                    contactLink_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_contactlink"),
            })
          );
    }
  };

  return (
    <>
      <div className="create_from_row">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {/* {updateState
              ? `${sideHeading} Link Type > Edit`
              : `${sideHeading} Link Type > Create`} */}
            {updateState
              ? `${SidebarComponent()} Link Type > Edit`
              : `${SidebarComponent()} Link Type > Create`}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`contactLink_icon`}
                labelOtherProps={
                  <span>{!values.contactLink_icon ? addIcon : editIcon}</span>
                }
                name="contactLink_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.contactLink_icon,
                  values.contactLink_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.contactLink_icon && (
              <span className="err_text">{errors?.contactLink_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.linkNameTypePlace")}
              label={t("form.linkNameType")}
              id="contactLink_name"
              name="contactLink_name"
              value={values.contactLink_name}
              onChange={handleInputChange}
              error={errors?.contactLink_name || ""}
            />
          </div>
          {fileUploadLoader ? (
            <div className="project_submit_bottom_btn center_back_btn">
              <span className="comman_btn">Loading...</span>
            </div>
          ) : (
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createLinkHandler}
              text={`${updateState ? t("common.save") : t("common.create")}`}
            />
          )}
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {contactLinks?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.contact_link_type}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.contact_link_image
                        }
                        alt="contactlink_icon"
                        className="custom_data_table_img"
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                        <div className="tooltip">
                          <button
                            className="custom_data_table_view_edit_item_btn"
                            onClick={() => deleteModalHandler(menu)}
                            // onClick={() => deleteContactLinkHandler(menu)}
                          >
                            {deleteIcon}
                          </button>
                          <span className="tooltiptext">Delete</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ContactLink;
