import React, { useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../constants";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CountryData from "../../../helpers/countryData.json";
import { useForm } from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../component/Common/Button";
import {
  createCountryCode,
  updateCountryCode,
} from "../../../store/slice/countryCodeSlice";
import Loader from "../../../component/Common/Loader";
import Input from "../../../component/Common/Input";

const CreateCountryCode = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const editCountryCode = location?.state?.item;
  const valuePage = location?.state?.page_number;

  const [loading, setLoading] = useState(false);
  const [countryErr, setCountryErr] = useState();

  const initialFValues = {
    country_name: editCountryCode
      ? {
          value:
            editCountryCode?.country_name + " " + editCountryCode?.country_code,
          label:
            editCountryCode?.country_name + " " + editCountryCode?.country_code,
        }
      : "",
    country_name_separate: editCountryCode?.country_name
      ? editCountryCode?.country_name
      : "",
    country_code_separate: editCountryCode?.country_code
      ? editCountryCode?.country_code
      : "",
    order: editCountryCode?.preference ? editCountryCode?.preference : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    // Validate country code separate
    if ("country_code_separate" in fieldValues) {
      let countryCode = fieldValues.country_code_separate
        .replace(/\s/g, "")
        .trim();
      if (!countryCode.startsWith("+")) {
        countryCode = "+" + countryCode;
      }
      if (!/^[0-9+]+$/.test(countryCode)) {
        temp.country_code_separate = "Invalid country code";
      } else if (countryCode.length > 6) {
        temp.country_code_separate = "It should not be more than 5 digits";
      } else {
        temp.country_code_separate = "";
      }
    } else {
      temp.country_code_separate = "";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const countryListOptions = CountryData?.map((item) => {
    return {
      value: item.label + " " + item.value,
      label: item.label + " " + item.value,
      name: item.label,
      code: item.value,
    };
  });

  //CREATE AND EDIT COUNTRY CODE HANDLER
  const createCountryCodeHandler = () => {
    let data;
    if (
      !values.country_name &&
      !values.country_name_separate &&
      !values.country_code_separate
    ) {
      setCountryErr("Country Name and Code are required!");
      return;
    }

    // Clear the error message if at least one of the values is present
    setCountryErr("");

    if (values.country_name_separate && values.country_code_separate) {
      setCountryErr("");
      data = {
        country_name: values.country_name_separate
          ? values.country_name_separate.trim()
          : "",
        country_code: values.country_code_separate
          ? values.country_code_separate.replace(/\s/g, "")
          : null,
        // preference: values.order ? Number(values.order) : null,
      };
    } else {
      data = {
        country_name: values.country_name?.name
          ? values.country_name?.name.trim()
          : "",
        country_code: values.country_name?.code
          ? values.country_name?.code.replace(/\s/g, "")
          : null,
        // preference: values.order ? Number(values.order) : null,
      };
    }

    if (editCountryCode) {
      dispatch(
        updateCountryCode({
          id: editCountryCode?.id,
          data,
          navigate,
          toast: "Country code updated successfully",
          setLoading,
          valuePage,
        })
      );
    } else {
      dispatch(
        createCountryCode({
          data,
          navigate,
          toast: "Country code created successfully",
          setLoading,
        })
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {editCountryCode ? `Country Code > Edit` : `Country Code > Create`}
        </h2>
        <Link
          to={`${routes.countryCodes}/page/${valuePage}`}
          state={{
            valuePage,
            // value
          }}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div>
            <label className="create_from_label">
              {t("table.select_country")}
            </label>
            <Select
              placeholder={t("table.select_country_name")}
              value={values?.country_name}
              className={`basic-multi-select visitor_label_tenant`}
              classNamePrefix="select"
              name="country_name"
              options={countryListOptions}
              onChange={(e) => {
                handleInputChange(e, "country_name");
                setValues({
                  ...values,
                  country_name: {
                    value: e.label,
                    label: e.label,
                  },
                  country_name_separate: e.name,
                  country_code_separate: e.code,
                });
                setCountryErr("");
              }}
            />
            {/* {errors?.country_name && (
              <span className="err_text">{errors?.country_name}</span>
            )} */}
          </div>
          <div></div>
          <div className="col_span_2">
            <label className="country_code_or_label">{t("common.or")}</label>
          </div>

          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("common.enter") + " " + t("table.country_name")}
            label={t("table.country_name")}
            id="country_name_separate"
            name="country_name_separate"
            value={
              values?.country_name?.name
                ? values?.country_name?.name
                : values?.country_name_separate
            }
            onChange={(e) => {
              handleInputChange(e);
              setCountryErr("");
            }}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("common.enter") + " " + t("table.country_code")}
            label={t("table.country_code")}
            id="country_code_separate"
            name="country_code_separate"
            value={
              values?.country_name?.code
                ? values?.country_name?.code
                : values?.country_code_separate
            }
            // onChange={handleInputChange}
            onChange={(e) => {
              handleInputChange(e);
              setCountryErr("");
            }}
          />
          {errors?.country_code_separate && (
            <span className="err_text">{errors?.country_code_separate}</span>
          )}
          {countryErr && <span className="err_text">{countryErr}</span>}

          {/* <hr className="container_bottom_dashed_line col_span_2" /> */}
          {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Order for Country Code"
            label={t("common.Select") + " " + t("common.Order")}
            id="order"
            name="order"
            value={values?.order}
            onChange={handleInputChange}
            // onChange={(e) => {
            //   handleInputChange(e);
            //   setCountryErr("");
            // }}
          /> */}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName={`comman_btn `}
          onClick={createCountryCodeHandler}
          text={`${editCountryCode ? t("common.save") : t("common.create")}`}
        />
      </div>
    </>
  );
};

export default CreateCountryCode;
