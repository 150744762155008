import { fileUpload } from "../../../store/slice/fileUploadSlice";

// Update Amenity Delete/Hide
export const dataForUpdate = (amenity, action) => {
  let data;
  data =
    action === "delete"
      ? {
          amenity_name: amenity.amenity_name,
          amenity_icon: amenity.amenity_icon,
          delete_status: "1",
        }
      : {
          amenity_name: amenity.amenity_name,
          amenity_icon: amenity.amenity_icon,
          hidden: amenity.hidden === "0" ? "1" : "0",
        };
  return data;
};

// Create Amenity
export const createAmenityData = async (dispatch, values, setValues) => {
  const formData = new FormData();
  let attachmentUrl;
  if (values.amenity_icon && typeof values.amenity_icon !== "string") {
    formData.append("file", values.amenity_icon);
    attachmentUrl = await dispatch(fileUpload(formData));
    if (attachmentUrl && attachmentUrl.payload) {
      setValues({
        ...values,
        amenity_icon: attachmentUrl.payload,
      });
    }
    formData.delete("file");
  } else {
    attachmentUrl = values.amenity_icon;
  }
  const data = {
    amenity_name: values.amenity_name,
    amenity_icon:
      typeof values.amenity_icon !== "string"
        ? attachmentUrl.payload
        : attachmentUrl,
    feature_id: values.feature_display_name.id,
  };
  return data;
};

// Amenity validate
export const amenityValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("amenity_icon" in fieldValues) {
    temp.amenity_icon = "";
    if (!fieldValues.amenity_icon) {
      temp.amenity_icon = t("errors.icon_require");
    }
    if (fieldValues.amenity_icon.size > 1000000) {
      temp.amenity_icon = t("errors.small_icon_size");
      setValues({
        ...values,
        amenity_icon_preview: "",
        amenity_icon: "",
      });
    }
    if (
      fieldValues.amenity_icon?.name &&
      !fieldValues.amenity_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.amenity_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        amenity_icon_preview: "",
        amenity_icon: "",
      });
    }
  }

  if ("amenity_name" in fieldValues) {
    temp.amenity_name = fieldValues.amenity_name ? "" : t("errors.name");
  }

  if ("feature_display_name" in fieldValues) {
    temp.feature_display_name = fieldValues.feature_display_name
      ? ""
      : t("errors.feature_type");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
