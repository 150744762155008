import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import { deleteIcon, editIcon } from "../../icons";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import { getPurpose, updatePurpose } from "../../store/slice/purposeSlice";
import CreatePurpose from "./CreatePurpose";
import { toast } from "react-toastify";
import DeletePurposeModal from "./DeletePurposeModal";

const Purpose = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const purposeList = useSelector((state) => state.purpose.purposeList?.data);
  const purposeListLoader = useSelector((state) => state.purpose.loader);

  const sideHeading = localStorage.getItem("sidebar_component");

  const [deleteModal, setDeleteModal] = useState(false);
  const [purposeForm, setPurposeForm] = useState(false);
  const [deletePurposeId, setDeletePurposeId] = useState("");
  const [purposeId, setPurposeId] = useState();
  const [apiErr, setApiErr] = useState(false);

  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");

  useEffect(() => {
    dispatch(
      getPurpose({
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
  }, [dispatch]);

  if (purposeListLoader) {
    return <Loader />;
  }

  const openCreatePurposeModal = () => {
    setPurposeForm(true);
  };

  const openEditPurposeModal = (menu) => {
    setPurposeId(menu.id);
    setTitle(menu.title);
    setPurposeForm(true);
  };

  const activePurposeHandler = (menu) => {
    const data = { is_active: menu.is_active === "1" ? "0" : "1" };
    dispatch(
      updatePurpose({
        data,
        id: menu.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              `${t("purpose.head")} ${
                menu.is_active === "1"
                  ? t("purpose.deactivated")
                  : t("purpose.activated")
              } ${t("purpose.successfully")}`,
              {
                autoClose: 3000,
              }
            );
            dispatch(getPurpose({}));
          }
        },
      })
    );
  };

  const openDeleteModal = (menu) => {
    setDeleteModal(true);
    setDeletePurposeId(menu.id);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeletePurposeId("");
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {/* <h2 className="project_subheading">{sideHeading}</h2> */}
          <div className="creat_edit_project_btn_row">
            <button
              className="comman_btn ml-auto"
              onClick={openCreatePurposeModal}
            >
              {t("common.create")}
            </button>
          </div>
        </div>
        <div className="custom_data_table_content">
          {purposeList?.length === 0 ? (
            <h2 className="ta_c c-grey">No results found</h2>
          ) : !purposeList && apiErr ? (
            <h2 className="ta_c c-grey">You have no authority</h2>
          ) : (
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.title")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {purposeList?.map((menu, i) => {
                  return (
                    <tr className="custom_data_table_row" key={menu.id}>
                      <td className="custom_data_table_item table_item">
                        {menu.title}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.created_at &&
                          moment
                            .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.updated_at
                          ? moment
                              .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              menu.is_active === "0" ? "red" : "green"
                            }`}
                            onClick={() => activePurposeHandler(menu)}
                          >
                            {menu.is_active === "0"
                              ? t("feature.deactivate")
                              : t("table.activate")}
                          </button>
                          <div className="tooltip">
                            <button
                              onClick={() => openEditPurposeModal(menu)}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </button>
                            <span className="tooltiptext">Edit</span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => openDeleteModal(menu)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">Delete</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <CreatePurpose
        setPurposeForm={setPurposeForm}
        purposeForm={purposeForm}
        purposeId={purposeId}
        setPurposeId={setPurposeId}
        title={title}
        setTitle={setTitle}
        titleErr={titleErr}
        setTitleErr={setTitleErr}
      />
      <DeletePurposeModal
        closeDeleteModal={closeDeleteModal}
        deleteModal={deleteModal}
        deletePurposeId={deletePurposeId}
        setDeleteModal={setDeleteModal}
        setDeletePurposeId={setDeletePurposeId}
      />
    </>
  );
};

export default Purpose;
