import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createAmenity = createAsyncThunk(
  "createAmenity",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/amenities`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(routes.amenities);
        return response.data;
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateAmenity = createAsyncThunk(
  "updateAmenity",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/amenities/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb && !data.toast) {
        toast.success(data.toastText, {
          autoClose: 3000,
        });
        data.navigate(routes.amenities);
        return response.data;
      }
      if (response.status === 200 && data.cb) {
        // toast.success("Amenity updated successfully", {
        //   autoClose: 3000,
        // });
        return data.cb(null, response.data);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
    }
  }
);

export const getAmenities = createAsyncThunk(
  "getAmenities",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/amenities?start=0&admin=1`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const deleteAmenity = createAsyncThunk(
  "deleteAmenity",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `/onecity/amenities/${data.id}`,
        authHeaders()
      );
      if (response.status === 200 && data.cb) {
        toast.error("Amenity deleted successfully", {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  }
);

const amenitySlice = createSlice({
  name: "amenity",
  initialState: {
    amenitiesList: [],
    createAmenityComponent: "",
    updateAmenityComponent: "",
    error: null,
    loader: false,
    updateLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get amenities
    builder.addCase(getAmenities.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAmenities.fulfilled, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.amenitiesList = action.payload;
    });
    builder.addCase(getAmenities.rejected, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.error = action.payload;
    });

    // Create Amenity
    builder.addCase(createAmenity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createAmenity.fulfilled, (state, action) => {
      state.loader = false;
      state.createAmenityComponent = action.payload;
    });
    builder.addCase(createAmenity.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Amenity
    builder.addCase(updateAmenity.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateAmenity.fulfilled, (state, action) => {
      state.loader = false;
      state.updateAmenityComponent = action.payload;
    });
    builder.addCase(updateAmenity.rejected, (state, action) => {
      state.loader = false;
      state.updateLoader = false;
      state.error = action.payload;
    });
  },
});

export default amenitySlice.reducer;
