import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { useTranslation } from "react-i18next";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { addIcon, editIcon } from "../../icons";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { useDispatch } from "react-redux";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import { useState } from "react";
import { updateJustCoConfig } from "../../store/slice/justCoSlice";

const JustCoLogo = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { staticContent } = location.state;
  const [uploading, setUploading] = useState(false);

  let initialLogoPreview;
  if (staticContent?.logo) {
    initialLogoPreview = `${process.env.REACT_APP_FILE_BASE_URL}/${staticContent?.logo}`;
  }

  let initialBannerImagePreview;
  if (staticContent?.banner_image) {
    initialBannerImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${staticContent?.banner_image}`;
  }

  const initialFValues = {
    logo: staticContent ? staticContent.logo : "",
    logo_preview: staticContent ? initialLogoPreview : "",
    banner_image: staticContent ? staticContent.banner_image : "",
    banner_image_preview: staticContent ? initialBannerImagePreview : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("logo" in fieldValues) {
      temp.logo = "";
      if (!fieldValues.logo) {
        temp.logo = t("errors.logo_require");
      }
      if (fieldValues.logo.size > 1000000) {
        temp.logo = t("errors.small_logo_size");
        setValues({
          ...values,
          logo_preview: "",
          logo: "",
        });
      }
      if (
        fieldValues.logo?.name &&
        !fieldValues.logo?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.logo = t("errors.logo_invalid");
        setValues({
          ...values,
          logo_preview: "",
          logo: "",
        });
      }
    }

    if ("banner_image" in fieldValues) {
      temp.banner_image = "";
      if (!fieldValues.banner_image) {
        temp.banner_image = t("errors.img_require");
      }
      if (fieldValues.banner_image.size > 1000000) {
        temp.banner_image = t("errors.small_image_size");
        setValues({
          ...values,
          banner_image_preview: "",
          banner_image: "",
        });
      }
      if (
        fieldValues.banner_image?.name &&
        !fieldValues.banner_image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.banner_image = t("errors.img_invalid");
        setValues({
          ...values,
          banner_image_preview: "",
          banner_image: "",
        });
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({ ...values, logo: "", logo_preview: "" });
  };

  const bannerImageRemoveHandler = () => {
    setValues({ ...values, banner_image: "", banner_image_preview: "" });
  };

  const logoBannerImgHandler = async () => {
    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let logoUrl;
      if (values.logo && typeof values.logo !== "string") {
        formData.append("file", values.logo);
        logoUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        logoUrl = values.logo;
      }

      let bannerImageUrl;
      if (values.banner_image && typeof values.banner_image !== "string") {
        formData.append("file", values.banner_image);
        bannerImageUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        bannerImageUrl = values.banner_image;
      }

      setUploading(false);
      const data = {
        logo: typeof values.logo !== "string" ? logoUrl.payload : logoUrl,
        banner_image:
          typeof values.banner_image !== "string"
            ? bannerImageUrl.payload
            : bannerImageUrl,
      };

      dispatch(
        updateJustCoConfig({
          data,
          id: staticContent.id,
          navigate,
          toast: t("toast.image_update"),
        })
      );
    }
  };

  return (
    <div className="project_edit_main_content">
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("form.logo_and_banner")}
        </h2>
        <Link to={routes.justCo} className="comman_btn ml-auto secondary_btn">
          {t("common.back")}
        </Link>
      </div>
      <div className="create_from_row  settings_container">
        <div className="project_edit_detail_divider">
          <label className="create_from_label required">
            {t("directory.logo")}
          </label>
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`logo`}
              labelOtherProps={<span>{values.logo ? editIcon : addIcon}</span>}
              name="logo"
              accept="image/*"
              onChange={handleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={imagePreviewProps(
                values.logo,
                values.logo_preview,
                imageRemoveHandler
              )}
            />
          </div>
          <p className="create_project_review mb-0 block">
            {t("form.xsmall_size_desc")}
          </p>
          {errors.logo && <span className="err_text">{errors.logo}</span>}
        </div>
        <div className="project_edit_detail_divider">
          <label className="create_from_label required">
            {t("common.banner") + " " + t("table.image")}
          </label>
          <div className="project_edit_feature_input_file">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              id={`banner_image`}
              labelOtherProps={
                <span>{values.banner_image ? editIcon : addIcon}</span>
              }
              name="banner_image"
              accept="image/*"
              onChange={handleInputChange}
              onClick={(e) => (e.target.value = null)}
              imageProps={imagePreviewProps(
                values.banner_image,
                values.banner_image_preview,
                bannerImageRemoveHandler
              )}
            />
          </div>
          <p className="create_project_review mb-0 block">
            {t("form.small_size_desc")}
          </p>
          {errors.banner_image && (
            <span className="err_text">{errors.banner_image}</span>
          )}
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={logoBannerImgHandler}
        text={`${staticContent ? t("common.save") : t("common.create")}`}
      />
    </div>
  );
};

export default JustCoLogo;
