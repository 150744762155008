import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  editIcon,
  searchIcon,
  viewIcon,
} from "../../icons";
import { getUsers } from "../../store/slice/userSlice";
import Select from "react-select";
import { getRoles } from "../../store/slice/rolemanagementSlice";
import Loader from "../../component/Common/Loader";
import DeleteUserModal from "./DeleteUserModal";
import googlePhoto from "../../images/google.png";
import facebookPhoto from "../../images/facebook.png";
import emailPhoto from "../../images/email.jpg";
import phonePhoto from "../../images/phone.jpg";
import applePhoto from "../../images/apple.png";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const location = useLocation();
  const value = location?.state?.value;
  const value_page = location?.state?.value_page;
  const sideHeading = localStorage.getItem("sidebar_component");
  const { t } = useTranslation("common");

  const users = useSelector((state) => state.user.userList?.data);
  const userCount = useSelector((state) => state.user.userList?.count);
  const userLoader = useSelector((state) => state.user.loader);
  const [pageN, setPageN] = useState(page_number - 1);

  const initialFValues = {
    user_name: value?.user_name ? value?.user_name : "",
    phone_number: value?.phone_number ? value?.phone_number : "",
    role: value?.role ? value?.role : "",
    email: value?.email ? value?.email : "",
    deleteUserModal: false,
    deleteUserModalData: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );
  const ref = useRef(false);

  const roleList = useSelector((state) => state.rolemanagement.rolesList?.data);

  const roleOptions = roleList?.map((role) => {
    return { value: role.role_display_name, label: role.role_display_name };
  });

  const userRoleOptions = roleOptions && [
    { value: "", label: "All", id: "" },
    ...roleOptions,
  ];

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.users}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getUsers({
              user_name: values.user_name,
              email: values.email,
              phone_number: values.phone_number,
              role: values.role.value,
            })
          );
        }, 500);

        return () => clearTimeout(timer);
      }
    }
  }, [values.user_name, values.email, values.phone_number, values.role]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getUsers({
          start: pageN,
          user_name: values.user_name,
          email: values.email,
          phone_number: values.phone_number,
          role: values.role.value,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getUsers({
        start: (value_page - 1) * 10,
        user_name: values.user_name,
        email: values.email,
        phone_number: values.phone_number,
        role: values.role.value,
      })
    );
    dispatch(getRoles({ appType: 1 }));
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const deleteUserHandler = (menu) => {
    setValues({ ...values, deleteUserModal: true, deleteUserModalData: menu });
  };

  const handleDeleteUserClose = () => {
    setValues({ ...values, deleteUserModal: false, deleteUserModalData: "" });
  };

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  // if (userLoader) {
  //   return <Loader />;
  // }'

  return (
    <>
      <div className="project_edit_main_content">
        <div>
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {/* <h2 className="project_subheading">{sideHeading}</h2> */}
          <div className="user_data_search_select_box_row">
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_name")}
                id="user_name"
                name="user_name"
                value={values.user_name}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_email")}
                id="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder={t("filter.search_phone")}
                id="phone_number"
                name="phone_number"
                value={values.phone_number}
                onChange={handleInputChange}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_search">
              <Select
                placeholder={t("form.userRole")}
                value={values.role}
                onChange={(e) => handleInputChange(e, "role")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="role"
                options={userRoleOptions}
              />
            </div>
          </div>
        </div>
        <div className="custom_data_table_content">
          {userLoader ? (
            <Loader />
          ) : users?.length === 0 ? (
            <h2 className="ta_c">No results found</h2>
          ) : (
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.profileImage")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.email")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.phoneNumber")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.loginType")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.userRole")}
                  </th>

                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.lastActiveAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.justCo_access")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.justCo_status")}
                  </th>

                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {users?.map((menu, i) => {
                  let socialphoto = "";
                  if (menu.provider_type === "google.com") {
                    socialphoto = googlePhoto;
                  } else if (menu.provider_type === "facebook.com") {
                    socialphoto = facebookPhoto;
                  } else if (menu.provider_type === "email.com") {
                    socialphoto = emailPhoto;
                  } else if (menu.provider_type === "apple.com") {
                    socialphoto = applePhoto;
                  }
                  return (
                    <tr className="custom_data_table_row" key={menu.id}>
                      <td className="custom_data_table_item table_item">
                        <img
                          src={
                            menu.profile_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                menu.profile_image
                              : process.env.REACT_APP_FILE_BASE_URL +
                                "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                          }
                          alt={"profile_image"}
                          className={`custom_data_table_img `}
                          onClick={() => {
                            setImgZoom(true);
                            setImgSrc(
                              menu.profile_image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    menu.profile_image
                                : process.env.REACT_APP_FILE_BASE_URL +
                                    "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            );
                          }}
                        />
                      </td>
                      <td className="custom_data_table_item table_item">
                        {!menu.first_name ||
                        menu.first_name == "" ||
                        menu.first_name == "null"
                          ? "-"
                          : menu.first_name}
                        {!menu.last_name ||
                        menu.last_name == "" ||
                        menu.last_name == "null"
                          ? ""
                          : " " + menu.last_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.email ? menu.email : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.phone_number && menu.phone_number !== "null"
                          ? "+" + menu.country_code + " " + menu.phone_number
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.provider_type ? (
                          <img
                            src={socialphoto}
                            alt={"service_type_image"}
                            className={`custom_data_table_img `}
                          />
                        ) : (
                          <img
                            src={phonePhoto}
                            alt={"service_type_image"}
                            className={`custom_data_table_img `}
                          />
                        )}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.role_display_name ? menu.role_display_name : "-"}
                      </td>

                      <td className="custom_data_table_item table_item">
                        {menu.created_at &&
                          moment
                            .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {menu.last_login
                          ? moment
                              .utc(menu.last_login, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>

                      <td className="custom_data_table_item table_item">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {menu.role_display_name === "Tenant" ? (
                            <span
                              className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 background ${
                                menu.justco_access === "0" ? "red" : "green"
                              }`}
                            >
                              {menu.justco_access === "1"
                                ? t("common.allowed")
                                : t("common.not_allowed")}
                            </span>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>

                      <td
                        className={`custom_data_table_item  table_item ${
                          menu.justco_status === "0" ? "red" : "green"
                        }`}
                      >
                        {menu.role_display_name === "Tenant" ? (
                          <>
                            {menu.justco_status === "1" ? checkIcon : crossIcon}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <div className="tooltip">
                            <Link
                              to={`${routes.users}/view/${menu.id}`}
                              state={{
                                value: {
                                  user_name: values.user_name,
                                  phone_number: values.phone_number,
                                  role: values.role,
                                  email: values.email,
                                },
                                page_number,
                              }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {viewIcon}
                            </Link>
                            <span className="tooltiptext">View</span>
                          </div>
                          <div className="tooltip">
                            <Link
                              to={`${routes.users}/edit/${menu.id}`}
                              state={{
                                user: menu,
                                value: {
                                  user_name: values.user_name,
                                  phone_number: values.phone_number,
                                  role: values.role,
                                  email: values.email,
                                },
                                page_number,
                              }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                            <span className="tooltiptext">Edit</span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => deleteUserHandler(menu)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">Delete</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {users?.length > 0 && (
          <Pagination totalRecords={userCount} onPageChange={onPageChange} />
        )}
        <DeleteUserModal
          handleClose={handleDeleteUserClose}
          values={values}
          setValues={setValues}
          page_number={page_number}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
    </>
  );
};

export default Users;
