import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import {
  checkIcon,
  crossIcon,
  deleteIcon,
  editIcon,
  searchIcon,
} from "../../icons";
import { getEvents, updateEvent } from "../../store/slice/eventSlice";
import DeleteEvent from "./DeleteEvent";
import Loader from "../../component/Common/Loader";
import { toast } from "react-toastify";
import { useRef } from "react";
import Pagination from "../../component/Pagination/Pagination";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import DatePicker from "react-datepicker";
import {
  SidebarComponent,
  defaultTimeZone,
} from "../../helpers/commonFunction";
import ViewImageModal from "../../component/Common/PageComponent/ViewImageModal";
import CommonNotification from "../../component/Common/PageComponent/CommonNotification";

const Event = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef(false);
  const { page_number } = params;
  const [pageN, setPageN] = useState(page_number - 1);

  const location = useLocation();
  const value = location?.state?.value;
  const page = location?.state?.page;

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  let events = useSelector((state) => state.event.eventList)?.data;
  let eventsCount = useSelector((state) => state.event.eventList)?.count;
  let eventLoader = useSelector((state) => state.event.loader);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [apiErr, setApiErr] = useState(false);

  const initialFValues = {
    title: value?.title ? value?.title : "",
    organizer_company_name: value?.organizer_company_name
      ? value?.organizer_company_name
      : "",
    date: value?.date ? value?.date : "",
    sendNotificationModal: false,
    sendNotificationModalData: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const deleteModalHandler = (menu) => {
    setDeleteModal(true);
    setDeleteModalId(menu.id);
  };

  const handleClose = () => {
    setDeleteModal(false);
    setDeleteModalId("");
  };

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.events}/page/1`);
      if (+pageN === 0) {
        const timer = setTimeout(() => {
          dispatch(
            getEvents({
              title: values.title,
              organizer_company_name: values.organizer_company_name,
              date: values.date && moment(values.date).format("YYYY-MM-DD"),
              noAuthority: (error) => {
                if (error) {
                  console.log("err", error);
                  setApiErr(true);
                } else {
                  setApiErr(false);
                }
              },
            })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [values.organizer_company_name, values.title, values.date]);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getEvents({
          start: pageN,
          title: values.title,
          organizer_company_name: values.organizer_company_name,
          date: values.date && moment(values.date).format("YYYY-MM-DD"),
          noAuthority: (error) => {
            if (error) {
              console.log("err", error);
              setApiErr(true);
            } else {
              setApiErr(false);
            }
          },
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(
      getEvents({
        start: (page - 1) * 10,
        title: values.title,
        organizer_company_name: values.organizer_company_name,
        date: values.date && moment(values.date).format("YYYY-MM-DD"),
        noAuthority: (error) => {
          if (error) {
            console.log("err", error);
            setApiErr(true);
          } else {
            setApiErr(false);
          }
        },
      })
    );
    ref.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const featureEventHandler = (item) => {
    const data = {
      is_feature: item?.is_feature === "1" ? "0" : "1",
    };

    dispatch(
      updateEvent({
        data,
        id: item?.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              `${t("event.head")} ${
                item.is_feature === "1"
                  ? t("promotion.featured")
                  : t("promotion.un_featured")
              } ${t("feature.successfully")}`,
              {
                autoClose: 3000,
              }
            );
            dispatch(
              getEvents({
                start: (page_number - 1) * 10,
                title: values.title,
                organizer_company_name: values.organizer_company_name,
                date: values.date && moment(values.date).format("YYYY-MM-DD"),
              })
            );
          }
        },
      })
    );
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const handleNotificationClose = () => {
    setValues({
      ...values,
      sendNotificationModal: false,
      sendNotificationModalData: "",
    });
  };

  const handleNotificationShow = (menu) => {
    setValues({
      ...values,
      sendNotificationModal: true,
      sendNotificationModalData: { id: menu.id },
    });
  };

  const sendNowHandler = () => {
    const data = {
      send_now: "1",
    };

    dispatch(
      updateEvent({
        data,
        id: values?.sendNotificationModalData?.id,
        toast: "Event Notification has been sent!",
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getEvents({
                start: (page_number - 1) * 10,
                title: values.title,
                organizer_company_name: values.organizer_company_name,
                date: values.date && moment(values.date).format("YYYY-MM-DD"),
              })
            );
            setValues({
              ...values,
              sendNotificationModal: false,
              sendNotificationModalData: "",
            });
          }
        },
      })
    );
  };

  const dateIsPast = (date) => {
    let today = moment();
    let givenDate = moment
      .utc(date)
      .clone()
      .tz(defaultTimeZone)
      .format("YYYY-MM-DDTHH:mm:ss");
    let finalDate = moment(givenDate, "YYYY-MM-DDTHH:mm:ss");
    return finalDate.isBefore(today);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{SidebarComponent()}</h2>
          {/* <h2 className="project_subheading">{sideHeading}</h2> */}
          <div className="creat_edit_project_btn_row creat_edit_project_btn_row_flex">
            <Link
              to={routes.createEvent}
              state={{ values, page_number }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_title")}
              id="title"
              name="title"
              value={values.title}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder={t("filter.search_organizer_company")}
              id="organizer_company_name"
              name="organizer_company_name"
              value={values.organizer_company_name}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search h-35">
            <DatePicker
              isClearable={values.date}
              placeholderText={`${t("table.date")}`}
              // wrapperClassName="date-picker_container create_from_input_content"
              // className="date-picker_input create_from_input"
              selected={values.date}
              onChange={(date) => {
                setValues({ ...values, date: date });
              }}
              dateFormat={"dd/MM/yyyy"}
            />
          </div>
        </div>
        {eventLoader ? (
          <Loader />
        ) : events?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : !events && apiErr ? (
          <h2 className="ta_c c-grey">You have no authority</h2>
        ) : (
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.image")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.title")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("form.event_location")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.start_date")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.end_date")}
                  </th>
                  {/* <th className="custom_data_table_heading">
                    {t("event.start_time")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("event.end_time")}
                  </th> */}
                  <th className="custom_data_table_heading">
                    {t("event.organizer_name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("event.organizer_company_name")}
                  </th>
                  <th
                    className="custom_data_table_heading"
                    style={{ minWidth: "94px" }}
                  >
                    {t("table.is_feature")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.created_by")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("notification.head")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {events?.length > 0 &&
                  events?.map((menu, i) => {
                    return (
                      <tr className="custom_data_table_row" key={menu.id}>
                        <td className="custom_data_table_item table_item">
                          <img
                            src={
                              menu.image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  menu.image
                                : process.env.REACT_APP_FILE_BASE_URL +
                                  "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            }
                            alt={"event_img"}
                            className={`custom_data_table_img `}
                            onClick={() => {
                              setImgZoom(true);
                              setImgSrc(
                                menu.image
                                  ? process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      menu.image
                                  : process.env.REACT_APP_FILE_BASE_URL +
                                      "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                              );
                            }}
                          />
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.title}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.address}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.date
                            ? moment
                                .utc(menu.date, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.end_date
                            ? moment
                                .utc(menu.end_date, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        {/* <td className="custom_data_table_item table_item">
                          {menu.start_time}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.end_time}
                        </td> */}
                        <td className="custom_data_table_item table_item">
                          {menu.organizer_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.organizer_company_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.is_feature === "1" ? checkIcon : crossIcon}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.created_at &&
                            moment
                              .utc(menu.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.updated_at
                            ? moment
                                .utc(menu.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {menu.user_name || "-"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            {menu.send_now === "0" ? (
                              // ||
                              // (menu.end_date && !dateIsPast(menu.end_date))
                              <button
                                onClick={() => handleNotificationShow(menu)}
                                className="custom_data_table_view_edit_item_btn hide_compoenent_btn w-fc"
                              >
                                {t("table.send")}
                              </button>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row custom_table_four_btn_row">
                            <button
                              className={`custom_data_table_view_edit_item_btn active_feature_btn hide_compoenent_btn ${
                                menu.is_feature === "1" ? "red" : "green"
                              }`}
                              onClick={() => featureEventHandler(menu)}
                              disabled={
                                menu.end_date && dateIsPast(menu.end_date)
                                  ? true
                                  : false
                              }
                            >
                              {menu.is_feature === "1"
                                ? t("promotion.un_featured")
                                : t("promotion.featured")}
                            </button>
                            {((menu.end_date && !dateIsPast(menu.end_date)) ||
                              menu.end_date == null) && (
                              <div className="tooltip">
                                <Link
                                  to={`${routes.events}/edit/${menu.id}`}
                                  state={{ menu, values, page_number }}
                                  className="custom_data_table_view_edit_item_btn"
                                >
                                  {editIcon}
                                </Link>
                                <span className="tooltiptext">Edit</span>
                              </div>
                            )}

                            {/* <div className="tooltip">
                        <button
                          className="custom_data_table_view_edit_item_btn"
                          onClick={() => viewAnnouncementHandler(menu)}
                        >
                          {viewIcon}
                        </button>
                        <span className="tooltiptext">View</span>
                      </div> */}
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteModalHandler(menu)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {+eventsCount > 0 && (
          <Pagination totalRecords={eventsCount} onPageChange={onPageChange} />
        )}
        <DeleteEvent
          show={deleteModal}
          serviceDetail={deleteModalId}
          handleClose={handleClose}
          setDeleteModal={setDeleteModal}
          setDeleteModalId={setDeleteModalId}
          values={values}
          page_number={page_number}
        />
      </div>
      <ViewImageModal
        setImgZoom={setImgZoom}
        imgZoom={imgZoom}
        imgSrc={imgSrc}
      />
      <CommonNotification
        show={values.sendNotificationModal}
        handleNotificationClose={handleNotificationClose}
        sendNowHandler={sendNowHandler}
      />
    </>
  );
};

export default Event;
