import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const createServiceType = createAsyncThunk(
  "createServiceType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity/types/service`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });

        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateServiceType = createAsyncThunk(
  "updateServiceType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/types/service/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const updateService = createAsyncThunk(
  "updateService",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity/service-requests/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Service updated successfully", {
          autoClose: 3000,
        });

        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getService = createAsyncThunk(
  "getService",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity/service-requests?service_request_status=${
          data.service_request_status || ""
        }&start=${data.start || 0}&search_key=${
          data.search_key || ""
        }&phone_number=${data.phone_number || ""}&service_type=${
          data.service_type || ""
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      if (error.response.status === 403 && data.noAuthority) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        return data.noAuthority(error.response.data);
      }
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const getServiceTypes = createAsyncThunk(
  "getServiceTypes",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(`/onecity/types/service`, authHeaders());
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceList: [],
    serviceTypesList: [],
    createServiceTypeComponent: "",
    updateServiceTypeComponent: "",
    error: null,
    loader: false,
    updateLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Services

    builder.addCase(getService.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.loader = false;
      state.serviceList = action.payload;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Services Types
    builder.addCase(getServiceTypes.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getServiceTypes.fulfilled, (state, action) => {
      state.loader = false;
      state.serviceTypesList = action.payload;
    });
    builder.addCase(getServiceTypes.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Service type
    builder.addCase(createServiceType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createServiceType.fulfilled, (state, action) => {
      state.loader = false;
      state.createServiceTypeComponent = action.payload;
    });
    builder.addCase(createServiceType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update service type
    builder.addCase(updateServiceType.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateServiceType.fulfilled, (state, action) => {
      state.loader = false;
      state.updateServiceTypeComponent = action.payload;
    });
    builder.addCase(updateServiceType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default serviceSlice.reducer;
