import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { announcementTypeValidate } from "../../component/Common/PageComponent/AnnouncementComponents";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, editIcon } from "../../icons";
import {
  createAnnoncementType,
  getAnnouncementTypes,
  updateAnnouncementType,
} from "../../store/slice/announcementSlice";
import { fileUpload } from "../../store/slice/fileUploadSlice";

const AnnouncementType = ({
  updateState,
  setUpdateState,
  values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
}) => {
  const sideHeading = localStorage.getItem("sidebar_component");
  const dispatch = useDispatch();
  const ref = useRef(false);

  const { t } = useTranslation("common");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  const announcementTypes = useSelector(
    (state) => state.announcement.announcementTypesList
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return announcementTypeValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors,
      t
    );
  };

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.announcementType_name && values.announcementType_icon) {
      setErrors({
        ...errors,
        announcementType_name: "",
        announcementType_icon: "",
      });
    } else if (values.announcementType_name) {
      setErrors({ ...errors, announcementType_name: "" });
    } else if (values.announcementType_icon) {
      setErrors({ ...errors, announcementType_icon: "" });
    }
  }, [values.announcementType_icon, values.announcementType_name]);

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      announcementType_icon: "",
      announcementType_icon_preview: "",
    });
  };

  const createAnnouncementTypeHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.announcementType_icon &&
        typeof values.announcementType_icon !== "string"
      ) {
        formData.append("file", values.announcementType_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            announcementType_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.announcementType_icon;
      }
      const data = {
        announcement_type_image:
          typeof values.announcementType_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
        announcement_type_name: values.announcementType_name,
      };

      updateState
        ? dispatch(
            updateAnnouncementType({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getAnnouncementTypes());
                  setValues({
                    ...values,
                    announcementType_name: "",
                    announcementType_icon: "",
                    announcementType_icon_preview: "",
                  });
                  ref.current = false;

                  setUpdateState("");
                }
              },
              toast: t("toast.update_announcementtype"),
            })
          )
        : dispatch(
            createAnnoncementType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getAnnouncementTypes());
                  setValues({
                    ...values,
                    announcementType_name: "",
                    announcementType_icon: "",
                    announcementType_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_announcementtype"),
            })
          );
    }
  };

  return (
    <>
      <div className="create_from_row">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {/* {updateState
              ? `${sideHeading} Type > Edit`
              : `${sideHeading} Type > Create`} */}

            {updateState
              ? `${SidebarComponent()} Type > Edit`
              : `${SidebarComponent()} Type > Create`}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`announcementType_icon`}
                labelOtherProps={
                  <span>
                    {!values.announcementType_icon ? addIcon : editIcon}
                  </span>
                }
                name="announcementType_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.announcementType_icon,
                  values.announcementType_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.announcementType_icon && (
              <span className="err_text">{errors?.announcementType_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.nameTypePlace")}
              label={t("form.nameType")}
              id="announcementType_name"
              name="announcementType_name"
              value={values.announcementType_name}
              onChange={handleInputChange}
              error={errors?.announcementType_name || ""}
            />
          </div>
          {fileUploadLoader ? (
            <div className="project_submit_bottom_btn center_back_btn">
              <span className="comman_btn">Loading...</span>
            </div>
          ) : (
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={createAnnouncementTypeHandler}
              text={`${updateState ? t("common.save") : t("common.create")}`}
            />
          )}
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.type")}</th>
                <th className="custom_data_table_heading">
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {announcementTypes?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.announcement_type_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.announcement_type_image
                        }
                        alt="announcement_type_image"
                        className="custom_data_table_img"
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AnnouncementType;
