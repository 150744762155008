import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const createDocType = createAsyncThunk(
  "createDocType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `onecity/doctype`,
        data.data,
        authHeaders()
      );
      if ((response.status === 200 || response.status === 201) && data.cb) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const updateDocType = createAsyncThunk(
  "updateDocType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `onecity/doctype/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb && data.only_edit) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
      if (response.status === 200 && data.cb && !data.only_edit) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getDocType = createAsyncThunk(
  "getDocType",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(`onecity/doctype`, authHeaders());

      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const docTypeSlice = createSlice({
  name: "docType",
  initialState: {
    docTypeList: [],
    createDocTypeComponent: "",
    updateDocTypeComponent: "",
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Floors
    builder.addCase(getDocType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDocType.fulfilled, (state, action) => {
      state.loader = false;
      state.docTypeList = action.payload;
    });
    builder.addCase(getDocType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Create Floor
    builder.addCase(createDocType.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createDocType.fulfilled, (state, action) => {
      state.loader = false;
      state.createdocTypeComponent = action.payload;
    });
    builder.addCase(createDocType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update Floor
    builder.addCase(updateDocType.pending, (state) => {
      state.loader = true;
      state.updateLoader = true;
    });
    builder.addCase(updateDocType.fulfilled, (state, action) => {
      state.loader = false;
      state.updateDocTypeComponent = action.payload;
    });
    builder.addCase(updateDocType.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default docTypeSlice.reducer;
