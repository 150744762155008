import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import {
  SidebarComponent,
  imagePreviewProps,
} from "../../helpers/commonFunction";
import { addIcon, deleteIcon, editIcon } from "../../icons";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import {
  createServiceType,
  getServiceTypes,
  updateServiceType,
} from "../../store/slice/serviceSlice";

const ServiceTypeForm = ({
  updateState,
  values,
  setValues,
  setErrors,
  handleInputChange,
  errors,
  setUpdateState,
  validate,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(false);

  const { t } = useTranslation("common");
  const sideHeading = localStorage.getItem("sidebar_component");

  let fileUploadLoader = useSelector((state) => state.fileUpload.loader);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else if (values.serviceType_name && values.serviceType_icon) {
      setErrors({
        ...errors,
        serviceType_name: "",
        serviceType_icon: "",
      });
    } else if (values.serviceType_name) {
      setErrors({ ...errors, serviceType_name: "" });
    } else if (values.serviceType_icon) {
      setErrors({ ...errors, serviceType_icon: "" });
    }
  }, [values.serviceType_icon, values.serviceType_name]);

  const serviceTypeList = useSelector(
    (state) => state.service.serviceTypesList
  );

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      serviceType_icon: "",
      serviceType_icon_preview: "",
    });
  };

  const createServiceTypeHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      let attachmentUrl;
      if (
        values.serviceType_icon &&
        typeof values.serviceType_icon !== "string"
      ) {
        formData.append("file", values.serviceType_icon);
        attachmentUrl = await dispatch(fileUpload(formData));
        if (attachmentUrl && attachmentUrl.payload) {
          setValues({
            ...values,
            serviceType_icon: attachmentUrl.payload,
          });
        }
        formData.delete("file");
      } else {
        attachmentUrl = values.serviceType_icon;
      }
      const data = {
        service_type_name: values.serviceType_name,
        service_type_image:
          typeof values.serviceType_icon !== "string"
            ? attachmentUrl.payload
            : attachmentUrl,
      };
      updateState
        ? dispatch(
            updateServiceType({
              data,
              id: updateState.id,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getServiceTypes());
                  setValues({
                    ...values,
                    serviceType_name: "",
                    serviceType_icon: "",
                    serviceType_icon_preview: "",
                  });
                  ref.current = false;
                  setUpdateState("");
                }
              },
              toast: t("toast.update_serviceType"),
            })
          )
        : dispatch(
            createServiceType({
              data,
              cb: (err, res) => {
                if (err) {
                  console.log("err", err);
                } else {
                  dispatch(getServiceTypes());
                  setValues({
                    ...values,
                    serviceType_name: "",
                    serviceType_icon: "",
                    serviceType_icon_preview: "",
                  });
                  ref.current = false;
                }
              },
              toast: t("toast.create_serviceType"),
            })
          );
    }
  };

  const deleteModalHandler = (menu) => {
    setValues({
      ...values,
      deleteModal: true,
      deleteModalId: menu.id,
    });
  };

  // const deleteServiceTypeHandler = (menu) => {
  //   const data = {
  //     delete_status: 1,
  //   };
  //   console.log("data", data);
  //   dispatch(
  //     updateServiceType({
  //       data,
  //       id: menu.id,
  //       cb: (err, res) => {
  //         if (err) {
  //           console.log("err", err);
  //         } else {
  //           dispatch(getServiceTypes());
  //         }
  //       },
  //       toast: t("toast.update_serviceType"),
  //     })
  //   );
  // };

  return (
    <>
      <div className="create_from_row servicetype_form-container">
        <div className="project_edit_detail_column_content type_form_content_devider">
          <h2>
            {/* {updateState
              ? `${sideHeading} Type > Edit`
              : `${sideHeading} Type > Create`} */}
            {updateState
              ? `${SidebarComponent()} Type > Edit`
              : `${SidebarComponent()} Type > Create`}
          </h2>
          <div className="project_edit_detail_divider">
            <label className="create_from_label required">
              {t("form.icon")}
            </label>
            <div className="project_edit_feature_input_file">
              <Input
                className="create_from_input_content"
                labelClassName="create_from_label"
                errorClassName="err_text"
                type="file"
                id={`serviceType_icon`}
                labelOtherProps={
                  <span>{!values.serviceType_icon ? addIcon : editIcon}</span>
                }
                name="serviceType_icon"
                accept="image/*"
                onChange={handleInputChange}
                onClick={(e) => (e.target.value = null)}
                imageProps={imagePreviewProps(
                  values.serviceType_icon,
                  values.serviceType_icon_preview,
                  imageRemoveHandler
                )}
              />
            </div>
            <p className="create_project_review mb-0 block">
              {t("form.xsmall_size_desc")}
            </p>
            {errors?.serviceType_icon && (
              <span className="err_text">{errors?.serviceType_icon}</span>
            )}
            <Input
              className="project_edit_feature_input"
              labelClassName="required"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.name_place")}
              label={t("form.name")}
              id="serviceType_name"
              name="serviceType_name"
              value={values.serviceType_name}
              onChange={handleInputChange}
              error={errors?.serviceType_name || ""}
            />
          </div>
        </div>
        <div className="custom_data_table_content">
          <h2>{t("table.list")}</h2>
          <table className="custom_data_table custom_data_table_width">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">{t("table.name")}</th>
                <th className="custom_data_table_heading">
                  {t("table.image")}
                </th>
                <th className="custom_data_table_heading">
                  {t("table.action")}
                </th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {serviceTypeList?.map((menu, i) => {
                return (
                  <tr className="custom_data_table_row" key={menu.id}>
                    <td className="custom_data_table_item table_item">
                      {menu.service_type_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          menu.service_type_image
                        }
                        alt="contactlink_icon"
                        className="custom_data_table_img"
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        <div className="tooltip">
                          <button
                            onClick={() => setUpdateState(menu)}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </button>
                          <span className="tooltiptext">Edit</span>
                        </div>
                        <div className="tooltip">
                          <button
                            className="custom_data_table_view_edit_item_btn"
                            onClick={() => deleteModalHandler(menu)}
                            // onClick={() => deleteServiceTypeHandler(menu)}
                          >
                            {deleteIcon}
                          </button>
                          <span className="tooltiptext">Delete</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {fileUploadLoader ? (
        <div className="project_submit_bottom_btn center_back_btn mw-un">
          <span className="comman_btn">Loading...</span>
        </div>
      ) : (
        <Button
          className="project_submit_bottom_btn center_back_btn mw-un"
          buttonClassName="comman_btn"
          onClick={createServiceTypeHandler}
          text={`${updateState ? t("common.save") : t("common.create")}`}
        />
      )}
    </>
  );
};

export default ServiceTypeForm;
