// Announcement type form validation

export const announcementTypeValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  t
) => {
  if ("announcementType_icon" in fieldValues) {
    temp.announcementType_icon = "";
    if (!fieldValues.announcementType_icon) {
      temp.announcementType_icon = t("errors.icon_require");
    }
    if (fieldValues.announcementType_icon.size > 1000000) {
      temp.announcementType_icon = t("errors.small_icon_size");
      setValues({
        ...values,
        announcementType_icon_preview: "",
        announcementType_icon: "",
      });
    }
    if (
      fieldValues.announcementType_icon?.name &&
      !fieldValues.announcementType_icon?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.announcementType_icon = t("errors.icon_invalid");
      setValues({
        ...values,
        announcementType_icon_preview: "",
        announcementType_icon: "",
      });
    }
  }

  if ("announcementType_name" in fieldValues) {
    temp.announcementType_name = fieldValues.announcementType_name
      ? ""
      : t("errors.name");
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

// Announcement Form Validation
export const announcementValidation = (
  temp,
  fieldValues,
  values,
  setErrors,
  setValues,
  t
) => {
  if ("announcement_title" in fieldValues) {
    temp.announcement_title = fieldValues.announcement_title
      ? ""
      : t("errors.title");
  }
  if ("announcement_type" in fieldValues) {
    temp.announcement_type = fieldValues.announcement_type
      ? ""
      : t("errors.type");
  }
  if ("announcement_description" in fieldValues) {
    temp.announcement_description = fieldValues.announcement_description
      ? ""
      : t("errors.description");
  }
  if ("user_type" in fieldValues) {
    temp.user_type =
      fieldValues.user_type.length > 0 ? "" : t("errors.user_role");
  }

  if ("start_date" in fieldValues) {
    temp.start_date = fieldValues.start_date ? "" : t("errors.start_date");
  }
  if ("end_date" in fieldValues) {
    temp.end_date = fieldValues.end_date ? "" : t("errors.end_date");
  }

  if ("announcement_date_and_time" in fieldValues) {
    if (!fieldValues.announcement_date_and_time) {
      temp.announcement_date_and_time = t("errors.date_time");
    } else {
      // Case 2: Announcement date and time is present
      const announcementDate = new Date(fieldValues.announcement_date_and_time);
      const startDate = new Date(fieldValues.start_date);

      if (announcementDate.getTime() === startDate.getTime()) {
        // Case 2.1: Announcement date and time is the same as start date
        const currentTime = new Date();

        if (announcementDate < currentTime) {
          // Case 2.1.1: Announcement date and time is in the past
          temp.announcement_date_and_time =
            "Selected announcement time is in the past.";
        } else {
          // Case 2.1.2: Announcement date and time is valid
          temp.announcement_date_and_time = "";
        }
      } else {
        // Case 2.2: Announcement date and time is different from start date
        temp.announcement_date_and_time = "";
      }
    }
  }

  // if ("announcement_date_and_time" in fieldValues) {
  //   if (!fieldValues.announcement_date_and_time) {
  //     temp.announcement_date_and_time = t("errors.date_time");
  //   } else {
  //     // Case 2: Announcement date and time is present
  //     const announcementDate = new Date(fieldValues.announcement_date_and_time);
  //     const startDate = new Date(fieldValues.start_date);
  //     const endDate = new Date(fieldValues.end_date);
  //     const currentTime = new Date();

  //     if (announcementDate.getTime() === startDate.getTime()) {
  //       // Case 2.1: Announcement date and time is the same as start date
  //       if (announcementDate < currentTime) {
  //         // Case 2.1.1: Announcement date and time is in the past
  //         temp.announcement_date_and_time =
  //           "Selected announcement time is in the past.";
  //       } else {
  //         // Case 2.1.2: Announcement date and time is valid
  //         if (announcementDate > endDate) {
  //           // Case 2.1.2.1: Announcement date and time is after end date
  //           temp.announcement_date_and_time =
  //             "Selected announcement time is after the end date.";
  //         } else {
  //           temp.announcement_date_and_time = "";
  //         }
  //       }
  //     } else {
  //       // Case 2.2: Announcement date and time is different from start date
  //       temp.announcement_date_and_time = "";

  //       if (announcementDate > endDate) {
  //         // Case 2.2.1: Announcement date and time is after end date
  //         temp.announcement_date_and_time =
  //           "Selected announcement time is after the end date.";
  //       }
  //     }
  //   }
  // }

  if ("image" in fieldValues) {
    temp.image = "";
    // if (!fieldValues.image) {
    //   temp.image = t("errors.img_require");
    // }
    if (values?.include_image === "1" && !fieldValues.image) {
      temp.image = t("errors.img_require");
    }
    if (fieldValues.image.size > 1000000) {
      // temp.image = t("errors.small_icon_size");
      temp.image = t("errors.small_image_size1");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
    if (
      fieldValues.image?.name &&
      !fieldValues.image?.name?.match(/.(jpg|jpeg|png|gif)$/i)
    ) {
      temp.image = t("errors.img_invalid");
      setValues({
        ...values,
        image_preview: "",
        image: "",
      });
    }
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const userTypeOptions = [
  {
    id: 1,
    label: "All",
    name: "user_type",
    value: "ALL",
  },
  {
    id: 2,
    label: "Tenant",
    name: "user_type",
    value: "TENANT",
  },
  {
    id: 3,
    label: "Visitor",
    name: "user_type",
    value: "VISITOR",
  },
];
